import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const ReportService = {
  report9332: (page, count, payload) => {
    let url = `/api/v1/report-9332/search?page=${page}&count=${count}`;

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  report9084: (page, count, payload) => {
    let url = `/api/v1/report-9084/search?page=${page}&count=${count}`;

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  report9084new: (page, count, payload) => {
    let url = `/api/v1/report-9084new/search?page=${page}&count=${count}`;

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  report9084new1: (page, count, payload) => {
    let url = `/api/v1/report-9084new1/search?page=${page}&count=${count}`;

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

}