import { Helmet } from 'react-helmet';
import CauhinhgsmDetail from 'src/components/cauhinhgsm-detail/CauhinhgsmDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

const Cauhinhgsm = () => (
  <>
    <Helmet>
      <title>Cấu hình GSM</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <Box sx={{ pt: 3 }}>
          <CauhinhgsmDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Cauhinhgsm;
