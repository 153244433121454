import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const NapmymobifoneService = {

  thongKe: (payload) => {
    let url = `/api/v1/napmymobifone/thongKe?`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  donChoHeThong: (payload) => {
    let url = `/api/v1/napmymobifone/donChoHeThong?page=${payload.donchohethongpage}&count=${payload.donchohethonglimit}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      //url += `&nha_mang=${payload.nha_mang}`
      url += `&nha_mang=${payload.operator.toUpperCase()}`
    };

    if (payload.boi_so) {
      url += `&boi_so=${payload.boi_so}`
    };

    if (payload.operator) {
      url += `&operator=${payload.operator}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.dua) {
      url += `&dua=${payload.dua}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  handleSuaPortNhieuMuc: (payload) => {
    return axios.post(`/api/v1/napmymobifone/handleSuaPortNhieuMuc`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  chayTiep: (id) => {
    return axios.get(`/api/v1/napmymobifone/chayTiep/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  chayTiepChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/chayTiepChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  daNap: (id) => {
    return axios.get(`/api/v1/napmymobifone/daNap/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  daNapChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/daNapChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  huyDon: (id) => {
    return axios.get(`/api/v1/napmymobifone/huyDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  huyDonChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/huyDonChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  tamDung: (id) => {
    return axios.get(`/api/v1/napmymobifone/tamDung/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  tamDungChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/tamDungChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  choNap: (id) => {
    return axios.get(`/api/v1/napmymobifone/choNap/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  choNapChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/choNapChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  dangNap: (id) => {
    return axios.get(`/api/v1/napmymobifone/dangNap/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  dangNapChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/dangNapChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  thatBai: (id) => {
    return axios.get(`/api/v1/napmymobifone/thatBai/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  thatBaiChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/thatBaiChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  hoanThanh: (id) => {
    return axios.get(`/api/v1/napmymobifone/hoanThanh/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  batDauDon: (id) => {
    return axios.get(`/api/v1/napmymobifone/batDauDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  batDauDonChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/batDauDonChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  duaDon: (id) => {
    return axios.get(`/api/v1/napmymobifone/duaDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  duaDonChiTiet: (id) => {
    return axios.get(`/api/v1/napmymobifone/duaDonChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateBoiSo: (id, payload) => {
    return axios.post(`/api/v1/napmymobifone/updateBoiSo/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateBoiSoChiTiet: (id, payload) => {
    return axios.post(`/api/v1/napmymobifone/updateBoiSoChiTiet/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  guiOtp: (id) => {
    return axios.post(`/api/v1/napmymobifone/guiOtp`, {id}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  nhapOtp: (id, otp) => {
    return axios.post(`/api/v1/napmymobifone/nhapOtp`, {id, otp}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  approve: (id, payload) => {
    return axios.put(`/api/v1/napmymobifone/approve/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThai: (id, payload) => {
    return axios.put(`/api/v1/napmymobifone/setTrangThai/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  create: (payload) => {
    return axios.post('/api/v1/napmymobifone', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/napmymobifone/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/napmymobifone/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  doiGiaoDien: (payload) => {
    return axios.post(`/api/v1/napmymobifone/doiGiaoDien`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/napmymobifone?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllOld: (page, count, payload) => {
    let url = `/api/v1/napmymobifone/old-list?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllByOrder: (id, page, count, payload) => {
    let url = `/api/v1/napmymobifone/findAllByOrder/${id}?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  listExport: (payload) => {
    let url = `/api/v1/napmymobifone/listExport?page=${payload.page}&count=${payload.limit}`;

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };
    
    if (payload.message) {
      url += `&phone=${payload.phone}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/napmymobifone/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/napmymobifone/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findOneChiTietDon: (id) => {
    return axios.get(`/api/v1/napmymobifone/findOneChiTietDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findPosition: (position) => {
    return axios.get(`/api/v1/napmymobifone/findPosition/${position}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/napmymobifone/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/napmymobifone/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/napmymobifone/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  }
}