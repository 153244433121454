import { DanhsachcomService } from 'src/services/danhsachcom.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const DANHSACHCOM_NEW_CARE_SUCCESS = 'DANHSACHCOM:NEW_CARE_SUCCESS'
export const DANHSACHCOM_LIST_SUCCESS = 'DANHSACHCOM:FIND_ALL_SUCCESS'
export const DANHSACHCOM_LIST_FAILD = 'DANHSACHCOM:FIND_ALL_FAILD'
export const DANHSACHCOM_COUNT_ASSIGN_SUCCESS = 'DANHSACHCOM:COUNT_ASSIGN_SUCCESS'
export const DANHSACHCOM_COUNT_ASSIGN_FAILD = 'DANHSACHCOM:COUNT_ASSIGN_FAILD'

export const DANHSACHCOM_CREATE_SUCCESS = 'DANHSACHCOM:CREATE_SUCCESS'
export const DANHSACHCOM_CREATE_FAILD = 'DANHSACHCOM:CREATE_FAILD'

export const DANHSACHCOM_IMPORT_SUCCESS = 'DANHSACHCOM:IMPORT_SUCCESS'
export const DANHSACHCOM_SAVE_SUCCESS = 'DANHSACHCOM:SAVE_SUCCESS'
export const DANHSACHCOM_SAVE_FAILD = 'DANHSACHCOM:SAVE_SUCCESS'

export const DANHSACHCOM_DEL_SUCCESS = 'DANHSACHCOM:DEL_SUCCESS'
export const DANHSACHCOM_DEL_FAILD = 'DANHSACHCOM:DEL_FAILD'

export const DANHSACHCOM_ONE_SUCCESS = 'DANHSACHCOM:FIND_ONE_SUCCESS'
export const DANHSACHCOM_ONE_FAILD = 'DANHSACHCOM:FIND_ONE_FAILD'

export const DANHSACHCOM_HISTORY_LIST_SUCCESS = 'DANHSACHCOM:HISTORY_FIND_ALL_SUCCESS'
export const DANHSACHCOM_HISTORY_LIST_FAILD = 'DANHSACHCOM:HISTORY_FIND_ALL_FAILD'

export const DANHSACHCOM_UPDATE_FILTER = 'DANHSACHCOM:UPDATE_FILTER'

export const DanhsachcomAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    approve,
    setTrangThai,
    updateFilter,
    xuLyChucNang,
}

function updateFilter(payload) {
    return { type: DANHSACHCOM_UPDATE_FILTER, data: payload }
}

function xuLyChucNang(funcName, PortAlias, PortName, data) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            DanhsachcomService.xuLyChucNang(funcName, PortAlias, PortName, data)
            .then(response => {
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                reject(error); // Reject the promise with the error
            });
        });
    };
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            DanhsachcomService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(DanhsachcomAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: DANHSACHCOM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_CREATE_FAILD, error } }
}

function setTrangThai(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            DanhsachcomService.setTrangThai(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(DanhsachcomAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: DANHSACHCOM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        DanhsachcomService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DANHSACHCOM_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { danhsachcom } = getState()
        dispatch(AppAction.appLoading(true));
        DanhsachcomService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...danhsachcom.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DANHSACHCOM_LIST_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { danhsachcom } = getState();
        const page = danhsachcom && typeof danhsachcom.filter !== 'undefined' && danhsachcom.filter && typeof danhsachcom.filter.page !== 'undefined' ? danhsachcom.filter.page : 0;
        const limit = danhsachcom && typeof danhsachcom.filter !== 'undefined' && danhsachcom.filter && typeof danhsachcom.filter.limit !== 'undefined' ? danhsachcom.filter.limit : 10;
        DanhsachcomService.findAll(page, limit, danhsachcom.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tongSoDuHienTai: rs.data.tongSoDuHienTai,
                    daNapHienTai: rs.data.daNapHienTai,
                    soTienConCoTheBanDuoc: rs.data.soTienConCoTheBanDuoc,
                }));
            } else {
                const oldItems = danhsachcom.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tongSoDuHienTai: rs.data.tongSoDuHienTai,
                    daNapHienTai: rs.data.daNapHienTai,
                    soTienConCoTheBanDuoc: rs.data.soTienConCoTheBanDuoc,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DANHSACHCOM_LIST_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: DANHSACHCOM_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            DanhsachcomService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(DanhsachcomAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: DANHSACHCOM_SAVE_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            DanhsachcomService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(DanhsachcomAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: DANHSACHCOM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        DanhsachcomService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(DanhsachcomAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DANHSACHCOM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        DanhsachcomService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(DanhsachcomAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DANHSACHCOM_DEL_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        DanhsachcomService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DANHSACHCOM_ONE_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        DanhsachcomService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(DanhsachcomAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { danhsachcom } = getState()
        dispatch(AppAction.appLoading(true));
        DanhsachcomService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...danhsachcom.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DANHSACHCOM_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: DANHSACHCOM_HISTORY_LIST_FAILD, error } }
}