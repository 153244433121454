import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import{  LsnaptienAction } from 'src/actions/lsnaptien.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getBlacklistStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  TextField,
  SvgIcon,
  InputAdornment,
  CardContent,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

//import CreateEditDialog from './CreateEditDialog';

import { showToast } from 'src/utils/common';

import numeral from 'numeral';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const LsnaptienDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedBlacklistIds, setSelectedBlacklistIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { items, total, filter } = useSelector(state => state.lsnaptien);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    phone: "",
    startDate: filter ? dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')) : '',
    endDate: filter ? dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')) : '',
  });

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
      phone: values.phone,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(LsnaptienAction.updateFilter(payload));
    dispatch(LsnaptienAction.list())
  }, [page, limit])

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: values.phone,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(LsnaptienAction.updateFilter(payload));
    dispatch(LsnaptienAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handlephoneChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSelectAll = (event) => {
    let newSelectedBlacklistIds;

    if (event.target.checked) {
      newSelectedBlacklistIds = items.map((lsnaptien) => lsnaptien.id);
    } else {
      newSelectedBlacklistIds = [];
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBlacklistIds.indexOf(id);
    let newSelectedBlacklistIds = [];

    if (selectedIndex === -1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(1));
    } else if (selectedIndex === selectedBlacklistIds.length - 1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(
        selectedBlacklistIds.slice(0, selectedIndex),
        selectedBlacklistIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, lsnaptien) => {
    setSelected(lsnaptien);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(LsnaptienAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(LsnaptienAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableStyle = {
    marginTop: '50px',
    fontSize: '14px !important',
  };

  const childElementStyle = {
    fontSize: '14px !important',
  };

  return (
    <><Card>
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                      >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              name="phone"
              onChange={handlephoneChange}
              onKeyDown={handleKeyDown}
              value={values.phone}
              placeholder="Tìm SĐT"
              variant="outlined"
              style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
            <DatePicker
              label="Thời gian bắt đầu"
              style={{ width: '200px' }}
              format='DD/MM/YYYY'
              value={values.startDate}
              onChange={(newValue) => handleStartDateChange(newValue)}
              />
            <DatePicker
              label="Thời gian kết thúc"
              style={{ width: '200px' }}
              value={values.endDate}
              format='DD/MM/YYYY'
              onChange={(newValue) => handleEndDateChange(newValue)}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={onLookup}
              style={{ height: 56, marginTop: 6, width: "20%", marginLeft: 0 }}
              >
              Tra cứu
            </Button>
          </Box>
        </LocalizationProvider>
      </CardContent>
    </Card>
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table style={tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Tên cổng
                </TableCell>
                <TableCell>
                  SĐT bắn tiền
                </TableCell>
                <TableCell>
                  SĐT được nạp
                </TableCell>
                <TableCell>
                  Số tiền cần nạp
                </TableCell>
                <TableCell>
                  Thời gian yêu cầu
                </TableCell>
                <TableCell>
                  Kết quả
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.length > 0 && items.slice(page * limit, page * limit + limit).map((lsnaptien, index) => (
                <TableRow
                  hover
                  key={lsnaptien.id}
                  selected={selectedBlacklistIds.indexOf(lsnaptien.id) !== -1}
                >
                  <TableCell style={childElementStyle}>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      fontWeight="bold"
                    >
                      {lsnaptien.PortData.PortAlias}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      fontSize="14px"
                    >
                      ({lsnaptien.PortData.PortName})
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {lsnaptien.NumberPhone}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {lsnaptien.Request.msisdn}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(lsnaptien.Request.amount).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(lsnaptien.createdTime).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={lsnaptien.StatusCode == 1 ? 'green' : 'red'}
                      variant="body1"
                    >
                      {lsnaptien.StatusCode == 1 ? 'Thành công' : 'Thất bại'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      {/* <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} /> */}
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card></>
  );
};

export default LsnaptienDetail;
