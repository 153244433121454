import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const BlacklistService = {

  create: (payload) => {
    return axios.post('/api/v1/blacklist', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/blacklist/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/blacklist?page=${page}&count=${count}`;

    if (payload.message) {
      url += `&message=${payload.message}`
    };

    if (payload.description) {
      url += `&description=${payload.description}`
    };
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/blacklist/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/blacklist/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}