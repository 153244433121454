import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const ThamsoService = {

  create: (payload) => {
    return axios.post('/api/v1/thamso', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/thamso/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/thamso/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count) => {
    return axios.get(`/api/v1/thamso?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/thamso/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/thamso/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findPosition: (position) => {
    return axios.get(`/api/v1/thamso/findPosition/${position}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/thamso/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/thamso/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/thamso/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  }
}