import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import {
  CustomerCareStatus,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  MenuItem,
  Select as Select1,
} from '@material-ui/core';

import { showToast } from 'src/utils/common';

import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CustomerCareHistoryListResults = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { info, permissions, role } = useSelector(state => state.profile)
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const { history, totalHistory, filter } = useSelector(state => state.customer)
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [careResult, setCareResult] = useState({});

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
      limit: limit,
    };

    dispatch(CustomerAction.updateFilter(payload));

    dispatch(CustomerAction.history());
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = history.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const setStatus = async (id, status) => {
    console.log(id, status)

    const result = await dispatch(CustomerAction.setStatus(id, {status}));
  };

  const setAppointmentAt = async (id, appointment_at) => {
    console.log(id, appointment_at)

    const result = await dispatch(CustomerAction.setAppointmentAt(id, {appointment_at}));
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Username NV - Tên NV
                </TableCell>
                <TableCell>
                  Thuê bao
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Kết quả
                </TableCell>
                <TableCell>
                  Thời gian hẹn lại (nếu có)
                </TableCell>
                <TableCell>
                  Lý do
                </TableCell>
                <TableCell>
                  Thời gian
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.slice(page * limit, (page + 1) * limit).map((care, index) => (
                <TableRow
                  hover
                  key={care.id}
                  selected={selectedCustomerIds.indexOf(care.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {care.user && care.user.username} - {care.user && care.user.fullname}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0}} onClick={() => copyToClipboard(care.customer ? care.customer.phone : '')}>
                        {care.customer ? (role.priority < 3 ? care.customer.phone.slice(-9) : ('****' + care.customer.phone.slice(-5))) : ''}
                      </Button>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {care.package}
                  </TableCell>
                  <TableCell>
                    {(role.priority < 3 || role.priority > 2 && info.username == (care.user && care.user.username ? care.user.username : null)) && <Select1 style={{ fontSize: "14px", width: "100%"}} variant="outlined" value={care.status} onChange={(e) => setStatus(care.id, e.target.value)}>
                      <MenuItem value="-99">Kết quả...</MenuItem>
                      <MenuItem value="APPROVED">Đồng ý</MenuItem>
                      <MenuItem value="REJECTED">Không đồng ý</MenuItem>
                      <MenuItem value="APPOINTMENT">Hẹn lại</MenuItem>
                      <MenuItem value="KBM">Không bắt máy</MenuItem>
                      <MenuItem value="KLLĐ">Không liên lạc được</MenuItem>
                      <MenuItem value="KEEP">Dùng lại gói cũ</MenuItem>
                      <MenuItem value="RE-REGISTER">Đã đăng ký lại</MenuItem>
                    </Select1>}
                    {!(role.priority < 3 || role.priority > 2 && info.username == (care.user && care.user.username ? care.user.username : null)) && CustomerCareStatus.find((c) => c.code === care.status) ? CustomerCareStatus.find((c) => c.code === care.status).name : ""}
                  </TableCell>
                  <TableCell>
                    {(role.priority < 3 || role.priority > 2 && info.username == (care.user && care.user.username ? care.user.username : null)) && care.status == 'APPOINTMENT' && <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                        <DateTimePicker
                            style={{ width: '200px' }}
                            value={care.appointmentAt ? dayjs(new Date(care.appointmentAt)) : null}
                            onChange={(newValue) => setAppointmentAt(care.id, newValue)}
                        />
                    </LocalizationProvider>}
                    {!((role.priority < 3 || role.priority > 2 && info.username == (care.user && care.user.username ? care.user.username : null)) && care.status == 'APPOINTMENT') && care.appointmentAt && moment(care.appointmentAt).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {care.note}
                  </TableCell>
                  <TableCell>
                    {moment(care.careAt).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalHistory}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default CustomerCareHistoryListResults;
