import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { RequestrefundAction } from 'src/actions/requestrefund.action';
import { SettingAction } from 'src/actions/setting.action';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Select,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Card,
    CardContent,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Search as SearchIcon } from 'react-feather';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

import { 
    NOTI_TYPES,
    DEFAULT_ROW_PER_PAGE,
    ROW_PER_PAGE_OPTIONS, 
} from 'src/utils/constants';
import { QuanlynapcuocAction } from 'src/actions/quanlynapcuoc.action';

import moment from 'moment';
import dayjs from 'dayjs';

const CreateEditDialogThongKeUuTien = (props) => {
    const dispatch = useDispatch();
    const { leaders, brands, info, role } = useSelector(state => state.profile)
    const { thongkeitems, donchohethongitems, donchohethongtotal, donchohethongfilter: filter, donchohethong_tong_can_nap, donchohethong_tong_da_nap, donchohethong_tong_huy_nap, donchohethong_tong_that_bai, donchohethong_tong_ton_nap } = useSelector(state => state.quanlynapcuoc);
    const [donchohethonglimit, setLimit] = useState(filter.donchohethonglimit || DEFAULT_ROW_PER_PAGE);
    const [donchohethongpage, setPage] = useState(filter.donchohethongpage || 0);
    const { isLoading } = useSelector(state => state.app);

    const [values, setValues] = useState({
        username: "",
        phone: "",
        ma_don: "",
        kenh_yeu_cau: "-99",
        nha_mang: "-99",
        boi_so: "-99",
        trang_thai: "-99",
        dua: "",
        startDate: filter ? dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')) : '',
        endDate: filter ? dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')) : '',
    });

    useEffect(() => {
        fetchData();
    }, [donchohethongpage, donchohethonglimit])
    
    const onLookup = async (immediatelyPayload = {}) => {
        const payload = {
            donchohethongpage: 'no_update',
            donchohethonglimit: 'no_update',
            username: typeof immediatelyPayload.username !== 'undefined' ? immediatelyPayload.username : values.username,
            phone: typeof immediatelyPayload.phone !== 'undefined' ? immediatelyPayload.phone : values.phone,
            ma_don: typeof immediatelyPayload.ma_don !== 'undefined' ? immediatelyPayload.ma_don : values.ma_don,
            kenh_yeu_cau: typeof immediatelyPayload.kenh_yeu_cau !== 'undefined' ? immediatelyPayload.kenh_yeu_cau : values.kenh_yeu_cau,
            nha_mang: typeof immediatelyPayload.nha_mang !== 'undefined' ? immediatelyPayload.nha_mang : values.nha_mang,
            boi_so: typeof immediatelyPayload.boi_so !== 'undefined' ? immediatelyPayload.boi_so : values.boi_so,
            trang_thai: typeof immediatelyPayload.trang_thai !== 'undefined' ? immediatelyPayload.trang_thai : values.trang_thai,
            dua: typeof immediatelyPayload.dua !== 'undefined' ? immediatelyPayload.dua : values.dua,
            startDate: typeof immediatelyPayload.startDate !== 'undefined' ? immediatelyPayload.startDate.format("DD/MM/YYYY") : values.startDate.format("DD/MM/YYYY"),
            endDate: typeof immediatelyPayload.endDate !== 'undefined' ? immediatelyPayload.endDate.format("DD/MM/YYYY") :values.endDate.format("DD/MM/YYYY"),
        };
        dispatch(QuanlynapcuocAction.updateFilterDonchohethong(payload));
        dispatch(QuanlynapcuocAction.donChoHeThong(props.operator));
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value.trim()
        });

        onLookup({[event.target.name]: event.target.value});
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onLookup();
        }
    };

    async function fetchData() {
        try {
            // const thongKe = await dispatch(QuanlynapcuocAction.thongKe());
            
            const payload = {
                ...filter,
                donchohethonglimit: donchohethonglimit,
                donchohethongpage: donchohethongpage,
                username: values.username,
                phone: values.phone,
                ma_don: values.ma_don,
                kenh_yeu_cau: values.kenh_yeu_cau,
                nha_mang: values.nha_mang,
                boi_so: values.boi_so,
                trang_thai: values.trang_thai,
                dua: values.dua,
                startDate: values.startDate.format("DD/MM/YYYY"),
                endDate: values.endDate.format("DD/MM/YYYY"),
              };
              dispatch(QuanlynapcuocAction.updateFilterDonchohethong(payload));
              dispatch(QuanlynapcuocAction.donChoHeThong(props.operator));
        } catch (error) {
            console.log(error);
        }
    }

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };
    
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const refresh = async () => {
        console.log(props.operator);
        fetchData();
    }

    const onPressPositive = async () => {
        props.handleClose();
    }

    const hienThiBoiSo = (boi_so) => {
        let boiSo = '';
        if (boi_so == 'menh_gia_bat_ky') boiSo = 'Mệnh giá bất kỳ';
        else if (boi_so == '10000') boiSo = '10.000';
        else if (boi_so == '20000') boiSo = '20.000';
        else if (boi_so == 'the_nho_nho_hon_50000') boiSo = 'Thẻ nhỏ (<50k)';
        else if (boi_so == '50000') boiSo = '50.000';
        else if (boi_so == '100000') boiSo = '100.000';
        else if (boi_so == '200000') boiSo = '200.000';
        else if (boi_so == '300000') boiSo = '300.000';
        else if (boi_so == '500000') boiSo = '500.000';
        else if (boi_so == '1000000') boiSo = '1.000.000';
        else boiSo = boi_so;
        return boiSo;
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle style={{paddingBottom: 0}}>
                {`Các mức ưu tiên [${props.operator ? props.operator.toUpperCase() : ''}]`}
                <Button
                    color="primary"
                    variant="contained"
                    onClick={refresh}
                    style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px', border: '1px solid orange' }}
                >
                    {!isLoading ? '🔄' : ''}
                </Button>
                <Button style={{position: 'absolute', top: '10px', right: '5px'}} onClick={props.handleClose}>Huỷ</Button>
            </DialogTitle>
            <DialogContent style={{ width: 600, maxWidth: '80vw', paddingTop: 0 }}>
                <Box
                    style={{marginBottom: '25px'}}
                >
                    {/* <Table style={{marginTop: 15}}>
                        <TableHead>
                        <TableRow style={{background: 'red'}}>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Mức ưu tiên
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Tổng tiền đơn
                            </TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {thongkeitems && thongkeitems.map((mucuutien, index) => (
                                // Check if the operator matches the desired value
                                props.operator && mucuutien.operator.toUpperCase() == props.operator.toUpperCase() && (
                                    <TableRow hover key={mucuutien.dua}>
                                        <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                            {mucuutien.dua}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                            {numeral(mucuutien.phi_dua_theo_muc).format('0,0')}
                                        </TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table> */}

                    <Card className="filter_card">
                        <CardContent style={{padding: 0}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <PerfectScrollbar>
                                    <Box sx={{ 
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        //minWidth: 1050,
                                    }}>
                                        <TextField
                                            name="dua"
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            value={values.dua}
                                            placeholder="Tìm theo ưu tiên"
                                            variant="outlined"
                                            style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
                                        />
                                        <Select1
                                            style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
                                            name="boi_so"
                                            value={values.boi_so || -99}
                                            onChange={(newValue) => handleChange(newValue)}
                                            variant="outlined"
                                            startAdornment={(
                                            <InputAdornment position="start">
                                                <SvgIcon fontSize="small" color="action">
                                                <SearchIcon />
                                                </SvgIcon>
                                            </InputAdornment>
                                            )}
                                        >
                                            <MenuItem value="-99">Tất cả bội</MenuItem>
                                            <MenuItem value="menh_gia_bat_ky">Mệnh giá bất kỳ</MenuItem>
                                            <MenuItem value="10000">10.000</MenuItem>
                                            <MenuItem value="20000">20.000</MenuItem>
                                            <MenuItem value="the_nho_nho_hon_50000">{`Thẻ nhỏ (<50k)`}</MenuItem>
                                            <MenuItem value="50000">50.000</MenuItem>
                                            <MenuItem value="100000">100.000</MenuItem>
                                            <MenuItem value="200000">200.000</MenuItem>
                                            <MenuItem value="300000">300.000</MenuItem>
                                            <MenuItem value="500000">500.000</MenuItem>
                                            <MenuItem value="1000000">1.000.000</MenuItem>
                                        </Select1>
                                    </Box>
                                </PerfectScrollbar>
                            </LocalizationProvider>
                        </CardContent>
                    </Card>
                    <Table style={{marginTop: 15}}>
                        <TableHead>
                        <TableRow style={{background: 'red'}}>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                STT
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Thuê bao
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Nhà mạng
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Kênh yêu cầu
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Cần nạp
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Đã nạp
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Đua
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Bội số
                            </TableCell>
                            <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                Ngày
                            </TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {donchohethongitems && donchohethongitems.slice(donchohethongpage * donchohethonglimit, donchohethongpage * donchohethonglimit + donchohethonglimit).map((item, index) => (
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {donchohethongpage * donchohethonglimit + index + 1}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {item.thue_bao}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {item.operator}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {item.kenh_yeu_cau == 'khong_qua_api' && "Không qua API"}
                                        {item.kenh_yeu_cau == 'smart_vi' && "TOP-UP"}
                                        {item.kenh_yeu_cau == 'tkc' && "TKC"}
                                        {item.kenh_yeu_cau == 'dichvu' && "DV"}
                                        {item.kenh_yeu_cau == 'bunca' && "BC"}
                                        {item.kenh_yeu_cau == 'napho' && "Nạp hộ Mobifone"}
                                        {item.kenh_yeu_cau == 'mymobifone' && "My Mobifone"}
                                        {item.kenh_yeu_cau == 'gsmmobifone' && "GSM Mobifone"}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {numeral(item.can_nap).format('0,0')}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {numeral(item.da_nap).format('0,0')}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {item.dua}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {hienThiBoiSo(item.boi_so)}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'normal', color: 'black', textAlign: 'center' }}>
                                        {moment(item.created_at).format('DD-MM-YYYY HH:mm:ss')}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                <TablePagination
                    component="div"
                    count={donchohethongtotal}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={donchohethongpage}
                    rowsPerPage={donchohethonglimit}
                    rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                />
            </DialogContent>
            <DialogActions>
                <PerfectScrollbar>
                    <div style={{margin: 15, minWidth: 1050, fontSize: 12}}>
                        <span style={{marginRight: 15}}>Tổng dòng: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(donchohethongtotal).format('0,0')}</span></span>
                        |
                        <span style={{marginLeft: 15, marginRight: 15}}>Cần nạp: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(donchohethong_tong_can_nap).format('0,0')}</span></span>
                        |
                        <span style={{marginLeft: 15, marginRight: 15}}>Đã nạp: <span style={{color: 'green', fontWeight: 'bold'}}>{numeral(donchohethong_tong_da_nap).format('0,0')}</span></span>
                        |
                        {/* <span style={{marginLeft: 15, marginRight: 15}}>Hủy / TB: <span style={{color: 'red', fontWeight: 'bold'}}>{numeral(parseInt(donchohethong_tong_huy_nap) + parseInt(donchohethong_tong_that_bai)).format('0,0')}</span></span>
                        | */}
                        <span style={{marginLeft: 15, marginRight: 15}}>Tồn: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(donchohethong_tong_ton_nap).format('0,0')}</span></span>
                    </div>
                </PerfectScrollbar>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialogThongKeUuTien;