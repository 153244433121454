import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { QuanlythenapAction } from 'src/actions/quanlythenap.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getQuanlythenapStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';

import Select from 'react-select';

import CreateEditDialog from './CreateEditDialog';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import numeral from 'numeral';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { showToast } from 'src/utils/common';

import { Download, Cached } from '@material-ui/icons';
import { AccountAction } from 'src/actions/account.acction';

const QuanlythenapLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedQuanlythenapIds, setSelectedQuanlythenapIds] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role } = useSelector(state => state.profile);
  const { items, total, filter, tong_sai_menh_gia, tong_dung_menh_gia, tong_loi_huy, tong_doi_soat, tong_chua_nap, tong_nap, tong_the } = useSelector(state => state.quanlythenap);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [item, setItemValue] = useState({});
  const [itemCheckAll, setItemCheckAll] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const { isLoading } = useSelector(state => state.app);
  const { items: account_items, total: account_total } = useSelector(state => state.account)

  const allOption = { username: '', fullname: 'Tất cả', role: { priority: 0 } };
  const options = [allOption, ...account_items];

  const [values, setValues] = useState({
    ma_tram: "",
    kieu_nap: "-99",
    trang_thai: "-99",
    tai_khoan: "",
    gia_gui: 0,
    nha_mang: "-99",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const extractedSelectedItems = () => {
    const extractedObj = {};

    // Iterate over the object's keys
    for (const key in item) {
      // Check if key is -1 or value is 0, then copy the property to extractedObj
      if (key != '-1' && item[key] != 0) {
        extractedObj[key] = item[key];
      }
    }

    return Object.keys(extractedObj);
  }

  const handleEdit = (id = null) => {
    let selectedItems = [];
    if (id) selectedItems = [id];
    else selectedItems = extractedSelectedItems()
    console.log(selectedItems)
    if (selectedItems.length < 1) {
      alert('Chưa chọn mục nào để sửa')
    } else {
      try {
        let selectedItem = selectedItems[0];
        setItemValue({[selectedItem] : 1})
        setItemCheckAll(false);
        dispatch(QuanlythenapAction.findOne(selectedItem));
        setIsUpdate(true)
        setOpen(true)
      } catch (error) {
        //
      }
    }
  }

  const handleDelete = (id = null) => {
    let selectedItems = [];
    if (id) selectedItems = [id];
    else selectedItems = extractedSelectedItems()
    console.log(selectedItems)
    if (selectedItems.length < 1) {
      alert('Chưa chọn mục nào để xóa')
    } else {
      let question = "Bạn có muốn xóa các mục đã chọn?";
      if (window.confirm(question)) {
        try {
          dispatch(QuanlythenapAction.xoaMucDaChon(selectedItems));
          showToast('success', `Đã xóa các mục đã chọn`);
        } catch (error) {
          //
        }
      } else {
        
      }
    }
  }

  const setItem = (e, value) => {
    setItemValue({
      ...item,
      [value]: e.target.value == 1 ? 0 : 1
    });
  }

  const setItemCheckAllOnChange = (event) => {
    console.log(event.target.checked);

    if (event.target.checked) {
      setItemCheckAll(1);
      chonItem(event);
    } else {
      setItemCheckAll(0);
      boChonItem(event);
    }
  };

  const chonItem = (event) => {
    // if (item && Object.entries(item).length > 0) {
    //   let newItems = {}
    //   Object.entries(item).forEach(([key, value]) => {
    //     newItems[key] = 1;
    //   });
    //   setItemValue(newItems);
    // }

    if (items && items.length > 0) {
      let newItems = {}
      for (let i = 0; i < limit; i++) {
        const key = page * limit + i;
        const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
        newItems[id] = 1;
      }
      setItemValue(newItems);
    }
  };

  const boChonItem = (event) => {
    // if (item && Object.entries(item).length > 0) {
    //   let newItems = {}
    //   Object.entries(item).forEach(([key, value]) => {
    //     newItems[key] = 0;
    //   });
    //   setItemValue(newItems);
    // }

    if (items && items.length > 0) {
      let newItems = {}
      for (let i = 0; i < limit; i++) {
        const key = page * limit + i;
        const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
        newItems[id] = 0;
      }
      setItemValue(newItems);
    }
  };

  useEffect(() => {
    dispatch(AccountAction.listNoLimit());
  }, [])

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
      ma_tram: values.ma_tram,
      kieu_nap: values.kieu_nap,
      trang_thai: values.trang_thai,
      tai_khoan: values.tai_khoan,
      gia_gui: values.gia_gui,
      nha_mang: values.nha_mang,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(QuanlythenapAction.updateFilter(payload));

    const getItemsList = async () => {
      const list = await dispatch(QuanlythenapAction.list(page, limit));
      if (list && typeof list.data !== 'undefined' && list.data && typeof list.data.values !== 'undefined' && list.data.values) {
        let newItems = {}
        list.data.values.forEach((item, index) => {
          newItems[item.id] = 0;
        });
        setItemValue(newItems);
      }
    };

    getItemsList()
  }, [page, limit])

  const onLookup = async (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      ma_tram: typeof immediatelyPayload.ma_tram !== 'undefined' ? immediatelyPayload.ma_tram : values.ma_tram,
      kieu_nap: typeof immediatelyPayload.kieu_nap !== 'undefined' ? immediatelyPayload.kieu_nap : values.kieu_nap,
      trang_thai: typeof immediatelyPayload.trang_thai !== 'undefined' ? immediatelyPayload.trang_thai : values.trang_thai,
      tai_khoan: typeof immediatelyPayload.tai_khoan !== 'undefined' ? immediatelyPayload.tai_khoan : values.tai_khoan,
      gia_gui: typeof immediatelyPayload.gia_gui !== 'undefined' ? immediatelyPayload.gia_gui : values.gia_gui,
      nha_mang: typeof immediatelyPayload.nha_mang !== 'undefined' ? immediatelyPayload.nha_mang : values.nha_mang,
      startDate: typeof immediatelyPayload.startDate !== 'undefined' ? immediatelyPayload.startDate.format("DD/MM/YYYY") : values.startDate.format("DD/MM/YYYY"),
      endDate: typeof immediatelyPayload.endDate !== 'undefined' ? immediatelyPayload.endDate.format("DD/MM/YYYY") :values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(QuanlythenapAction.updateFilter(payload));
    dispatch(QuanlythenapAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const exportExcel = async (ma_don = null) => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        ma_tram: values.ma_tram,
        kieu_nap: values.kieu_nap,
        trang_thai: values.trang_thai,
        tai_khoan: values.tai_khoan,
        gia_gui: values.gia_gui,
        nha_mang: values.nha_mang,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(QuanlythenapAction.updateFilter(payload));
      const response = await dispatch(QuanlythenapAction.list());

      const data = [
        [
          'Nhà mạng', 
          'Mã thẻ',
          'Số seri', 
          'Mệnh giá gửi',
          'Mệnh giá thực',
          'Mệnh giá ĐS', 
          'CK', 
          'Tài khoản', 
          'Thời gian tạo', 
          'Thời gian cập nhật',
          'Thời gian xử lý (giây)', 
          'Thực hiện',
          'Số thực hiện',
          'Số dư',
          'Số nhận',
          'Số điện thoại nhận',
          'Kiểu nạp',
          'Trạng thái',
          'ID',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let kieuNap = '';
          let trangThai = '';

          if (element.kieu_nap == 'nap_sim') kieuNap = 'NẠP SIM';
          else if (element.kieu_nap == 'nap_cuoc_gsm') kieuNap = 'NẠP CƯỚC (GSM)';
          else if (element.kieu_nap == 'nap_cuoc_app') kieuNap = 'NẠP CƯỚC (APP)';
          else if (element.kieu_nap == 'nap_ho_app') kieuNap = 'NẠP HỘ (APP)';
          else if (element.kieu_nap == 'tu_nap_app') kieuNap = 'TỰ NẠP (APP)';
          else if (element.kieu_nap == 'api') kieuNap = 'API';

          if (element.trang_thai == 'cho_xu_ly') trangThai = 'Chờ xử lý';
          else if (element.trang_thai == 'dang_xu_ly') trangThai = 'Đang xử lý';
          else if (element.trang_thai == 'dang_xu_ly_loi') trangThai = 'Đang xử lý (lỗi)';
          else if (element.trang_thai == 'the_dung_menh_gia') trangThai = 'Thẻ đúng mệnh giá';
          else if (element.trang_thai == 'the_sai_menh_gia') trangThai = 'Thẻ sai mệnh giá';
          else if (element.trang_thai == 'the_loi_huy') trangThai = 'Thẻ lỗi / hủy';

          data.push([
            element.nha_mang,
            element.ma_the,
            element.seri,
            element.gia_gui,
            element.gia_thuc,
            element.gia_doi_soat,
            element.chiet_khau + '%',
            element.tai_khoan,
            element.createdAt,
            element.updatedAt,
            element.thoi_gian_xu_ly,
            element.thuc_hien,
            element.so_thuc_hien,
            element.kieu_nap == 'nap_sim' ? element.so_du : '',
            element.kieu_nap == 'nap_sim' ? element.so_nhan : '',
            element.kieu_nap != 'nap_sim' ? element.so_dien_thoai_nhan : '',
            kieuNap,
            trangThai,
            element.id
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Quan-ly-the-nap.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({[event.target.name]: event.target.value});
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });

    onLookup({[name]: newValue});
  };

  const handleSelectAll = (event) => {
    let newSelectedQuanlythenapIds;

    if (event.target.checked) {
      newSelectedQuanlythenapIds = items.map((quanlythenap) => quanlythenap.id);
    } else {
      newSelectedQuanlythenapIds = [];
    }

    setSelectedQuanlythenapIds(newSelectedQuanlythenapIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedQuanlythenapIds.indexOf(id);
    let newSelectedQuanlythenapIds = [];

    if (selectedIndex === -1) {
      newSelectedQuanlythenapIds = newSelectedQuanlythenapIds.concat(selectedQuanlythenapIds, id);
    } else if (selectedIndex === 0) {
      newSelectedQuanlythenapIds = newSelectedQuanlythenapIds.concat(selectedQuanlythenapIds.slice(1));
    } else if (selectedIndex === selectedQuanlythenapIds.length - 1) {
      newSelectedQuanlythenapIds = newSelectedQuanlythenapIds.concat(selectedQuanlythenapIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedQuanlythenapIds = newSelectedQuanlythenapIds.concat(
        selectedQuanlythenapIds.slice(0, selectedIndex),
        selectedQuanlythenapIds.slice(selectedIndex + 1)
      );
    }

    setSelectedQuanlythenapIds(newSelectedQuanlythenapIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, quanlythenap) => {
    setSelected(quanlythenap);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(QuanlythenapAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleClickOpen = () => {
    setIsUpdate(false)
    setOpen(true);
  };

  const handleEditOpen = (id) => {
    dispatch(QuanlythenapAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const batTatLoaiDichVu = (event, id, yeu_cau) => {
    console.log(id, yeu_cau);
    dispatch(QuanlythenapAction.batTatLoaiDichVu(id, {yeu_cau}));
  }

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });

    onLookup({startDate: date});
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });

    onLookup({endDate: date});
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        //showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const yeuCauChayLaiCallback = (id) => {
    dispatch(QuanlythenapAction.yeuCauChayLaiCallback(id));
  }

  const refreshRecord = () => {
    
  };

  const handleSuaNhieuMuc = (event) => {
    const selectedItems = extractedSelectedItems()
    console.log(selectedItems)
    if (selectedItems.length < 1) {
      alert('Chưa chọn mục nào để sửa')
    } else {
      try {
        const yeuCauXuLy = event.target.value.trim();
        dispatch(QuanlythenapAction.handleSuaNhieuMuc({yeuCauXuLy, selectedItems, data: {}}))
        showToast('success', `Cập nhật thành công`);
      } catch (error) {
        //
      }
    }
  }

  const mauTrangThai = (trang_thai) => {
    if (trang_thai == 'cho_xu_ly') return 'black';
    else if (trang_thai == 'dang_xu_ly') return 'blue';
    else if (trang_thai == 'dang_xu_ly_loi') return 'red';
    else if (trang_thai == 'the_dung_menh_gia') return 'green';
    else if (trang_thai == 'the_sai_menh_gia') return 'orange';
    else if (trang_thai == 'the_loi_huy') return 'red';
  }

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PerfectScrollbar>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 1500,
            }}>
              <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: 56,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                  }}
                  options={options}
                  getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username != '' ? (item.username + ' - ') : ''}${item.fullname}`}
                  placeholder={values.tai_khoan || "Tìm user..."}
                  components={{
                    DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                    IndicatorSeparator: null,
                  }}
                  value={values.tai_khoan || null}
                  onChange={(newValue) => handleUsernameChange1('tai_khoan', newValue.username)}
                  isSearchable
                />
              </div>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="ma_tram"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.ma_tram}
                placeholder="Mã thẻ, số seri..."
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
              />
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="nha_mang"
                value={values.nha_mang || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Nhà mạng...</MenuItem>
                <MenuItem value="MOBI">MOBI</MenuItem>
                <MenuItem value="VINA">VINA</MenuItem>
                <MenuItem value="VIETTEL">VIETTEL</MenuItem>
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="gia_gui"
                value={values.gia_gui || 0}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="0">Mệnh giá...</MenuItem>
                <MenuItem value="10000">10.000</MenuItem>
                <MenuItem value="20000">20.000</MenuItem>
                <MenuItem value="30000">30.000</MenuItem>
                <MenuItem value="50000">50.000</MenuItem>
                <MenuItem value="100000">100.000</MenuItem>
                <MenuItem value="200000">200.000</MenuItem>
                <MenuItem value="300000">300.000</MenuItem>
                <MenuItem value="500000">500.000</MenuItem>
                <MenuItem value="1000000">1.000.000</MenuItem>
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="kieu_nap"
                value={values.kieu_nap || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Kiểu nạp...</MenuItem>
                <MenuItem value="nap_sim">NẠP SIM</MenuItem>
                <MenuItem value="nap_cuoc_gsm">NẠP CƯỚC (GSM)</MenuItem>
                <MenuItem value="nap_cuoc_app">NẠP CƯỚC (APP)</MenuItem>
                <MenuItem value="nap_ho_app">NẠP HỘ (APP)</MenuItem>
                <MenuItem value="tu_nap_app">TỰ NẠP (APP)</MenuItem>
                <MenuItem value="api">API</MenuItem>
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="trang_thai"
                value={values.trang_thai || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Trạng thái...</MenuItem>
                <MenuItem value="cho_xu_ly">Chờ xử lý</MenuItem>
                <MenuItem value="dang_xu_ly">Đang xử lý</MenuItem>
                <MenuItem value="dang_xu_ly_loi">Đang xử lý (lỗi)</MenuItem>
                <MenuItem value="the_dung_menh_gia">Thẻ đúng mệnh giá</MenuItem>
                <MenuItem value="the_sai_menh_gia">Thẻ sai mệnh giá</MenuItem>
                <MenuItem value="the_loi_huy">Thẻ lỗi / hủy</MenuItem>
              </Select1>
              <DatePicker
                label="Thời gian bắt đầu"
                style={{ width: '200px' }}
                format='DD/MM/YYYY'
                value={values.startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
                />
              <DatePicker
                label="Thời gian kết thúc"
                style={{ width: '200px' }}
                value={values.endDate}
                format='DD/MM/YYYY'
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
            </Box>
          </PerfectScrollbar>
        </LocalizationProvider>
      </CardContent>
    </Card>
    <Card {...rest} style={{ width: "100%"}}>
      <div style={{width: '300px', margin: 'auto'}}>
        {role.priority < 3 && <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "80px", minWidth: '20px', marginLeft: '0', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Nạp thẻ
        </Button>}
        <Button
          color="primary"
          variant="contained"
          onClick={handleEdit}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal', borderRadius: '4px 0 0 4px' }}
          >
          Sửa
        </Button>
        <Select1
          style={{ height: '20px', marginTop: 0, width: '40px', marginLeft: 0, padding: 0, top: '2px', border: '1px solid orange', background: 'orange', borderRadius: '0px 4px 4px 0' }}
          name="sua_nhieu_muc"
          value={-99}
          onChange={(newValue) => handleSuaNhieuMuc(newValue)}
          variant="outlined"
        >
          <MenuItem value="lam_moi_the">⟳ Làm mới thẻ</MenuItem>
        </Select1>
        {/* <Button
          color="primary"
          variant="contained"
          onClick={handleDelete}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Xóa
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={() => exportExcel()}
          style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
          <Download style={{ color: "#3498db" }} />
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onLookup}
          style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
          {!isLoading ? <Cached style={{ color: "#3498db" }} /> : ''}
        </Button>
      </div>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table style={{marginTop: 15}}>
            <TableHead>
              <TableRow style={{background: 'red', height: '20px'}}>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  #
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  <Checkbox
                      checked={itemCheckAll == 1 ? true : false}
                      onChange={(event) => setItemCheckAllOnChange(event)}
                      value={itemCheckAll == 1 ? 1 : 0}
                      style={{width: '20px', color: 'white'}}
                  />
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Nhà mạng
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mã thẻ - seri
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mệnh giá gửi
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mệnh giá thực
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  CK
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tài khoản
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thời gian tạo
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thời gian cập nhật
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  s
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thực hiện
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Số thực hiện
                </TableCell>
                <TableCell colSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Số dư / Số nhận
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Kiểu nạp
                </TableCell>
                <TableCell colSpan={3} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Trạng thái
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((quanlythenap, index) => (
                <TableRow
                  hover
                  key={quanlythenap.id}
                  selected={selectedQuanlythenapIds.indexOf(quanlythenap.id) !== -1}
                >
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Checkbox
                      checked={item[quanlythenap.id] == 1 ? true : false}
                      onChange={(event) => setItem(event, quanlythenap.id)}
                      value={item[quanlythenap.id] == 1 ? 1 : 0}
                    />
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {quanlythenap.nha_mang}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0, color: 'blue'}} onClick={() => copyToClipboard(quanlythenap.ma_the)}>
                        {quanlythenap.ma_the.substring(0, 3) + '***' + quanlythenap.ma_the.substring(quanlythenap.ma_the.length - 3)}
                      </Button>
                      <Button style={{padding: 0, color: 'blue', fontSize: 20, width: '20px', minWidth: 0}} onClick={() => copyToClipboard(quanlythenap.seri)}>
                        📋
                      </Button>
                      <Button style={{padding: 0, color: 'blue', fontSize: 20, width: '20px', minWidth: 0}} onClick={() => copyToClipboard(quanlythenap.seri)}>
                        🔍
                      </Button>
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(quanlythenap.gia_gui).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(quanlythenap.gia_thuc).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {quanlythenap.chiet_khau}%
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {quanlythenap.tai_khoan}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {quanlythenap.thoi_gian_tao}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {quanlythenap.thoi_gian_cap_nhat}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(quanlythenap.thoi_gian_xu_ly).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {quanlythenap.thuc_hien}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0, color: 'blue', textTransform: 'none'}} onClick={() => copyToClipboard(quanlythenap.so_thuc_hien)}>
                        {quanlythenap.so_thuc_hien}
                      </Button>
                    </Typography>
                  </TableCell>
                  {quanlythenap.kieu_nap == 'nap_sim' && <>
                    <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {numeral(quanlythenap.so_du).format('0,0')}
                      </Typography>
                    </TableCell>
                    <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {numeral(quanlythenap.so_nhan).format('0,0')}
                      </Typography>
                    </TableCell>
                  </>}
                  {quanlythenap.kieu_nap != 'nap_sim' && <TableCell colSpan={2} style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0, color: 'blue', textTransform: 'none'}} onClick={() => copyToClipboard(quanlythenap.so_dien_thoai_nhan)}>
                        {quanlythenap.so_dien_thoai_nhan}
                      </Button>
                    </Typography>
                  </TableCell>}
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {quanlythenap.kieu_nap == 'nap_sim' && 'NẠP SIM'}
                      {quanlythenap.kieu_nap == 'nap_cuoc_gsm' && 'NẠP CƯỚC (GSM)'}
                      {quanlythenap.kieu_nap == 'nap_cuoc_app' && 'NẠP CƯỚC (APP)'}
                      {quanlythenap.kieu_nap == 'nap_ho_app' && 'NẠP HỘ (APP)'}
                      {quanlythenap.kieu_nap == 'tu_nap_app' && 'TỰ NẠP (APP)'}
                      {quanlythenap.kieu_nap == 'api' && 'API'}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Button style={{color: mauTrangThai(quanlythenap.trang_thai), textTransform: 'initial', fontWeight: 'bold', margin: 0, padding: 0, minWidth: 'auto', width: 100}} onClick={() => handleEdit(quanlythenap.id)}>
                      {quanlythenap.trang_thai == 'cho_xu_ly' && 'Chờ xử lý'}
                      {quanlythenap.trang_thai == 'dang_xu_ly' && 'Đang xử lý'}
                      {quanlythenap.trang_thai == 'dang_xu_ly_loi' && 'Đang xử lý (lỗi)'}
                      {quanlythenap.trang_thai == 'the_dung_menh_gia' && 'Thẻ đúng'}
                      {quanlythenap.trang_thai == 'the_sai_menh_gia' && 'Sai mệnh giá'}
                      {quanlythenap.trang_thai == 'the_loi_huy' && 'Thẻ lỗi / hủy'}
                    </Button>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => yeuCauChayLaiCallback(quanlythenap.id)}
                      style={{ color: quanlythenap.trang_thai_callback ? 'green' : 'red', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'bold', fontSize: 15 }}
                      >
                      CB
                    </Button>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => alert(quanlythenap.noi_dung_chi_tiet ? quanlythenap.noi_dung_chi_tiet : '')}
                      style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: 30, border: '1px solid transparent' }}
                      >
                      📜
                    </Button>
                  </TableCell>
                  <TableCell style={{textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0, color: 'blue', textTransform: 'none'}} onClick={() => copyToClipboard(quanlythenap.id)}>
                        {quanlythenap.id.substring(0, 3) + '***' + quanlythenap.id.substring(quanlythenap.id.length - 3)}
                      </Button>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <PerfectScrollbar>
        <div style={{margin: 15, minWidth: 1050}}>
          <span style={{marginRight: 15}}>Tổng thẻ: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_the).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Đúng MG: <span style={{color: 'green', fontWeight: 'bold'}}>{numeral(tong_dung_menh_gia).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Sai MG: <span style={{color: 'red', fontWeight: 'bold'}}>{numeral(tong_sai_menh_gia).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Chưa nạp: <span style={{color: 'black', fontWeight: 'bold'}}>{numeral(tong_chua_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Lỗi: <span style={{color: 'red', fontWeight: 'bold'}}>{numeral(tong_loi_huy).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng nạp: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Đối soát: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_doi_soat).format('0,0')}</span></span>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={isUpdate ? "EDIT" : "CREATE"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card></>
  );
};

export default QuanlythenapLookupDetail;
