import { Helmet } from 'react-helmet';
import NapMyMobifoneDetailOld from 'src/components/NapMyMobifone-detail/NapMyMobifoneDetailOld';

import NapMyMobifoneDetail from 'src/components/NapMyMobifone-detail/NapMyMobifoneDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import { AccountAction } from 'src/actions/account.acction';

import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

//import NapMyMobifoneToolbar from '../components/NapMyMobifone-detail/NapMyMobifoneToolbar';

const NapMyMobifone = () => {
  const dispatch = useDispatch();
  const { info, permissions, role } = useSelector(state => state.profile);
  const [giao_dien_nap_cuoc, setGiaoDienNapCuoc] = useState('');

  useEffect(() => {
    async function fetchData() {
      const currentUser = await dispatch(AccountAction.findByUsername(info.username));
      console.log(currentUser);
      if (currentUser) {
        setGiaoDienNapCuoc(currentUser.giao_dien_nap_cuoc);
      }
    }
    fetchData();
  }, [])

  return (
    <>
      <Helmet>
        <title>Nạp cước - Danh sách đơn</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container style={{maxWidth: "100%"}}>
          {/* <NapMyMobifoneToolbar /> */}
          <Box sx={{ pt: 3 }}>
            {giao_dien_nap_cuoc == 'old' && <NapMyMobifoneDetailOld />}
            {giao_dien_nap_cuoc == 'new' && <NapMyMobifoneDetail />}
          </Box>
        </Container>
      </Box>
    </>
  )
};

export default NapMyMobifone;
