import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ThenapAction } from 'src/actions/thenap.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getThenapStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import CreateEditDialog from '../quanlythenap-detail/CreateEditDialog';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import numeral from 'numeral';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { showToast } from 'src/utils/common';

import { Download, Cached } from '@material-ui/icons';

const ThenapLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedThenapIds, setSelectedThenapIds] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role } = useSelector(state => state.profile);
  const { items, total, filter, tong_sai_menh_gia, tong_dung_menh_gia, tong_loi_huy, tong_doi_soat, tong_chua_nap, tong_nap, tong_the } = useSelector(state => state.thenap);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [item, setItemValue] = useState({});
  const [itemCheckAll, setItemCheckAll] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const { isLoading } = useSelector(state => state.app);

  const [values, setValues] = useState({
    ma_tram: "",
    trang_thai: "-99",
    gia_gui: 0,
    nha_mang: "-99",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const extractedSelectedItems = () => {
    const extractedObj = {};

    // Iterate over the object's keys
    for (const key in item) {
      // Check if key is -1 or value is 0, then copy the property to extractedObj
      if (key != '-1' && item[key] != 0) {
        extractedObj[key] = item[key];
      }
    }

    return Object.keys(extractedObj);
  }

  const handleEdit = () => {
    const selectedItems = extractedSelectedItems()
    console.log(selectedItems)
    if (selectedItems.length < 1) {
      alert('Chưa chọn mục nào để sửa')
    } else {
      try {
        let selectedItem = selectedItems[0];
        setItemValue({[selectedItem] : 1})
        setItemCheckAll(false);
        dispatch(ThenapAction.findOne(selectedItem));
        setIsUpdate(true)
        setOpen(true)
      } catch (error) {
        //
      }
    }
  }

  const handleDelete = () => {
    const selectedItems = extractedSelectedItems()
    console.log(selectedItems)
    if (selectedItems.length < 1) {
      alert('Chưa chọn mục nào để xóa')
    } else {
      let question = "Bạn có muốn xóa các mục đã chọn?";
      if (window.confirm(question)) {
        try {
          dispatch(ThenapAction.xoaMucDaChon(selectedItems));
          showToast('success', `Đã xóa các mục đã chọn`);
        } catch (error) {
          //
        }
      } else {
        
      }
    }
  }

  const setItem = (e, value) => {
    setItemValue({
      ...item,
      [value]: e.target.value == 1 ? 0 : 1
    });
  }

  const setItemCheckAllOnChange = (event) => {
    console.log(event.target.checked);

    if (event.target.checked) {
      setItemCheckAll(1);
      chonItem(event);
    } else {
      setItemCheckAll(0);
      boChonItem(event);
    }
  };

  const chonItem = (event) => {
    // if (item && Object.entries(item).length > 0) {
    //   let newItems = {}
    //   Object.entries(item).forEach(([key, value]) => {
    //     newItems[key] = 1;
    //   });
    //   setItemValue(newItems);
    // }

    if (items && items.length > 0) {
      let newItems = {}
      for (let i = 0; i < limit; i++) {
        const key = page * limit + i;
        const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
        newItems[id] = 1;
      }
      setItemValue(newItems);
    }
  };

  const boChonItem = (event) => {
    // if (item && Object.entries(item).length > 0) {
    //   let newItems = {}
    //   Object.entries(item).forEach(([key, value]) => {
    //     newItems[key] = 0;
    //   });
    //   setItemValue(newItems);
    // }

    if (items && items.length > 0) {
      let newItems = {}
      for (let i = 0; i < limit; i++) {
        const key = page * limit + i;
        const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
        newItems[id] = 0;
      }
      setItemValue(newItems);
    }
  };

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
      ma_tram: values.ma_tram,
      trang_thai: values.trang_thai,
      gia_gui: values.gia_gui,
      nha_mang: values.nha_mang,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(ThenapAction.updateFilter(payload));

    const getItemsList = async () => {
      const list = await dispatch(ThenapAction.list(page, limit));
      if (list && typeof list.data !== 'undefined' && list.data && typeof list.data.values !== 'undefined' && list.data.values) {
        let newItems = {}
        list.data.values.forEach((item, index) => {
          newItems[item.id] = 0;
        });
        setItemValue(newItems);
      }
    };

    getItemsList()
  }, [page, limit])

  const onLookup = async (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      ma_tram: typeof immediatelyPayload.ma_tram !== 'undefined' ? immediatelyPayload.ma_tram : values.ma_tram,
      trang_thai: typeof immediatelyPayload.trang_thai !== 'undefined' ? immediatelyPayload.trang_thai : values.trang_thai,
      gia_gui: typeof immediatelyPayload.gia_gui !== 'undefined' ? immediatelyPayload.gia_gui : values.gia_gui,
      nha_mang: typeof immediatelyPayload.nha_mang !== 'undefined' ? immediatelyPayload.nha_mang : values.nha_mang,
      startDate: typeof immediatelyPayload.startDate !== 'undefined' ? immediatelyPayload.startDate.format("DD/MM/YYYY") : values.startDate.format("DD/MM/YYYY"),
      endDate: typeof immediatelyPayload.endDate !== 'undefined' ? immediatelyPayload.endDate.format("DD/MM/YYYY") :values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(ThenapAction.updateFilter(payload));
    dispatch(ThenapAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const exportExcel = async (ma_don = null) => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        ma_tram: values.ma_tram,
        trang_thai: values.trang_thai,
        gia_gui: values.gia_gui,
        nha_mang: values.nha_mang,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(ThenapAction.updateFilter(payload));
      const response = await dispatch(ThenapAction.list());

      const data = [
        [
          'Nhà mạng', 
          'Mã thẻ',
          'Số seri', 
          'Mệnh giá gửi',
          'Mệnh giá thực',
          'Mệnh giá ĐS', 
          'CK', 
          'Tài khoản', 
          'Thời gian tạo', 
          'Mã đối chiếu',
          'Trạng thái',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let trangThai = '';

          if (element.trang_thai == 'cho_xu_ly') trangThai = 'Chờ xử lý';
          else if (element.trang_thai == 'dang_xu_ly') trangThai = 'Đang xử lý';
          else if (element.trang_thai == 'dang_xu_ly_loi') trangThai = 'Đang xử lý (lỗi)';
          else if (element.trang_thai == 'the_dung_menh_gia') trangThai = 'Thẻ đúng mệnh giá';
          else if (element.trang_thai == 'the_sai_menh_gia') trangThai = 'Thẻ sai mệnh giá';
          else if (element.trang_thai == 'the_loi_huy') trangThai = 'Thẻ lỗi / hủy';

          data.push([
            element.nha_mang,
            element.ma_the,
            element.seri,
            element.gia_gui,
            element.gia_thuc,
            element.gia_doi_soat,
            element.chiet_khau + '%',
            element.tai_khoan,
            element.createdAt,
            element.ma_doi_chieu,
            trangThai,
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'The-nap.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({[event.target.name]: event.target.value});
  };

  const handleSelectAll = (event) => {
    let newSelectedThenapIds;

    if (event.target.checked) {
      newSelectedThenapIds = items.map((thenap) => thenap.id);
    } else {
      newSelectedThenapIds = [];
    }

    setSelectedThenapIds(newSelectedThenapIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedThenapIds.indexOf(id);
    let newSelectedThenapIds = [];

    if (selectedIndex === -1) {
      newSelectedThenapIds = newSelectedThenapIds.concat(selectedThenapIds, id);
    } else if (selectedIndex === 0) {
      newSelectedThenapIds = newSelectedThenapIds.concat(selectedThenapIds.slice(1));
    } else if (selectedIndex === selectedThenapIds.length - 1) {
      newSelectedThenapIds = newSelectedThenapIds.concat(selectedThenapIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedThenapIds = newSelectedThenapIds.concat(
        selectedThenapIds.slice(0, selectedIndex),
        selectedThenapIds.slice(selectedIndex + 1)
      );
    }

    setSelectedThenapIds(newSelectedThenapIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, thenap) => {
    setSelected(thenap);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(ThenapAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleClickOpen = () => {
    setIsUpdate(false)
    setOpen(true);
  };

  const handleEditOpen = (id) => {
    dispatch(ThenapAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const batTatLoaiDichVu = (event, id, yeu_cau) => {
    console.log(id, yeu_cau);
    dispatch(ThenapAction.batTatLoaiDichVu(id, {yeu_cau}));
  }

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });

    onLookup({startDate: date});
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });

    onLookup({endDate: date});
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        //showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const yeuCauChayLaiCallback = (id) => {
    dispatch(ThenapAction.yeuCauChayLaiCallback(id));
  }

  const refreshRecord = () => {
    
  };

  const mauTrangThai = (trang_thai) => {
    if (trang_thai == 'cho_xu_ly') return 'black';
    else if (trang_thai == 'dang_xu_ly') return 'blue';
    else if (trang_thai == 'dang_xu_ly_loi') return 'red';
    else if (trang_thai == 'the_dung_menh_gia') return 'green';
    else if (trang_thai == 'the_sai_menh_gia') return 'orange';
    else if (trang_thai == 'the_loi_huy') return 'red';
  }

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PerfectScrollbar>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 1500,
            }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="ma_tram"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.ma_tram}
                placeholder="Mã thẻ, số seri..."
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
              />
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="nha_mang"
                value={values.nha_mang || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Nhà mạng...</MenuItem>
                <MenuItem value="MOBI">MOBI</MenuItem>
                <MenuItem value="VINA">VINA</MenuItem>
                <MenuItem value="VIETTEL">VIETTEL</MenuItem>
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="gia_gui"
                value={values.gia_gui || 0}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="0">Mệnh giá...</MenuItem>
                <MenuItem value="10000">10.000</MenuItem>
                <MenuItem value="20000">20.000</MenuItem>
                <MenuItem value="30000">30.000</MenuItem>
                <MenuItem value="50000">50.000</MenuItem>
                <MenuItem value="100000">100.000</MenuItem>
                <MenuItem value="200000">200.000</MenuItem>
                <MenuItem value="300000">300.000</MenuItem>
                <MenuItem value="500000">500.000</MenuItem>
                <MenuItem value="1000000">1.000.000</MenuItem>
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="trang_thai"
                value={values.trang_thai || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Trạng thái...</MenuItem>
                <MenuItem value="cho_xu_ly">Chờ xử lý</MenuItem>
                <MenuItem value="dang_xu_ly">Đang xử lý</MenuItem>
                <MenuItem value="dang_xu_ly_loi">Đang xử lý (lỗi)</MenuItem>
                <MenuItem value="the_dung_menh_gia">Thẻ đúng mệnh giá</MenuItem>
                <MenuItem value="the_sai_menh_gia">Thẻ sai mệnh giá</MenuItem>
                <MenuItem value="the_loi_huy">Thẻ lỗi / hủy</MenuItem>
              </Select1>
              <DatePicker
                label="Thời gian bắt đầu"
                style={{ width: '200px' }}
                format='DD/MM/YYYY'
                value={values.startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
                />
              <DatePicker
                label="Thời gian kết thúc"
                style={{ width: '200px' }}
                value={values.endDate}
                format='DD/MM/YYYY'
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
            </Box>
          </PerfectScrollbar>
        </LocalizationProvider>
      </CardContent>
    </Card>
    <Card {...rest} style={{ width: "100%"}}>
      <div style={{width: '300px', margin: 'auto'}}>
        {role.priority < 3 && <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "80px", minWidth: '20px', marginLeft: '0', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Nạp thẻ
        </Button>}
        {/* <Button
          color="primary"
          variant="contained"
          onClick={handleEdit}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Sửa
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleDelete}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Xóa
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={() => exportExcel()}
          style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
          <Download style={{ color: "#3498db" }} />
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onLookup}
          style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
          {!isLoading ? <Cached style={{ color: "#3498db" }} /> : ''}
        </Button>
      </div>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table style={{marginTop: 15}}>
            <TableHead>
              <TableRow style={{background: 'red', height: '20px'}}>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  #
                </TableCell>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  <Checkbox
                      checked={itemCheckAll == 1 ? true : false}
                      onChange={(event) => setItemCheckAllOnChange(event)}
                      value={itemCheckAll == 1 ? 1 : 0}
                      style={{width: '20px', color: 'white'}}
                  />
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Nhà mạng
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mã thẻ
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Số seri
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mệnh giá gửi
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mệnh giá thực
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mệnh giá ĐS
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  CK
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tài khoản
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thời gian tạo
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mã đối chiếu
                </TableCell>
                <TableCell colSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Trạng thái
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((thenap, index) => (
                <TableRow
                  hover
                  key={thenap.id}
                  selected={selectedThenapIds.indexOf(thenap.id) !== -1}
                >
                  <TableCell style={{textAlign: 'center'}}>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Checkbox
                      checked={item[thenap.id] == 1 ? true : false}
                      onChange={(event) => setItem(event, thenap.id)}
                      value={item[thenap.id] == 1 ? 1 : 0}
                    />
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thenap.nha_mang}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0, color: 'blue'}} onClick={() => copyToClipboard(thenap.ma_the)}>
                        {thenap.ma_the.substring(0, 3) + '***' + thenap.ma_the.substring(thenap.ma_the.length - 3)}
                      </Button>
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thenap.seri}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(thenap.gia_gui).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(thenap.gia_thuc).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(thenap.gia_doi_soat).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thenap.chiet_khau}%
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thenap.tai_khoan}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thenap.createdAt}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thenap.ma_doi_chieu}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color={mauTrangThai(thenap.trang_thai)}
                      variant="body1"
                    >
                      {thenap.trang_thai == 'cho_xu_ly' && 'Chờ xử lý'}
                      {thenap.trang_thai == 'dang_xu_ly' && 'Đang xử lý'}
                      {thenap.trang_thai == 'dang_xu_ly_loi' && 'Đang xử lý (lỗi)'}
                      {thenap.trang_thai == 'the_dung_menh_gia' && 'Thẻ đúng'}
                      {thenap.trang_thai == 'the_sai_menh_gia' && 'Sai mệnh giá'}
                      {thenap.trang_thai == 'the_loi_huy' && 'Thẻ lỗi / hủy'}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => yeuCauChayLaiCallback(thenap.id)}
                      style={{ color: thenap.trang_thai_callback ? 'green' : 'red', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'bold', fontSize: 15 }}
                      >
                      CB
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <PerfectScrollbar>
        <div style={{margin: 15, minWidth: 1050}}>
          <span style={{marginRight: 15}}>Tổng thẻ: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_the).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Đúng MG: <span style={{color: 'green', fontWeight: 'bold'}}>{numeral(tong_dung_menh_gia).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Sai MG: <span style={{color: 'red', fontWeight: 'bold'}}>{numeral(tong_sai_menh_gia).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Chưa nạp: <span style={{color: 'black', fontWeight: 'bold'}}>{numeral(tong_chua_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Lỗi: <span style={{color: 'red', fontWeight: 'bold'}}>{numeral(tong_loi_huy).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng nạp: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Đối soát: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_doi_soat).format('0,0')}</span></span>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={isUpdate ? "EDIT" : "CREATE"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card></>
  );
};

export default ThenapLookupDetail;
