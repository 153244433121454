import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { RequestrefundAction } from 'src/actions/requestrefund.action';
import { SettingAction } from 'src/actions/setting.action';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    TableCell,
} from '@material-ui/core';

import Select from 'react-select'

import { Search as SearchIcon } from 'react-feather';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

import { NOTI_TYPES } from 'src/utils/constants';
import { MobidanhsachdonAction } from 'src/actions/mobidanhsachdon.action';

const CreateEditDialogTaoDon = (props) => {
    const dispatch = useDispatch();
    const { leaders, brands, info, role } = useSelector(state => state.profile)
    const [dangTaoDonLe, setDangTaoDonLe] = useState(1)
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [chietKhauCuaBan, setChietKhauCuaBan] = useState({
        chiet_khau_smart_vi: "0",
        chiet_khau_tkc: "0",
        chiet_khau_dichvu: "0",
        chiet_khau_bunca: "0",
        chiet_khau_napho: "0",
        chiet_khau_mymobifone: "0",
        chiet_khau_gsmmobifone: "0",

        chiet_khau_smart_vi_vina: "0",
        chiet_khau_tkc_vina: "0",
        chiet_khau_dichvu_vina: "0",
        chiet_khau_bunca_vina: "0",
        chiet_khau_napho_vina: "0",
        chiet_khau_mymobifone_vina: "0",
        chiet_khau_gsmmobifone_vina: "0",

        chiet_khau_smart_vi_viettel: "0",
        chiet_khau_tkc_viettel: "0",
        chiet_khau_dichvu_viettel: "0",
        chiet_khau_bunca_viettel: "0",
        chiet_khau_napho_viettel: "0",
        chiet_khau_mymobifone_viettel: "0",
        chiet_khau_gsmmobifone_viettel: "0",
    });
    const [moduleBanTien, batTatModuleBanTienValue] = useState({});

    useEffect(() => {
        async function fetchSettingModuleBanTien() {
            const moduleBanTien_res = await dispatch(SettingAction.findByKey('bat_tat_module_ban_tien'))
            let moduleBanTien_value = JSON.parse(moduleBanTien_res.data.value);
            moduleBanTien_value = {
                topup: moduleBanTien_value.topup == '1' ? 1 : 2,
                tkc: moduleBanTien_value.tkc == '1' ? 1 : 2, 
                dv: moduleBanTien_value.dv == '1' ? 1 : 2,
                bc: moduleBanTien_value.bc == '1' ? 1 : 2,
                napho: moduleBanTien_value.napho == '1' ? 1 : 2,
                mymobifone: moduleBanTien_value.mymobifone == '1' ? 1 : 2,
                gsmmobifone: moduleBanTien_value.gsmmobifone == '1' ? 1 : 2,

                topup_vina: moduleBanTien_value.topup_vina == '1' ? 1 : 2,
                tkc_vina: moduleBanTien_value.tkc_vina == '1' ? 1 : 2, 
                dv_vina: moduleBanTien_value.dv_vina == '1' ? 1 : 2,
                bc_vina: moduleBanTien_value.bc_vina == '1' ? 1 : 2,

                topup_viettel: moduleBanTien_value.topup_viettel == '1' ? 1 : 2,
                tkc_viettel: moduleBanTien_value.tkc_viettel == '1' ? 1 : 2, 
                dv_viettel: moduleBanTien_value.dv_viettel == '1' ? 1 : 2,
                bc_viettel: moduleBanTien_value.bc_viettel == '1' ? 1 : 2,
            }
            batTatModuleBanTienValue(moduleBanTien_value)
        }
        fetchSettingModuleBanTien();
    }, [])

    useEffect(() => {
        async function fetchData() {
            if (info && info.cai_dat_chiet_khau) {
                try {
                    const jsonObject = JSON.parse(info.cai_dat_chiet_khau);
                    setChietKhauCuaBan({
                        chiet_khau_smart_vi: typeof jsonObject.chiet_khau_smart_vi !== 'undefined' ? jsonObject.chiet_khau_smart_vi : '0',
                        chiet_khau_tkc: typeof jsonObject.chiet_khau_tkc !== 'undefined' ? jsonObject.chiet_khau_tkc : '0',
                        chiet_khau_dichvu: typeof jsonObject.chiet_khau_dichvu !== 'undefined' ? jsonObject.chiet_khau_dichvu : '0',
                        chiet_khau_bunca: typeof jsonObject.chiet_khau_bunca !== 'undefined' ? jsonObject.chiet_khau_bunca : '0',
                        chiet_khau_napho: typeof jsonObject.chiet_khau_napho !== 'undefined' ? jsonObject.chiet_khau_napho : '0',
                        chiet_khau_mymobifone: typeof jsonObject.chiet_khau_mymobifone !== 'undefined' ? jsonObject.chiet_khau_mymobifone : '0',
                        chiet_khau_gsmmobifone: typeof jsonObject.chiet_khau_gsmmobifone !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone : '0',

                        chiet_khau_smart_vi_vina: typeof jsonObject.chiet_khau_smart_vi_vina !== 'undefined' ? jsonObject.chiet_khau_smart_vi_vina : '0',
                        chiet_khau_tkc_vina: typeof jsonObject.chiet_khau_tkc_vina !== 'undefined' ? jsonObject.chiet_khau_tkc_vina : '0',
                        chiet_khau_dichvu_vina: typeof jsonObject.chiet_khau_dichvu_vina !== 'undefined' ? jsonObject.chiet_khau_dichvu_vina : '0',
                        chiet_khau_bunca_vina: typeof jsonObject.chiet_khau_bunca_vina !== 'undefined' ? jsonObject.chiet_khau_bunca_vina : '0',
                        chiet_khau_napho_vina: typeof jsonObject.chiet_khau_napho_vina !== 'undefined' ? jsonObject.chiet_khau_napho_vina : '0',
                        chiet_khau_mymobifone_vina: typeof jsonObject.chiet_khau_mymobifone_vina !== 'undefined' ? jsonObject.chiet_khau_mymobifone_vina : '0',
                        chiet_khau_gsmmobifone_vina: typeof jsonObject.chiet_khau_gsmmobifone_vina !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone_vina : '0',

                        chiet_khau_smart_vi_viettel: typeof jsonObject.chiet_khau_smart_vi_viettel !== 'undefined' ? jsonObject.chiet_khau_smart_vi_viettel : '0',
                        chiet_khau_tkc_viettel: typeof jsonObject.chiet_khau_tkc_viettel !== 'undefined' ? jsonObject.chiet_khau_tkc_viettel : '0',
                        chiet_khau_dichvu_viettel: typeof jsonObject.chiet_khau_dichvu_viettel !== 'undefined' ? jsonObject.chiet_khau_dichvu_viettel : '0',
                        chiet_khau_bunca_viettel: typeof jsonObject.chiet_khau_bunca_viettel !== 'undefined' ? jsonObject.chiet_khau_bunca_viettel : '0',
                        chiet_khau_napho_viettel: typeof jsonObject.chiet_khau_napho_viettel !== 'undefined' ? jsonObject.chiet_khau_napho_viettel : '0',
                        chiet_khau_mymobifone_viettel: typeof jsonObject.chiet_khau_mymobifone_viettel !== 'undefined' ? jsonObject.chiet_khau_mymobifone_viettel : '0',
                        chiet_khau_gsmmobifone_viettel: typeof jsonObject.chiet_khau_gsmmobifone_viettel !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone_viettel : '0',
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
        fetchData();
    }, [])
    
    const [napLeValues, setNapLeValues] = useState({
        number_type: '-99',
        operator: '-99',
        ten_kh: '',
        nha_mang: '-99',
        boi_so: ['-99'],
        thue_bao: '',
        so_tien: '0',
    });

    const [napDonValues, setNapDonValues] = useState({
        number_type: '-99',
        operator: '-99',
        ten_don: '',
        nha_mang: '-99',
        boi_so: ['-99'],
        chi_tiet_don: '',
        outputChiTietDon: [],
        error: false,
    });

    const resetNapLeValues = () => {
        setNapLeValues({
            number_type: '-99',
            operator: '-99',
            ten_kh: '',
            nha_mang: '-99',
            boi_so: ['-99'],
            thue_bao: '',
            so_tien: '0',
        })
    }

    const resetNapDonValues = () => {
        setNapDonValues({
            number_type: '-99',
            operator: '-99',
            ten_don: '',
            nha_mang: '-99',
            boi_so: ['-99'],
            chi_tiet_don: '',
            outputChiTietDon: [],
            error: false,
        })
    }

    const handleChangeNapLe = (event) => {
        if (event.target.name == 'so_tien') event.target.value = numeral(event.target.value).format('0,0')
        if (event.target.name == 'number_type' && event.target.value != '1' && napLeValues.nha_mang == 'tkc') {
            setNapLeValues({
                ...napLeValues,
                [event.target.name]: event.target.value,
                'number_type': '1',
            });
        } else if (event.target.name == 'nha_mang' && event.target.value == 'tkc') {
            setNapLeValues({
                ...napLeValues,
                [event.target.name]: event.target.value,
                'number_type': '1',
            });
        } else if (event.target.name == 'nha_mang' && event.target.value == 'napho') {
            setNapLeValues({
                ...napLeValues,
                [event.target.name]: event.target.value,
                'number_type': '1',
            });
        } else if (event.target.name == 'boi_so') {
            setIsOpen(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setNapLeValues({
                ...napLeValues,
                [event.target.name]: targetValues,
            });
        } else {
            setNapLeValues({
                ...napLeValues,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeNapDon = (event) => {
        if (event.target.name == 'number_type' && event.target.value != '1' && napDonValues.nha_mang == 'tkc') {
            setNapDonValues({
                ...napDonValues,
                [event.target.name]: event.target.value,
                'number_type': '1',
            });
        } else if (event.target.name == 'nha_mang' && event.target.value == 'tkc') {
            setNapDonValues({
                ...napDonValues,
                [event.target.name]: event.target.value,
                'number_type': '1',
            });
        } else if (event.target.name == 'nha_mang' && event.target.value == 'napho') {
            setNapDonValues({
                ...napDonValues,
                [event.target.name]: event.target.value,
                'number_type': '1',
            });
        } else if (event.target.name == 'boi_so') {
            setIsOpen1(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setNapDonValues({
                ...napDonValues,
                [event.target.name]: targetValues,
            });
        } else {
            setNapDonValues({
                ...napDonValues,
                [event.target.name]: event.target.value,
            });
        }
    };

    const onLookupNapLe = async () => {
        let payload = napLeValues

        resetNapLeValues();

        payload.thue_bao = payload.thue_bao.trim().replace(/\D/g, '').slice(-9);
        payload.so_tien = payload.so_tien.trim().replace(/,/g, '');

        if (payload.number_type == '-99') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn loại thuê bao')
            return;
        }

        if (payload.operator == '-99') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn nhà mạng')
            return;
        }

        if (payload.ten_kh == '') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải nhập tên KH')
            return;
        }

        if (payload.nha_mang == '-99') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn nhà mạng nạp')
            return;
        }

        // if (payload.nha_mang != 'tkc' && payload.boi_so == '-99') {
        //   showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn bội số')
        //   return;
        // }

        if ((payload.nha_mang == 'mymobifone' || payload.nha_mang == 'napho') && payload.boi_so.includes('-99')) {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn bội số')
            return;
        }

        if (payload.thue_bao == '') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải nhập thuê bao')
            return;
        }

        if (parseInt(payload.so_tien) == 0) {
            showToast(NOTI_TYPES.ERROR, 'Số tiền phải > 0')
            return;
        }

        if (payload.nha_mang != 'tkc' && parseInt(payload.so_tien) % 10000 != 0) {
            showToast(NOTI_TYPES.ERROR, 'Số tiền phải là bội số của 10.000')
            return;
        }

        if (role.priority > 2 && payload.nha_mang == 'tkc' && parseInt(payload.so_tien) < 10000) {
            showToast(NOTI_TYPES.ERROR, 'Yêu cầu số tiền nạp phải >= 10.000')
            return;
        }

        if (payload.nha_mang == 'smart_vi' && parseInt(payload.so_tien) < 20000) {
            showToast(NOTI_TYPES.ERROR, 'Yêu cầu số tiền nạp phải >= 20.000')
            return;
        }

        console.log(payload)

        try {
            const result = await dispatch(MobidanhsachdonAction.create({ number_type: payload.number_type, operator: payload.operator, loai_don: 'nap_le', ten_kh: payload.ten_kh, nha_mang: payload.nha_mang, boi_so: payload.boi_so, thue_bao: payload.thue_bao, so_tien: payload.so_tien }));
            if (result && result.result == 'success') {
                showToast(NOTI_TYPES.SUCCESS, 'Tạo đơn thành công!');
                props.handleClose();
            } else {
                let errorMes = `Tạo đơn thất bại!`;
                if (typeof result.msg !== 'undefined') errorMes = result.msg;
                showToast(NOTI_TYPES.ERROR, errorMes);
            }
        } catch (error) {
            let errorMes = `Tạo đơn thất bại!`;
            if (typeof error.msg !== 'undefined') errorMes = error.msg;
            showToast(NOTI_TYPES.ERROR, errorMes);
        }
    }
    
    const onLookupNapDon = async () => {
        let payload = napDonValues

        resetNapDonValues();

        if (payload.number_type == '-99') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn loại thuê bao')
            return;
        }

        if (payload.operator == '-99') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn nhà mạng')
            return;
        }

        if (payload.ten_don == '') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải nhập tên đơn')
            return;
        }

        if (payload.nha_mang == '-99') {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn nhà mạng nạp')
            return;
        }

        // if (payload.nha_mang != 'tkc' && payload.boi_so == '-99') {
        //   showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn bội số')
        //   return;
        // }

        if ((payload.nha_mang == 'mymobifone' || payload.nha_mang == 'napho') && payload.boi_so.includes('-99')) {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn bội số')
            return;
        }
        
        if (!payload.error) {
            if (payload.outputChiTietDon.length == 0) {
            showToast(NOTI_TYPES.ERROR, 'Bạn phải nhập chi tiết đơn')
            return;
            }

            console.log(payload)

            try {
                const result = await dispatch(MobidanhsachdonAction.create({ number_type: payload.number_type, operator: payload.operator, loai_don: 'nap_don', ten_don: payload.ten_don, nha_mang: payload.nha_mang, boi_so: payload.boi_so, chi_tiet_don: payload.outputChiTietDon }));
                
                // if (result && result.result == 'success') showToast(NOTI_TYPES.SUCCESS, 'Tạo đơn thành công!');
                // else {
                //   let errorMes = `Tạo đơn thất bại!`;
                //   if (typeof result.msg !== 'undefined') errorMes = result.msg;
                //   showToast(NOTI_TYPES.ERROR, errorMes);
                // }

                if (result && result.result == 'failed') {
                    let errorMes = `Tạo đơn thất bại!`;
                    if (typeof result.msg !== 'undefined') errorMes = result.msg;
                    showToast(NOTI_TYPES.ERROR, errorMes);
                    return;
                }
                
                if (result && result.ket_qua_nap_nhieu_so && result.ket_qua_nap_nhieu_so.length > 0) {
                    let res = ''

                    for (let i = 0; i < result.ket_qua_nap_nhieu_so.length; i++) {
                        res += `- ${result.ket_qua_nap_nhieu_so[i].thue_bao}: ${result.ket_qua_nap_nhieu_so[i].ket_qua.result == 'success' ? 'THÀNH CÔNG' : 'THẤT BẠI'} - ${result.ket_qua_nap_nhieu_so[i].ket_qua.msg} (${result.ket_qua_nap_nhieu_so[i].ket_qua.code})\n`
                    }

                    //alert(res);
                    props.handleClose();
                }
            } catch (error) {
                let errorMes = `Tạo đơn thất bại!`;
                if (typeof error.msg !== 'undefined') errorMes = error.msg;
                showToast(NOTI_TYPES.ERROR, errorMes);
            }
        }
    }

    const handleBlur = (event) => {
        let outputChiTietDon = [];

        if (event.target.name == 'chi_tiet_don') {
            let outputCtd = '';
            let ctd = event.target.value
            ctd = ctd.split(/\n/g);
            if (ctd)
            {
                for (let i = 0; i < ctd.length; i++) {
                    let napTheoSo = ctd[i].split(/[\s,]+/);
                    if (napTheoSo && ctd[i].trim() != '') {
                        let thue_bao = napTheoSo[0].replace(/\D/g, '');
                        if (napTheoSo.length == 2) {
                            let so_tien = napTheoSo[napTheoSo.length - 1].replace(/\D/g, '');

                            if (napDonValues.nha_mang != 'tkc' && so_tien % 10000 != 0) {
                                setNapDonValues({
                                    ...napDonValues,
                                    error: true,
                                });
                                showToast(NOTI_TYPES.ERROR, `Dòng thứ ${i + 1} (${thue_bao}) số tiền không phải là bội số của 10.000`)
                                return;
                            }

                            if (role.priority > 2 && napDonValues.nha_mang == 'tkc' && so_tien < 10000) {
                                setNapDonValues({
                                    ...napDonValues,
                                    error: true,
                                });
                                showToast(NOTI_TYPES.ERROR, `Dòng thứ ${i + 1} (${thue_bao}) số tiền nạp phải >= 10.000`)
                                return;
                            }

                            if (napDonValues.nha_mang == 'smart_vi' && so_tien < 20000) {
                                setNapDonValues({
                                    ...napDonValues,
                                    error: true,
                                });
                                showToast(NOTI_TYPES.ERROR, `Dòng thứ ${i + 1} (${thue_bao}) số tiền nạp phải >= 20.000`)
                                return;
                            }

                            outputCtd += `${thue_bao},${so_tien}`;
                            if (i < ctd.length - 1) outputCtd += '\n'
                            outputChiTietDon.push({thue_bao: thue_bao.slice(-9), so_tien});
                        } else {
                            setNapDonValues({
                                ...napDonValues,
                                error: true,
                            });
                            showToast(NOTI_TYPES.ERROR, `Dòng thứ ${i + 1} (${thue_bao}) nhập sai cấu trúc, dấu phẩy hoặc khoảng trắng đặt sai vị trí`)
                            return;
                        }
                    }
                }
            }
            event.target.value = outputCtd;
        }
        setNapDonValues({
            ...napDonValues,
            [event.target.name]: event.target.value,
            outputChiTietDon,
            error: false,
        });
    };

    const onPressPositive = async () => {
        props.handleClose();
    }

    const handleClickChonBoiNapLe = async () => {
        setIsOpen(true);
    }

    const handleClickChonBoiNapDon = async () => {
        setIsOpen1(true);
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>
                <Button
                    color="primary"
                    variant="contained"
                    disabled=""
                    onClick={() => setDangTaoDonLe(1)}
                    style={{ height: '30px', width: '90px', marginTop: 0, marginBottom: 0, marginLeft: 0, fontWeight: 'normal', background: dangTaoDonLe == 1 ? 'blue' : 'red' }}
                    >
                    NẠP LẺ
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled=""
                    onClick={() => setDangTaoDonLe(0)}
                    style={{ height: '30px', width: '90px', marginTop: 0, marginBottom: 0, marginLeft: 5, fontWeight: 'normal', background: dangTaoDonLe != 1 ? 'blue' : 'red' }}
                    >
                    NẠP LÔ
                </Button>
            </DialogTitle>
            <DialogContent style={{ width: 600, maxWidth: '80vw', paddingTop: 20 }}>
                <Box
                    style={{marginBottom: '25px'}}
                >
                    <span style={{color: 'blue'}}>* Chiết khấu của bạn:</span><br></br>
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.topup == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- TOP-UP: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_smart_vi} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_smart_vi_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_smart_vi_viettel} %</span><br></br></span>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.napho == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp hộ: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_napho} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_napho_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_napho_viettel} %</span><br></br></span>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.mymobifone == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp My: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_mymobifone} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_mymobifone_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_mymobifone_viettel} %</span><br></br></span>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.gsmmobifone == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp GSM: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_gsmmobifone} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_gsmmobifone_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_gsmmobifone_viettel} %</span><br></br></span>}
                </Box>

                {/* TẠO ĐƠN LẺ */}
                <div style={{display: dangTaoDonLe == 1 ? 'block' : 'none'}}>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <TextField
                        fullWidth
                        label="Tên KH"
                        margin="normal"
                        name="ten_kh"
                        onChange={handleChangeNapLe}
                        required
                        value={napLeValues.ten_kh}
                        variant="outlined"
                        style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
                        />
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                        style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                        name="operator"
                        value={napLeValues.operator || -99}
                        onChange={handleChangeNapLe}
                        variant="outlined"
                        startAdornment={(
                            <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                            </SvgIcon>
                            </InputAdornment>
                        )}
                        >
                        <MenuItem value="-99">Nhà mạng...</MenuItem>
                        <MenuItem value="MOBIFONE">MOBIFONE</MenuItem>
                        <MenuItem value="VINAPHONE">VINAPHONE</MenuItem>
                        <MenuItem value="VIETTEL">VIETTEL</MenuItem>
                        </Select1>
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                        style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                        name="nha_mang"
                        value={napLeValues.nha_mang || -99}
                        onChange={handleChangeNapLe}
                        variant="outlined"
                        startAdornment={(
                            <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                            </SvgIcon>
                            </InputAdornment>
                        )}
                        >
                        <MenuItem value="-99">Kênh nạp...</MenuItem>
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.topup == 1 && (moduleBanTien.topup == 1 && napLeValues.operator == 'MOBIFONE' || moduleBanTien.topup_vina == 1 && napLeValues.operator == 'VINAPHONE' || moduleBanTien.topup_viettel == 1 && napLeValues.operator == 'VIETTEL')) && <MenuItem value="smart_vi">TOP-UP</MenuItem>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.napho == 1 && (moduleBanTien.napho == 1 && napLeValues.operator == 'MOBIFONE')) && <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.mymobifone == 1 && (moduleBanTien.mymobifone == 1 && napLeValues.operator == 'MOBIFONE')) && <MenuItem value="mymobifone">My Mobifone</MenuItem>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.gsmmobifone == 1 && (moduleBanTien.gsmmobifone == 1 && napLeValues.operator == 'MOBIFONE')) && <MenuItem value="gsmmobifone">GSM Mobifone</MenuItem>}
                        {/* <MenuItem value="mobifone_gsm">Mobifone GSM</MenuItem>
                        <MenuItem value="mobifone_otp">Mobifone (Nhập OTP)</MenuItem> */}
                        </Select1>
                    </Box>
                    <Box
                        sx={{
                        display: napLeValues.nha_mang != 'mymobifone' && napLeValues.nha_mang != 'napho' ? 'none' : 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                            style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                            name="boi_so"
                            value={napLeValues.boi_so || ['-99']}
                            onChange={handleChangeNapLe}
                            onOpen={handleClickChonBoiNapLe}
                            variant="outlined"
                            placeholder='Chọn bội'
                            open={isOpen}
                            multiple
                        >
                            <MenuItem value="-99">Chọn bội...</MenuItem>
                            {/* <MenuItem value="10" style={{backgroundColor: napLeValues.boi_so.includes('10') ? 'yellow' : 'inherit'}}>10</MenuItem>
                            <MenuItem value="20" style={{backgroundColor: napLeValues.boi_so.includes('20') ? 'yellow' : 'inherit'}}>20</MenuItem>
                            <MenuItem value="30" style={{backgroundColor: napLeValues.boi_so.includes('30') ? 'yellow' : 'inherit'}}>30</MenuItem> */}
                            <MenuItem value="50" style={{backgroundColor: napLeValues.boi_so.includes('50') ? 'yellow' : 'inherit'}}>50</MenuItem>
                            <MenuItem value="100" style={{backgroundColor: napLeValues.boi_so.includes('100') ? 'yellow' : 'inherit'}}>100</MenuItem>
                            <MenuItem value="200" style={{backgroundColor: napLeValues.boi_so.includes('200') ? 'yellow' : 'inherit'}}>200</MenuItem>
                            <MenuItem value="300" style={{backgroundColor: napLeValues.boi_so.includes('300') ? 'yellow' : 'inherit'}}>300</MenuItem>
                            <MenuItem value="500" style={{backgroundColor: napLeValues.boi_so.includes('500') ? 'yellow' : 'inherit'}}>500</MenuItem>
                            <MenuItem value="1000" style={{backgroundColor: napLeValues.boi_so.includes('1000') ? 'yellow' : 'inherit'}}>1.000</MenuItem>
                        </Select1>
                    </Box>
                    {napLeValues.nha_mang != 'tkc' && napLeValues.nha_mang != 'napho' && <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                        style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                        name="number_type"
                        value={napLeValues.number_type || -99}
                        onChange={handleChangeNapLe}
                        variant="outlined"
                        startAdornment={(
                            <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                            </SvgIcon>
                            </InputAdornment>
                        )}
                        >
                        <MenuItem value="-99">Loại thuê bao...</MenuItem>
                        <MenuItem value="1">TRẢ TRƯỚC</MenuItem>
                        <MenuItem value="2">TRẢ SAU</MenuItem>
                        </Select1>
                    </Box>}
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <TextField
                        fullWidth
                        label="Thuê bao"
                        margin="normal"
                        name="thue_bao"
                        onChange={handleChangeNapLe}
                        required
                        value={napLeValues.thue_bao}
                        variant="outlined"
                        style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
                        />
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <TextField
                        fullWidth
                        label="Số tiền"
                        margin="normal"
                        name="so_tien"
                        onChange={handleChangeNapLe}
                        required
                        value={napLeValues.so_tien}
                        variant="outlined"
                        style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
                        />
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Button
                        color="primary"
                        variant="contained"
                        disabled=""
                        onClick={onLookupNapLe}
                        style={{ height: 56, marginTop: 6, width: '100%', marginLeft: 0 }}
                        >
                        NẠP
                        </Button>
                    </Box>
                </div>

                {/* TẠO ĐƠN LÔ */}
                <div style={{display: dangTaoDonLe == 0 ? 'block' : 'none'}}>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <TextField
                        fullWidth
                        label="Tên đơn"
                        margin="normal"
                        name="ten_don"
                        onChange={handleChangeNapDon}
                        required
                        value={napDonValues.ten_don}
                        variant="outlined"
                        style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
                        />
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                        style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                        name="operator"
                        value={napDonValues.operator || -99}
                        onChange={handleChangeNapDon}
                        variant="outlined"
                        startAdornment={(
                            <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                            </SvgIcon>
                            </InputAdornment>
                        )}
                        >
                        <MenuItem value="-99">Nhà mạng...</MenuItem>
                        <MenuItem value="MOBIFONE">MOBIFONE</MenuItem>
                        <MenuItem value="VINAPHONE">VINAPHONE</MenuItem>
                        <MenuItem value="VIETTEL">VIETTEL</MenuItem>
                        </Select1>
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                        style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                        name="nha_mang"
                        value={napDonValues.nha_mang || -99}
                        onChange={handleChangeNapDon}
                        variant="outlined"
                        startAdornment={(
                            <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                            </SvgIcon>
                            </InputAdornment>
                        )}
                        >
                        <MenuItem value="-99">Kênh nạp...</MenuItem>
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.topup == 1 && (moduleBanTien.topup == 1 && napDonValues.operator == 'MOBIFONE' || moduleBanTien.topup_vina == 1 && napDonValues.operator == 'VINAPHONE' || moduleBanTien.topup_viettel == 1 && napDonValues.operator == 'VIETTEL')) && <MenuItem value="smart_vi">TOP-UP</MenuItem>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.napho == 1 && (moduleBanTien.napho == 1 && napDonValues.operator == 'MOBIFONE')) && <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.mymobifone == 1 && (moduleBanTien.mymobifone == 1 && napDonValues.operator == 'MOBIFONE')) && <MenuItem value="mymobifone">My Mobifone</MenuItem>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.gsmmobifone == 1 && (moduleBanTien.gsmmobifone == 1 && napDonValues.operator == 'MOBIFONE')) && <MenuItem value="gsmmobifone">GSM Mobifone</MenuItem>}
                        {/* <MenuItem value="mobifone_gsm">Mobifone GSM</MenuItem>
                        <MenuItem value="mobifone_otp">Mobifone (Nhập OTP)</MenuItem> */}
                        </Select1>
                    </Box>
                    <Box
                        sx={{
                        display: napDonValues.nha_mang != 'mymobifone' && napDonValues.nha_mang != 'napho' ? 'none' : 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                            style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                            name="boi_so"
                            value={napDonValues.boi_so || ['-99']}
                            onChange={handleChangeNapDon}
                            onOpen={handleClickChonBoiNapDon}
                            variant="outlined"
                            placeholder='Chọn bội'
                            open={isOpen1}
                            multiple
                        >
                            <MenuItem value="-99">Chọn bội...</MenuItem>
                            {/* <MenuItem value="10" style={{backgroundColor: napDonValues.boi_so.includes('10') ? 'yellow' : 'inherit'}}>10</MenuItem>
                            <MenuItem value="20" style={{backgroundColor: napDonValues.boi_so.includes('20') ? 'yellow' : 'inherit'}}>20</MenuItem>
                            <MenuItem value="30" style={{backgroundColor: napDonValues.boi_so.includes('30') ? 'yellow' : 'inherit'}}>30</MenuItem> */}
                            <MenuItem value="50" style={{backgroundColor: napDonValues.boi_so.includes('50') ? 'yellow' : 'inherit'}}>50</MenuItem>
                            <MenuItem value="100" style={{backgroundColor: napDonValues.boi_so.includes('100') ? 'yellow' : 'inherit'}}>100</MenuItem>
                            <MenuItem value="200" style={{backgroundColor: napDonValues.boi_so.includes('200') ? 'yellow' : 'inherit'}}>200</MenuItem>
                            <MenuItem value="300" style={{backgroundColor: napDonValues.boi_so.includes('300') ? 'yellow' : 'inherit'}}>300</MenuItem>
                            <MenuItem value="500" style={{backgroundColor: napDonValues.boi_so.includes('500') ? 'yellow' : 'inherit'}}>500</MenuItem>
                            <MenuItem value="1000" style={{backgroundColor: napDonValues.boi_so.includes('1000') ? 'yellow' : 'inherit'}}>1.000</MenuItem>
                        </Select1>
                    </Box>
                    {napDonValues.nha_mang != 'tkc' && napDonValues.nha_mang != 'napho' && <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Select1
                        style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                        name="number_type"
                        value={napDonValues.number_type || -99}
                        onChange={handleChangeNapDon}
                        variant="outlined"
                        startAdornment={(
                            <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                            </SvgIcon>
                            </InputAdornment>
                        )}
                        >
                        <MenuItem value="-99">Loại thuê bao...</MenuItem>
                        <MenuItem value="1">TRẢ TRƯỚC</MenuItem>
                        <MenuItem value="2">TRẢ SAU</MenuItem>
                        </Select1>
                    </Box>}
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <div style={{ marginTop: 6, width: "100%", marginLeft: 0 }}>
                        <textarea
                            rows="10"
                            onChange={handleChangeNapDon}
                            onBlur={handleBlur}
                            placeholder="901111111,100000"
                            value={napDonValues.chi_tiet_don}
                            name='chi_tiet_don'
                            style={{fontSize: 18, padding: 6, width: '100%'}}
                        ></textarea>
                        </div>
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        }}
                    >
                        <Button
                        color="primary"
                        variant="contained"
                        disabled=""
                        onClick={onLookupNapDon}
                        style={{ height: 56, marginTop: 6, width: '100%', marginLeft: 0 }}
                        >
                        NẠP
                        </Button>
                    </Box>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                {/* <Button onClick={onPressPositive}>OK</Button> */}
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialogTaoDon;