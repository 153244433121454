import { Helmet } from 'react-helmet';
import NapMyMobifoneChiTietDetail
  from 'src/components/NapMyMobifoneChiTiet-detail/NapMyMobifoneChiTietDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

//import NapMyMobifoneChiTietToolbar from '../components/NapMyMobifoneChiTiet-detail/NapMyMobifoneChiTietToolbar';

const NapMyMobifoneChiTiet = () => (
  <>
    <Helmet>
      <title>Nạp cước - Danh sách đơn chi tiết</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        {/* <NapMyMobifoneChiTietToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <NapMyMobifoneChiTietDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default NapMyMobifoneChiTiet;
