import {
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_BRANCH_NO_LIMIT_LIST_SUCCESS,
  ACCOUNT_LEADER_NO_LIMIT_LIST_SUCCESS,
  ACCOUNT_SALE_NO_LIMIT_LIST_SUCCESS,
  ACCOUNT_SELECTED_FAILD,
  ACCOUNT_SELECTED_SUCCESS,
  ACCOUNT_UPDATE_FILTER,
} from 'src/actions/account.acction';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import dayjs from 'dayjs';

const today = new Date();

const initState = {
  filter: {
    message: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  items: [],
  total: 0,
  selected: undefined
}

const accountReducer = (state = initState, action) => {
  switch (action.type) {
    case ACCOUNT_UPDATE_FILTER:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filter !== 'undefined' && state.filter) {
        if (action.data.page == 'no_update' && typeof state.filter.page !== 'undefined') action.data.page = state.filter.page;
        if (action.data.limit == 'no_update' && typeof state.filter.limit !== 'undefined') action.data.limit = state.filter.limit;
      }
      return {
        ...state,
        filter: action.data,
      }
    }
    case ACCOUNT_LIST_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
        tong_so_du: action.data.tong_so_du,
      }
    }

    case ACCOUNT_BRANCH_NO_LIMIT_LIST_SUCCESS: {
      return {
        ...state,
        branch_no_limit_items: action.data.items,
        branch_no_limit_total: action.data.total,
      }
    }

    case ACCOUNT_LEADER_NO_LIMIT_LIST_SUCCESS: {
      return {
        ...state,
        leader_no_limit_items: action.data.items,
        leader_no_limit_total: action.data.total,
      }
    }

    case ACCOUNT_SALE_NO_LIMIT_LIST_SUCCESS: {
      return {
        ...state,
        sale_no_limit_items: action.data.items,
        sale_no_limit_total: action.data.total,
      }
    }

    case ACCOUNT_SELECTED_SUCCESS: {
      return {
        ...state,
        selected: action.data
      }
    }

    case ACCOUNT_SELECTED_FAILD: {
      console.log("ACCOUNT_SELECTED_FAILD");
      return {
        ...state,
        selected: undefined
      }
    }
    default: {
      return state;
    }

  }
}
export default accountReducer