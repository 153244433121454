import { useState, useEffect } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';
import { LstracuuAction } from 'src/actions/lstracuu.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  Select as Select1,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';

import { AppAction } from '../../actions/app.action';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

import { NOTI_TYPES } from 'src/utils/constants';
import { MobidanhsachdonAction } from 'src/actions/mobidanhsachdon.action';

const MobifoneNapLeDetail = (props) => {

  const dispatch = useDispatch()
  const { balance } = useSelector(state => state.lookup)
  const { leaders, brands, info, role } = useSelector(state => state.profile)

  const [chietKhauCuaBan, setChietKhauCuaBan] = useState({
      chiet_khau_smart_vi: "0",
      chiet_khau_tkc: "0",
      chiet_khau_dichvu: "0",
      chiet_khau_bunca: "0",
      chiet_khau_napho: "0"
  });

  useEffect(() => {
    async function fetchData() {
        if (info && info.cai_dat_chiet_khau) {
            try {
                const jsonObject = JSON.parse(info.cai_dat_chiet_khau);
                setChietKhauCuaBan({
                    ...values,
                    chiet_khau_smart_vi: typeof jsonObject.chiet_khau_smart_vi !== 'undefined' ? jsonObject.chiet_khau_smart_vi : '0',
                    chiet_khau_tkc: typeof jsonObject.chiet_khau_tkc !== 'undefined' ? jsonObject.chiet_khau_tkc : '0',
                    chiet_khau_dichvu: typeof jsonObject.chiet_khau_dichvu !== 'undefined' ? jsonObject.chiet_khau_dichvu : '0',
                    chiet_khau_bunca: typeof jsonObject.chiet_khau_bunca !== 'undefined' ? jsonObject.chiet_khau_bunca : '0',
                    chiet_khau_napho: typeof jsonObject.chiet_khau_napho !== 'undefined' ? jsonObject.chiet_khau_napho : '0'
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    fetchData();
  }, [])
  
  const [values, setValues] = useState({
    number_type: '-99',
    operator: '-99',
    ten_kh: '',
    nha_mang: '-99',
    boi_so: '-99',
    thue_bao: '',
    so_tien: '0',
  });

  const handleChange = (event) => {
    if (event.target.name == 'so_tien') event.target.value = numeral(event.target.value).format('0,0')
    if (event.target.name == 'number_type' && event.target.value != '1' && values.nha_mang == 'tkc') {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
        'number_type': '1',
      });
    } else if (event.target.name == 'nha_mang' && event.target.value == 'tkc') {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
        'number_type': '1',
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const onLookup = async () => {
   

    let payload = values

    payload.thue_bao = payload.thue_bao.trim().replace(/\D/g, '').slice(-9);
    payload.so_tien = payload.so_tien.trim().replace(/,/g, '');

    if (payload.number_type == '-99') {
      showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn loại thuê bao')
      return;
    }

    if (payload.operator == '-99') {
      showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn nhà mạng')
      return;
    }

    if (payload.ten_kh == '') {
      showToast(NOTI_TYPES.ERROR, 'Bạn phải nhập tên KH')
      return;
    }

    if (payload.nha_mang == '-99') {
      showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn nhà mạng nạp')
      return;
    }

    // if (payload.nha_mang != 'tkc' && payload.boi_so == '-99') {
    //   showToast(NOTI_TYPES.ERROR, 'Bạn phải chọn bội số')
    //   return;
    // }

    if (payload.thue_bao == '') {
      showToast(NOTI_TYPES.ERROR, 'Bạn phải nhập thuê bao')
      return;
    }

    if (parseInt(payload.so_tien) == 0) {
      showToast(NOTI_TYPES.ERROR, 'Số tiền phải > 0')
      return;
    }

    if (payload.nha_mang != 'tkc' && parseInt(payload.so_tien) % 10000 != 0) {
      showToast(NOTI_TYPES.ERROR, 'Số tiền phải là bội số của 10.000')
      return;
    }

    if (payload.nha_mang == 'tkc' && parseInt(payload.so_tien) < 1000) {
      showToast(NOTI_TYPES.ERROR, 'Yêu cầu số tiền nạp phải >= 1.000')
      return;
    }

    if (payload.nha_mang == 'smart_vi' && parseInt(payload.so_tien) < 20000) {
      showToast(NOTI_TYPES.ERROR, 'Yêu cầu số tiền nạp phải >= 20.000')
      return;
    }

    console.log(payload)

    try {
      const result = await dispatch(MobidanhsachdonAction.create({ number_type: payload.number_type, operator: payload.operator, loai_don: 'nap_le', ten_kh: payload.ten_kh, nha_mang: payload.nha_mang, boi_so: payload.boi_so, thue_bao: payload.thue_bao, so_tien: payload.so_tien }));
      if (result && result.result == 'success') showToast(NOTI_TYPES.SUCCESS, 'Tạo đơn thành công!');
      else {
        let errorMes = `Tạo đơn thất bại!`;
        if (typeof result.msg !== 'undefined') errorMes = result.msg;
        showToast(NOTI_TYPES.ERROR, errorMes);
      }
    } catch (error) {
      let errorMes = `Tạo đơn thất bại!`;
      if (typeof error.msg !== 'undefined') errorMes = error.msg;
      showToast(NOTI_TYPES.ERROR, errorMes);
    }
  }

  return (
    <form {...props}>
      <Card>
        <CardHeader
          title="NẠP LẺ"
        />
        <Divider />
        <CardContent>
          <Box
            style={{marginBottom: '25px'}}
          >
            <span style={{color: 'blue'}}>* Chiết khấu của bạn:</span>
            <br></br>
            <span style={{color: 'green'}}>- TOP-UP: <span style={{color: 'brown'}}>{chietKhauCuaBan.chiet_khau_smart_vi}%</span></span>
            <br></br>
            <span style={{color: 'green'}}>- TKC: <span style={{color: 'brown'}}>{chietKhauCuaBan.chiet_khau_tkc}%</span></span>
            <br></br>
            {role.priority < 3 && <>
                <span style={{color: 'green'}}>- DV: <span style={{color: 'brown'}}>{chietKhauCuaBan.chiet_khau_dichvu}%</span></span>
                <br></br>
                <span style={{color: 'green'}}>- BC: <span style={{color: 'brown'}}>{chietKhauCuaBan.chiet_khau_bunca}%</span></span>
                <br></br>
            </>}
            <span style={{color: 'green'}}>- Nạp hộ Mobifone: <span style={{color: 'brown'}}>{chietKhauCuaBan.chiet_khau_napho}%</span></span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              label="Tên KH"
              margin="normal"
              name="ten_kh"
              onChange={handleChange}
              required
              value={values.ten_kh}
              variant="outlined"
              style={{ height: 56, marginTop: 0, width: "100%", marginLeft: 0 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Select1
              style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
              name="operator"
              value={values.operator || -99}
              onChange={handleChange}
              variant="outlined"
              startAdornment={(
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )}
            >
              <MenuItem value="-99">Nhà mạng...</MenuItem>
              <MenuItem value="MOBIFONE">MOBIFONE</MenuItem>
              <MenuItem value="VINAPHONE">VINAPHONE</MenuItem>
              <MenuItem value="VIETTEL">VIETTEL</MenuItem>
            </Select1>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Select1
              style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
              name="nha_mang"
              value={values.nha_mang || -99}
              onChange={handleChange}
              variant="outlined"
              startAdornment={(
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )}
            >
              <MenuItem value="-99">Nhà mạng nạp...</MenuItem>
              <MenuItem value="smart_vi">TOP-UP</MenuItem>
              <MenuItem value="tkc">TKC</MenuItem>
              {role.priority < 3 && <MenuItem value="dichvu">DV</MenuItem>}
              {role.priority < 3 && <MenuItem value="bunca">BC</MenuItem>}
              <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
              {/* <MenuItem value="mobifone_gsm">Mobifone GSM</MenuItem>
              <MenuItem value="mobifone_otp">Mobifone (Nhập OTP)</MenuItem> */}
            </Select1>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Select1
              style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
              name="number_type"
              value={values.number_type || -99}
              onChange={handleChange}
              variant="outlined"
              startAdornment={(
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )}
            >
              <MenuItem value="-99">Loại thuê bao...</MenuItem>
              <MenuItem value="1">TRẢ TRƯỚC</MenuItem>
              <MenuItem value="2">TRẢ SAU</MenuItem>
            </Select1>
          </Box>
          {/* <Box
            sx={{
              display: values.nha_mang == 'mobifone_tkc' ? 'none' : 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Select1
              style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
              name="boi_so"
              value={values.boi_so || -99}
              onChange={handleChange}
              variant="outlined"
              startAdornment={(
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )}
            >
              <MenuItem value="-99">Chọn bội...</MenuItem>
              <MenuItem value="menh_gia_bat_ky">Mệnh giá bất kỳ</MenuItem>
              <MenuItem value="10000">10.000</MenuItem>
              <MenuItem value="20000">20.000</MenuItem>
              <MenuItem value="the_nho_nho_hon_50000">{"Thẻ nhỏ (<50k)"}</MenuItem>
              <MenuItem value="50000">50.000</MenuItem>
              <MenuItem value="100000">100.000</MenuItem>
              <MenuItem value="200000">200.000</MenuItem>
              <MenuItem value="300000">300.000</MenuItem>
              <MenuItem value="500000">500.000</MenuItem>
              <MenuItem value="1000000">1.000.000</MenuItem>
            </Select1>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              label="Thuê bao"
              margin="normal"
              name="thue_bao"
              onChange={handleChange}
              required
              value={values.thue_bao}
              variant="outlined"
              style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              label="Số tiền"
              margin="normal"
              name="so_tien"
              onChange={handleChange}
              required
              value={values.so_tien}
              variant="outlined"
              style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled=""
              onClick={onLookup}
              style={{ height: 56, marginTop: 6, width: '100%', marginLeft: 0 }}
            >
              NẠP
            </Button>
          </Box>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

export default MobifoneNapLeDetail;
