import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { QuetdataAction } from 'src/actions/quetdata.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getQuetdataStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { element } from 'prop-types';

import MainSocket from 'src/components/MainSocket.js'

const QuetdataLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedQuetdataIds, setSelectedQuetdataIds] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const { items, total, filter } = useSelector(state => state.quetdata);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { accessToken, expiresAt } = useSelector(state => state.auth)
  const [recordInfos, setRecordInfos] = useState({});
  const { socket, socket_client_id } = MainSocket(useSelector(state => state));

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(QuetdataAction.updateFilter(payload));
    dispatch(QuetdataAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedQuetdataIds;

    if (event.target.checked) {
      newSelectedQuetdataIds = items.map((quetdata) => quetdata.id);
    } else {
      newSelectedQuetdataIds = [];
    }

    setSelectedQuetdataIds(newSelectedQuetdataIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedQuetdataIds.indexOf(id);
    let newSelectedQuetdataIds = [];

    if (selectedIndex === -1) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(selectedQuetdataIds, id);
    } else if (selectedIndex === 0) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(selectedQuetdataIds.slice(1));
    } else if (selectedIndex === selectedQuetdataIds.length - 1) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(selectedQuetdataIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(
        selectedQuetdataIds.slice(0, selectedIndex),
        selectedQuetdataIds.slice(selectedIndex + 1)
      );
    }

    setSelectedQuetdataIds(newSelectedQuetdataIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, quetdata) => {
    setSelected(quetdata);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(QuetdataAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(QuetdataAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dungQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      //socket.emit('dungQuet', {client_id: socket_client_id, data: {token: accessToken, FileName: FileName}});
      alert("Đã yêu cầu dừng quét \"" + FileName + "\"")
      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const batDauQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      //socket.emit('batDauQuet', {client_id: socket_client_id, data: {token: accessToken, FileName: FileName}});
      alert("Bắt đầu quét \"" + FileName + "\"")
      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const xoaFile = async (FileName) => {
    const question = "Xin lưu ý, khi xóa file này thì toàn bộ dữ liệu quét cũng sẽ xóa theo. Bạn có chắc muốn xóa file này?";
    if (window.confirm(question)) {
      dispatch(QuetdataAction.deleteFile(FileName));
    } else {

    }
  }

  const getInfoByFileName = async (FileName) => {
    const info = await dispatch(QuetdataAction.getInfoByFileName({FileName: FileName}));
    if (info && typeof info.data !== 'undefined' && info.data) {
      setRecordInfos({
        ...recordInfos,
        [FileName]: info.data,
      });
    }
    console.log(recordInfos);
  }

  const xuatSoDaQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      const response = await dispatch(QuetdataAction.findAllByFileName({"FileName": FileName}));
      let data = {};
      data[0] = [
          '#', 
          'Số điện thoại', 
          'Loại thuê bao', 
          'Gói cước', 
          'Ngày đăng ký', 
          'Ngày hết hạn', 
          'Chu kỳ hiện tại',
          'Số chu kỳ miễn phí còn lại',
          'Trạng thái',
          'Trạng thái hiệu lực',
          'Gói cước 1', 
          'Ngày đăng ký 1', 
          'Ngày hết hạn 1', 
          'Chu kỳ hiện tại 1', 
          'Số chu kỳ miễn phí còn lại 1',
          'Trạng thái 1',
          'Trạng thái hiệu lực 1',
          'Gói cước 2', 
          'Ngày đăng ký 2', 
          'Ngày hết hạn 2', 
          'Chu kỳ hiện tại 2', 
          'Số chu kỳ miễn phí còn lại 2',
          'Trạng thái 2',
          'Trạng thái hiệu lực 2',
      ];

      if (typeof response !== 'undefined' && response != null && response.length > 0) {
        var count = 1;
        response.forEach((element) => {
          let phone = element.PhoneNumber;

          if (data.hasOwnProperty(phone)) {
            data[phone] = [
              ...data[phone],
              element.NumberCurrentPackage,
              element.NumberPackageRegisterDate,
              element.NumberPackageRegisterExpire,
              element.CurrentCycle,
              element.NumberFreeCycleLeft,
              element.NumberStatus,
              element.Status,
            ];
          } else {
            data[phone] = [
              count,
              phone,
              element.NumberType,
              element.NumberCurrentPackage,
              element.NumberPackageRegisterDate,
              element.NumberPackageRegisterExpire,
              element.CurrentCycle,
              element.NumberFreeCycleLeft,
              element.NumberStatus,
              element.Status,
            ];
            count++;
          }
        })

        //Sắp xếp tăng dần theo count
        data = Object.values(data).sort((a, b) => a[0] - b[0]);

        //console.log(data);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'DA-XU-LY-' + FileName);
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Tên file
                </TableCell>
                <TableCell>
                  Số đã quét
                </TableCell>
                <TableCell>
                  Ngày tải lên
                </TableCell>
                <TableCell>
                  Người tải lên
                </TableCell>
                <TableCell>
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice(page * limit, page * limit + limit).map((quetdata, index) => (
                <TableRow
                  hover
                  key={quetdata.id}
                  selected={selectedQuetdataIds.indexOf(quetdata.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {quetdata.FileName}
                  </TableCell>
                  <TableCell>
                    {(typeof recordInfos[quetdata.FileName] !== 'undefined' ? recordInfos[quetdata.FileName].so_da_quet : '-') + "/" + (typeof recordInfos[quetdata.FileName] !== 'undefined' ? recordInfos[quetdata.FileName].total_numbers : '-')} <br></br> {"Tổng gói: " + (typeof recordInfos[quetdata.FileName] !== 'undefined' ? recordInfos[quetdata.FileName].goi_da_quet : '-')}
                  </TableCell>
                  <TableCell>
                    {moment(quetdata.ngay_upload).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {(typeof recordInfos[quetdata.FileName] !== 'undefined' ? recordInfos[quetdata.FileName].nguoi_upload : '-')}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => getInfoByFileName(quetdata.FileName)}>
                      Info
                    </Button>/
                    <Button onClick={() => batDauQuet(quetdata.FileName)}>
                      Quét
                    </Button>/
                    <Button onClick={() => dungQuet(quetdata.FileName)}>
                      Dừng
                    </Button>/
                    <Button onClick={() => xuatSoDaQuet(quetdata.FileName)}>
                      Xuất file
                    </Button>/
                    <Button onClick={() => xoaFile(quetdata.FileName)}>
                      Xóa file
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default QuetdataLookupDetail;
