import { Helmet } from 'react-helmet';
import CauhinhgsmcomlistDetail
  from 'src/components/cauhinhgsmcomlist-detail/CauhinhgsmcomlistDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

const Cauhinhgsmcomlist = () => (
  <>
    <Helmet>
      <title>Cấu hình GSM - Danh sách cổng</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <Box sx={{ pt: 3 }}>
          <CauhinhgsmcomlistDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Cauhinhgsmcomlist;
