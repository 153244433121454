import { Helmet } from 'react-helmet';
import LichsuhoatdongLookupDetail
  from 'src/components/lichsuhoatdong-lookup-detail/LichsuhoatdongLookupDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import LichsuhoatdongListToolbar from '../components/lichsuhoatdong-lookup-detail/LichsuhoatdongListToolbar';

const LichsuhoatdongLookup = () => (
  <>
    <Helmet>
      <title>Lịch sử hoạt động</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <LichsuhoatdongListToolbar />
        <Box sx={{ pt: 3 }}>
          <LichsuhoatdongLookupDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default LichsuhoatdongLookup;
