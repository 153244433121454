import { QuanlynapcuocService } from 'src/services/quanlynapcuoc.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const QUANLYNAPCUOC_NEW_CARE_SUCCESS = 'QUANLYNAPCUOC:NEW_CARE_SUCCESS'
export const QUANLYNAPCUOC_LIST_SUCCESS = 'QUANLYNAPCUOC:FIND_ALL_SUCCESS'
export const QUANLYNAPCUOC_LIST_FAILD = 'QUANLYNAPCUOC:FIND_ALL_FAILD'
export const QUANLYNAPCUOC_COUNT_ASSIGN_SUCCESS = 'QUANLYNAPCUOC:COUNT_ASSIGN_SUCCESS'
export const QUANLYNAPCUOC_COUNT_ASSIGN_FAILD = 'QUANLYNAPCUOC:COUNT_ASSIGN_FAILD'

export const QUANLYNAPCUOC_CREATE_SUCCESS = 'QUANLYNAPCUOC:CREATE_SUCCESS'
export const QUANLYNAPCUOC_CREATE_FAILD = 'QUANLYNAPCUOC:CREATE_FAILD'

export const QUANLYNAPCUOCCHITIET_CREATE_SUCCESS = 'QUANLYNAPCUOCCHITIET:CREATE_SUCCESS'
export const QUANLYNAPCUOCCHITIET_CREATE_FAILD = 'QUANLYNAPCUOCCHITIET:CREATE_FAILD'

export const QUANLYNAPCUOC_IMPORT_SUCCESS = 'QUANLYNAPCUOC:IMPORT_SUCCESS'
export const QUANLYNAPCUOC_SAVE_SUCCESS = 'QUANLYNAPCUOC:SAVE_SUCCESS'
export const QUANLYNAPCUOC_SAVE_FAILD = 'QUANLYNAPCUOC:SAVE_SUCCESS'

export const QUANLYNAPCUOC_DEL_SUCCESS = 'QUANLYNAPCUOC:DEL_SUCCESS'
export const QUANLYNAPCUOC_DEL_FAILD = 'QUANLYNAPCUOC:DEL_FAILD'

export const QUANLYNAPCUOC_ONE_SUCCESS = 'QUANLYNAPCUOC:FIND_ONE_SUCCESS'
export const QUANLYNAPCUOC_ONE_FAILD = 'QUANLYNAPCUOC:FIND_ONE_FAILD'

export const QUANLYNAPCUOCCHITIET_ONE_SUCCESS = 'QUANLYNAPCUOCCHITIET:FIND_ONE_SUCCESS'
export const QUANLYNAPCUOCCHITIET_ONE_FAILD = 'QUANLYNAPCUOCCHITIET:FIND_ONE_FAILD'

export const QUANLYNAPCUOC_HISTORY_LIST_SUCCESS = 'QUANLYNAPCUOC:HISTORY_FIND_ALL_SUCCESS'
export const QUANLYNAPCUOC_HISTORY_LIST_FAILD = 'QUANLYNAPCUOC:HISTORY_FIND_ALL_FAILD'

export const QUANLYNAPCUOC_UPDATE_FILTER = 'QUANLYNAPCUOC:UPDATE_FILTER'

export const QUANLYNAPCUOC_UPDATE_FILTER_DON_CHO_HE_THONG = 'QUANLYNAPCUOC:UPDATE_FILTER_DON_CHO_HE_THONG'

export const QUANLYNAPCUOCCHITIET_LIST_SUCCESS = 'QUANLYNAPCUOCCHITIET:FIND_ALL_SUCCESS'
export const QUANLYNAPCUOCCHITIET_LIST_FAILD = 'QUANLYNAPCUOCCHITIET:FIND_ALL_FAILD'

export const QUANLYNAPCUOC_LIST_EXPORT_SUCCESS = 'QUANLYNAPCUOC:FIND_ALL_EXPORT_SUCCESS'
export const QUANLYNAPCUOC_LIST_EXPORT_FAILD = 'QUANLYNAPCUOC:FIND_ALL_EXPORT_FAILD'

export const QUANLYNAPCUOC_THONG_KE_SUCCESS = 'QUANLYNAPCUOC:THONG_KE_SUCCESS'
export const QUANLYNAPCUOC_THONG_KE_FAILD = 'QUANLYNAPCUOC:THONG_KE_FAILD'

export const QUANLYNAPCUOC_DON_CHO_HE_THONG_SUCCESS = 'QUANLYNAPCUOC:DON_CHO_HE_THONG_SUCCESS'
export const QUANLYNAPCUOC_DON_CHO_HE_THONG_FAILD = 'QUANLYNAPCUOC:DON_CHO_HE_THONG_FAILD'

export const QuanlynapcuocAction = {
    list,
    findAllByOrder,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    findOneChiTietDon,
    approve,
    setTrangThai,
    updateFilter,
    updateFilterDonchohethong,
    listExport,
    chayTiep,
    chayTiepChiTiet,
    daNap,
    daNapChiTiet,
    huyDon,
    huyDonChiTiet,
    tamDung,
    tamDungChiTiet,
    choNap,
    choNapChiTiet,
    dangNap,
    dangNapChiTiet,
    thatBai,
    thatBaiChiTiet,
    hoanThanh,
    batDauDon,
    batDauDonChiTiet,
    duaDon,
    duaDonChiTiet,
    thongKe,
    donChoHeThong,
    guiOtp,
    nhapOtp,
    guiOtpDonTong,
    nhapOtpDonTong,
    updateBoiSo,
    updateBoiSoChiTiet,
    handleSuaPortNhieuMuc,
}

function updateFilter(payload) {
    return { type: QUANLYNAPCUOC_UPDATE_FILTER, data: payload }
}

function updateFilterDonchohethong(payload) {
    return { type: QUANLYNAPCUOC_UPDATE_FILTER_DON_CHO_HE_THONG, data: payload }
}

function handleSuaPortNhieuMuc(payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { cauhinhgsm } = getState();
            
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.handleSuaPortNhieuMuc(payload)
            .then(response => {
                // Handle the successful response
                // dispatch(success(response));
                console.log(payload.yeuCauXuLy)
                dispatch(QuanlynapcuocAction.list());
                resolve(response);
            })
            .catch(error => {
                // Handle the error
                // dispatch(failure(error));
                reject(error);
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_LIST_FAILD, error } }
}

function guiOtp(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.guiOtp(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function nhapOtp(id, id_don_tong, otp) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.nhapOtp(id, otp)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function guiOtpDonTong(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.guiOtp(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function nhapOtpDonTong(id, otp) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.nhapOtp(id, otp)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function listExport() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { quanlynapcuoc } = getState();

            const { filter } = quanlynapcuoc;

            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.listExport(filter)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: QUANLYNAPCUOC_LIST_EXPORT_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_LIST_EXPORT_FAILD, error } }
}

function thongKe(operator = null) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { quanlynapcuoc } = getState();
            const { filter } = quanlynapcuoc;
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.thongKe({...filter, operator})
            .then(response => {
                // Handle the successful response
                dispatch(success({
                    items: response.data.values,
                }));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_THONG_KE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_THONG_KE_FAILD, error } }
}

function donChoHeThong(operator = null) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { quanlynapcuoc } = getState();
        const { donchohethongfilter: filter } = quanlynapcuoc;
        const page = typeof filter.donchohethongpage !== 'undefined' ? filter.donchohethongpage : 0;
        const limit = typeof filter.donchohethonglimit !== 'undefined' ? filter.donchohethonglimit : 10;
        QuanlynapcuocService.donChoHeThong({...filter, operator}).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = quanlynapcuoc.donchohethongitems;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_DON_CHO_HE_THONG_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_DON_CHO_HE_THONG_FAILD, error } }
}

function chayTiep(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.chayTiep(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function daNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.daNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function huyDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.huyDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function tamDung(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.tamDung(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function choNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.choNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function dangNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.dangNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function thatBai(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.thatBai(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function hoanThanh(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.hoanThanh(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function batDauDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.batDauDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function duaDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.duaDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function updateBoiSo(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.updateBoiSo(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function updateBoiSoChiTiet(id, id_don_tong, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.updateBoiSoChiTiet(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function duaDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.duaDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function batDauDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.batDauDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function chayTiepChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.chayTiepChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOCCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOCCHITIET_CREATE_FAILD, error } }
}

function daNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.daNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOCCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOCCHITIET_CREATE_FAILD, error } }
}

function huyDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.huyDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOCCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOCCHITIET_CREATE_FAILD, error } }
}

function tamDungChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.tamDungChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOCCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOCCHITIET_CREATE_FAILD, error } }
}

function choNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.choNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function dangNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.dangNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function thatBaiChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.thatBaiChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(QuanlynapcuocAction.findAllByOrder(id_don_tong));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function setTrangThai(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.setTrangThai(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { quanlynapcuoc } = getState()
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quanlynapcuoc.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_LIST_FAILD, error } }
}

function findAllByOrder(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { quanlynapcuoc } = getState();
        const page = quanlynapcuoc && typeof quanlynapcuoc.filter !== 'undefined' && quanlynapcuoc.filter && typeof quanlynapcuoc.filter.page !== 'undefined' ? quanlynapcuoc.filter.page : 0;
        const limit = quanlynapcuoc && typeof quanlynapcuoc.filter !== 'undefined' && quanlynapcuoc.filter && typeof quanlynapcuoc.filter.limit !== 'undefined' ? quanlynapcuoc.filter.limit : 10;
        QuanlynapcuocService.findAllByOrder(id, page, limit, quanlynapcuoc.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = quanlynapcuoc.quanlynapcuocchitiet_items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOCCHITIET_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOCCHITIET_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { quanlynapcuoc } = getState();
        const page = quanlynapcuoc && typeof quanlynapcuoc.filter !== 'undefined' && quanlynapcuoc.filter && typeof quanlynapcuoc.filter.page !== 'undefined' ? quanlynapcuoc.filter.page : 0;
        const limit = quanlynapcuoc && typeof quanlynapcuoc.filter !== 'undefined' && quanlynapcuoc.filter && typeof quanlynapcuoc.filter.limit !== 'undefined' ? quanlynapcuoc.filter.limit : 10;
        QuanlynapcuocService.findAll(page, limit, quanlynapcuoc.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = quanlynapcuoc.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: QUANLYNAPCUOC_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            QuanlynapcuocService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(QuanlynapcuocAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: QUANLYNAPCUOC_SAVE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            QuanlynapcuocService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(QuanlynapcuocAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(QuanlynapcuocAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(QuanlynapcuocAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_DEL_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_ONE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_ONE_FAILD, error } }
}

function findOneChiTietDon(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.findOneChiTietDon(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOCCHITIET_ONE_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOCCHITIET_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(QuanlynapcuocAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { quanlynapcuoc } = getState()
        dispatch(AppAction.appLoading(true));
        QuanlynapcuocService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quanlynapcuoc.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUANLYNAPCUOC_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUANLYNAPCUOC_HISTORY_LIST_FAILD, error } }
}