import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { DsdttinhluongAction } from 'src/actions/dsdttinhluong.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';

import numeral from 'numeral';

const DsdttinhluongDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, tongGiaTriGoi, tongDoanhThu, tongGoi, tongSoTienHoan } = useSelector(state => state.dsdttinhluong);
  const { role } = useSelector(state => state.profile);

  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [updateUserValue, setUpdateUserValue] = useState({});


  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    
    dispatch(DsdttinhluongAction.updateFilter(payload));

    dispatch(DsdttinhluongAction.list());
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // const handleUserChange = (event, username, track0, track1) => {
  //   if (event.key === 'Enter') {
  //     username = username.trim();
  //     if (username != '') {
  //       setUpdateUserValue({
  //         ...updateUserValue,
  //         [track0 + '_' + track1]: username,
  //       });
  //       dispatch(DsdttinhluongAction.updateUser(username, track0, track1));
  //     }
  //   }
  // };

  const handleUserChange = (event, username, track0, track1) => {
    username = username.trim();
    if (username != '') {
      setUpdateUserValue({
        ...updateUserValue,
        [track0 + '_' + track1]: username,
      });
      dispatch(DsdttinhluongAction.updateUser(username, track0, track1));
    }
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG GÓI
                </TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG GIÁ TRỊ GÓI
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG SỐ TIỀN HOÀN
                </TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG DOANH THU TÍNH LƯƠNG
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongGoi).format('0,0')}
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongGiaTriGoi).format('0,0')}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongSoTienHoan).format('0,0')}
                </TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongDoanhThu).format('0,0')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Kênh
                </TableCell>
                <TableCell>
                  Username NV
                </TableCell>
                <TableCell>
                  Tên NV
                </TableCell>
                <TableCell>
                  SĐT
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Giá gói cước
                </TableCell>
                <TableCell>
                  Ngày đăng ký
                </TableCell>
                <TableCell>
                  KQ ĐS với MBF
                </TableCell>
                <TableCell>
                  Có tạo YCHT?
                </TableCell>
                <TableCell>
                  Yêu cầu - Hình thức
                </TableCell>
                <TableCell>
                  Số tiền hoàn
                </TableCell>
                <TableCell>
                  Tỉ lệ doanh thu (mặc định)
                </TableCell>
                <TableCell>
                  Doanh thu tính lương
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((dsdttinhluong, index) => (
                <TableRow
                  hover
                  key={dsdttinhluong.track0 + '_' + dsdttinhluong.track1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {dsdttinhluong.kenh}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {dsdttinhluong.username_nv}
                  </TableCell>
                  <TableCell>
                    {dsdttinhluong.ten_nv}
                  </TableCell>
                  <TableCell>
                    {role.priority < 3 ? dsdttinhluong.so_dien_thoai_cs : ('****' + dsdttinhluong.so_dien_thoai_cs.slice(-5))}
                  </TableCell>
                  <TableCell>
                    {dsdttinhluong.goi_cuoc}
                  </TableCell>
                  <TableCell>
                    {numeral(dsdttinhluong.doanh_thu).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {moment(dsdttinhluong.ngay_thanh_cong).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {dsdttinhluong.status == 'SUCCESS' ? 'Thành công' : 'Thất bại'}
                  </TableCell>
                  <TableCell>
                    {dsdttinhluong.staff_username ? 'Có' : 'Không'}
                  </TableCell>
                  <TableCell>
                    {dsdttinhluong.request_type == 1 && "Hoàn trước"}
                    {dsdttinhluong.request_type == 2 && "Hoàn sau"}
                    {dsdttinhluong.request_type == 3 && "Không hoàn"}
                    {dsdttinhluong.request_type == 4 && "Hoàn thêm"}
                    {dsdttinhluong.request_type == 5 && "Nạp tiền"}
                    {dsdttinhluong.request_method == 1 && " - Gia hạn"}
                    {dsdttinhluong.request_method == 2 && " - Nâng cấp T1"}
                    {dsdttinhluong.request_method == 3 && " - Nâng cấp T2"}
                    {dsdttinhluong.request_method == 4 && " - Nâng cấp T3"}
                  </TableCell>
                  <TableCell>
                    {numeral(dsdttinhluong.refund_price).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {dsdttinhluong.ti_le_doanh_thu}%
                  </TableCell>
                  <TableCell>
                    {numeral(dsdttinhluong.doanh_thu_tinh_luong).format('0,0')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default DsdttinhluongDetail;
