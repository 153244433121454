import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import{  NapmymobifoneAction } from 'src/actions/napmymobifone.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getBlacklistStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';

//import CreateEditDialog from './CreateEditDialog';

import { showToast } from 'src/utils/common';

import { useParams } from 'react-router-dom';

import numeral from 'numeral';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as XLSX from 'xlsx';
import { AppAction } from 'src/actions/app.action';
import { saveAs } from 'file-saver';
import CreateEditDialogBoiSoChiTiet from '../NapMyMobifone-detail/CreateEditDialogBoiSoChiTiet';

const NapMyMobifoneChiTietDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedBlacklistIds, setSelectedBlacklistIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { filter, napmymobifonechitiet_items, napmymobifonechitiet_total, napmymobifonechitiet_tong_can_nap, napmymobifonechitiet_tong_da_nap, napmymobifonechitiet_tong_huy_nap, napmymobifonechitiet_tong_that_bai, napmymobifonechitiet_tong_ton_nap } = useSelector(state => state.napmymobifone);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { info, permissions, role } = useSelector(state => state.profile)
  const [otps, setOtps] = useState({});
  const [openEditBoiSo, setOpenEditBoiSo] = useState(false);
  const { isLoading } = useSelector(state => state.app);
  const [values, setValues] = useState({
    phone: "",
    ma_don: "",
    trang_thai: "-99",
  });

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
      phone: values.phone,
      ma_don: values.ma_don,
      trang_thai: values.trang_thai,
    };
    dispatch(NapmymobifoneAction.updateFilter(payload));
    dispatch(NapmymobifoneAction.findAllByOrder(id))
  }, [page, limit])

  const onLookup = (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: typeof immediatelyPayload.phone !== 'undefined' ? immediatelyPayload.phone : values.phone,
      ma_don: typeof immediatelyPayload.ma_don !== 'undefined' ? immediatelyPayload.ma_don : values.ma_don,
      trang_thai: typeof immediatelyPayload.trang_thai !== 'undefined' ? immediatelyPayload.trang_thai : values.trang_thai,
    };
    dispatch(NapmymobifoneAction.updateFilter(payload));
    dispatch(NapmymobifoneAction.findAllByOrder(id))
  };

  const exportExcel = async () => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        username: values.username,
        phone: values.phone,
        ma_don: id,
        kenh_yeu_cau: values.kenh_yeu_cau,
        trang_thai: values.trang_thai,
      };
      dispatch(NapmymobifoneAction.updateFilter(payload));
      const response = await dispatch(NapmymobifoneAction.listExport());

      const data = [
        [
          'Mã đơn', 
          'Mã yêu cầu', 
          'Username lên đơn',
          'Tên đơn',
          'Nhà mạng',
          'Thuê bao', 
          'Loại thuê bao', 
          'Cần nạp', 
          'Đã nạp', 
          'Hủy nạp',
          'Thất bại',
          'Tồn',
          'Chiết khấu',
          'Đua',
          'Phí đua',
          'Bội số',
          'Ngày tạo đơn',
          'Kênh yêu cầu',
          'Trạng thái',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let loaiThueBao = '';
          let boiSo = '';
          let nhaMang = '';
          let kenhYeuCau = ''
          let trangThai = ''

          if (element.number_type == 1) loaiThueBao = 'TRẢ TRƯỚC';
          else if (element.number_type == 2) loaiThueBao = 'TRẢ SAU';

          if (element.boi_so == 'menh_gia_bat_ky') boiSo = 'Mệnh giá bất kỳ';
          else if (element.boi_so == '10000') boiSo = '10.000';
          else if (element.boi_so == '20000') boiSo = '20.000';
          else if (element.boi_so == 'the_nho_nho_hon_50000') boiSo = 'Thẻ nhỏ (<50k)';
          else if (element.boi_so == '50000') boiSo = '50.000';
          else if (element.boi_so == '100000') boiSo = '100.000';
          else if (element.boi_so == '200000') boiSo = '200.000';
          else if (element.boi_so == '300000') boiSo = '300.000';
          else if (element.boi_so == '500000') boiSo = '500.000';
          else if (element.boi_so == '1000000') boiSo = '1.000.000';
          else boiSo = element.boi_so;

          if (element.nha_mang == 'khong_qua_api') nhaMang = 'Không qua API';
          else if (element.nha_mang == 'smart_vi') nhaMang = 'TOP-UP';
          else if (element.nha_mang == 'tkc') nhaMang = 'TKC';
          else if (element.nha_mang == 'bunca') nhaMang = 'BC';
          else if (element.nha_mang == 'dichvu') nhaMang = 'DV';
          else if (element.nha_mang == 'napho') nhaMang = 'Nạp hộ Mobifone';
          else if (element.nha_mang == 'mymobifone') nhaMang = 'My Mobifone';
          else if (element.nha_mang == 'gsmmobifone') nhaMang = 'GSM Mobifone';
          else if (element.nha_mang == 'mobifone_gsm') nhaMang = 'Mobifone GSM';
          else if (element.nha_mang == 'mobifone_otp') nhaMang = 'Mobifone (Nhập OTP)';

          if (element.kenh_yeu_cau == 'khong_qua_api') kenhYeuCau = 'Không qua API';
          else if (element.kenh_yeu_cau == 'smart_vi') kenhYeuCau = 'TOP-UP';
          else if (element.kenh_yeu_cau == 'tkc') kenhYeuCau = 'TKC';
          else if (element.kenh_yeu_cau == 'bunca') kenhYeuCau = 'BC';
          else if (element.kenh_yeu_cau == 'dichvu') kenhYeuCau = 'DV';
          else if (element.kenh_yeu_cau == 'napho') kenhYeuCau = 'Nạp hộ Mobifone';
          else if (element.kenh_yeu_cau == 'mymobifone') kenhYeuCau = 'My Mobifone';
          else if (element.kenh_yeu_cau == 'gsmmobifone') kenhYeuCau = 'GSM Mobifone';

          if (element.trang_thai == 'chay_tiep') trangThai = 'CHẠY TIẾP';
          else if (element.trang_thai == 'tam_dung') trangThai = 'TẠM DỪNG';
          else if (element.trang_thai == 'da_huy') trangThai = 'HỦY ĐƠN';
          else if (element.trang_thai == 'cho_nap') trangThai = 'CHỜ NẠP';
          else if (element.trang_thai == 'dang_nap') trangThai = 'ĐANG NẠP';
          else if (element.trang_thai == 'da_nap') trangThai = 'ĐÃ NẠP';
          else if (element.trang_thai == 'that_bai') trangThai = 'HOÀN TIỀN';

          data.push([
            element.ma_don,
            element.ma_yeu_cau,
            element.username_len_don,
            element.ten_don,
            element.operator,
            parseRealPhoneNumber(element.thue_bao),
            loaiThueBao,
            element.can_nap,
            element.da_nap,
            element.huy_nap,
            element.that_bai,
            element.ton_nap,
            element.chiet_khau,
            element.dua,
            element.phi_dua,
            boiSo,
            moment(element.created_at).format('DD-MM-YYYY HH:mm:ss'),
            kenhYeuCau,
            trangThai,
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Danh-sach-don-nap-tien.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  const parseRealPhoneNumber = (msisdn) => {
      let phone = msisdn.slice(-9);
      let firstChar = phone.substring(0, 1);
      if (firstChar == '2') {
          return `1${phone}`;
      }
      return phone;
  }

  const hienThiLyDoHoanTien = (lyDo) => {
    alert(`Lý do hoàn tiền:\n${lyDo ? lyDo : ''}`);
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({[event.target.name]: event.target.value});
  };

  const handleSelectAll = (event) => {
    let newSelectedBlacklistIds;

    if (event.target.checked) {
      newSelectedBlacklistIds = napmymobifonechitiet_items.map((napmymobifonechitiet) => napmymobifonechitiet.id);
    } else {
      newSelectedBlacklistIds = [];
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBlacklistIds.indexOf(id);
    let newSelectedBlacklistIds = [];

    if (selectedIndex === -1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(1));
    } else if (selectedIndex === selectedBlacklistIds.length - 1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(
        selectedBlacklistIds.slice(0, selectedIndex),
        selectedBlacklistIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, napmymobifonechitiet) => {
    setSelected(napmymobifonechitiet);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(NapmymobifoneAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(NapmymobifoneAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const chayTiep = async (id, id_don_tong) => {
    let question = "Bạn có muốn chạy tiếp đơn?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Chạy tiếp đơn '${id}'`);
        dispatch(NapmymobifoneAction.chayTiepChiTiet(id, id_don_tong));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const daNap = async (id, id_don_tong) => {
    let question = `Bạn có muốn chuyển trạng thái đơn này sang ĐÃ NẠP?`;
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang ĐÃ NẠP`);
        dispatch(NapmymobifoneAction.daNapChiTiet(id, id_don_tong));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const huyDon = async (id, id_don_tong) => {
    let question = "Bạn có muốn hủy đơn này?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đang hủy đơn '${id}'`);
        dispatch(NapmymobifoneAction.huyDonChiTiet(id, id_don_tong));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const tamDung = async (id, id_don_tong) => {
    let question = "Bạn có muốn tạm dừng đơn này?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đã tạm dừng đơn '${id}'`);
        dispatch(NapmymobifoneAction.tamDungChiTiet(id, id_don_tong));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const choNap = async (id, id_don_tong) => {
    let question = "Bạn có muốn đổi trạng thái đơn này sang CHỜ NẠP?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang CHỜ NẠP`);
        dispatch(NapmymobifoneAction.choNapChiTiet(id, id_don_tong));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const dangNap = async (id, id_don_tong) => {
    let question = "Bạn có muốn đổi trạng thái đơn này sang ĐANG NẠP?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang ĐANG NẠP`);
        dispatch(NapmymobifoneAction.dangNapChiTiet(id, id_don_tong));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const thatBai = async (id, id_don_tong) => {
    let question = "Bạn có muốn đổi trạng thái đơn này sang HOÀN TIỀN?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang HOÀN TIỀN`);
        dispatch(NapmymobifoneAction.thatBaiChiTiet(id, id_don_tong));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const batDauDon = async (id, id_don_tong) => {
    dispatch(NapmymobifoneAction.batDauDonChiTiet(id, id_don_tong));
  };

  const duaDon = async (id, id_don_tong) => {
    dispatch(AppAction.appLoading(true))
    const res = await dispatch(NapmymobifoneAction.duaDonChiTiet(id, id_don_tong));
    if (res.data.result) {
      if (res.data.result == 'success') {
        showToast('success', res.data.msg);
      } else {
        showToast('failed', res.data.msg);
      }
      dispatch(AppAction.appLoading(true))
    }
  };

  const guiOtp = async (id, id_don_tong) => {
    dispatch(AppAction.appLoading(true))
    const res = await dispatch(NapmymobifoneAction.guiOtp(id, id_don_tong));
    if (res.result) {
      if (res.result == 'success') {
        showToast('success', res.msg);
      } else {
        showToast('failed', res.msg);
      }
      dispatch(AppAction.appLoading(true))
    }
  }

  const nhapOtp = async (id, id_don_tong) => {
    dispatch(AppAction.appLoading(true))
    const res = await dispatch(NapmymobifoneAction.nhapOtp(id, id_don_tong, otps[`otp_${id}`] || ''));
    if (res.result) {
      if (res.result == 'success') {
        showToast('success', res.msg);
      } else {
        showToast('failed', res.msg);
      }
      dispatch(AppAction.appLoading(true))
    }
  }

  const nhapOtpChange = async (event) => {
    setOtps({
      ...otps,
      [event.target.name]: event.target.value.trim()
    });
  }

  const nhapOtpKeyDown = (event, id, id_don_tong) => {
    if (event.key === 'Enter') {
      nhapOtp(id, id_don_tong);
    }
  };

  const handleEditBoiSo = (id) => {
    dispatch(NapmymobifoneAction.findOneChiTietDon(id));
    setOpenEditBoiSo(true);
  }

  const handleCloseEditBoiSo = () => {
    setOpenEditBoiSo(false);
  }

  const hienThiBoiSo = (boi_so) => {
    let boiSo = '';
    if (boi_so == 'menh_gia_bat_ky') boiSo = 'Mệnh giá bất kỳ';
    else if (boi_so == '10000') boiSo = '10.000';
    else if (boi_so == '20000') boiSo = '20.000';
    else if (boi_so == 'the_nho_nho_hon_50000') boiSo = 'Thẻ nhỏ (<50k)';
    else if (boi_so == '50000') boiSo = '50.000';
    else if (boi_so == '100000') boiSo = '100.000';
    else if (boi_so == '200000') boiSo = '200.000';
    else if (boi_so == '300000') boiSo = '300.000';
    else if (boi_so == '500000') boiSo = '500.000';
    else if (boi_so == '1000000') boiSo = '1.000.000';
    else boiSo = boi_so;
    return boiSo;
  }

  const xuLyChucNang = async (value, id, id_don_tong) => {
    if (value == 'chay_tiep') await chayTiep(id, id_don_tong);
    else if (value == 'tam_dung') await tamDung(id, id_don_tong);
    else if (value == 'da_nap') await daNap(id, id_don_tong);
    else if (value == 'da_huy') await huyDon(id, id_don_tong);
    else if (value == 'cho_nap') await choNap(id, id_don_tong);
    else if (value == 'dang_nap') await dangNap(id, id_don_tong);
    else if (value == 'that_bai') await thatBai(id, id_don_tong);
    else if (value == 'dua_don') await duaDon(id, id_don_tong);
    else if (value == 'bat_dau_don') await batDauDon(id, id_don_tong);
  };

  const colorByStatus = (status) => {
    if (status == "chay_tiep") return '#1338be';
    else if (status == "tam_dung") return 'orange';
    else if (status == "da_huy") return 'red';
    else if (status == "cho_nap") return '#65350f';
    else if (status == "dang_nap") return '#ff0099';
    else if (status == "da_nap") return 'green';
    else if (status == "that_bai") return 'red';
    else return '';
  }

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PerfectScrollbar>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 1050,
            }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="phone"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.phone}
                placeholder="Tìm SĐT"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="ma_don"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.ma_don}
                placeholder="Tìm theo mã yêu cầu"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Select1
                style={{ height: 56, marginTop: 6, width: "250px", marginLeft: 0 }}
                name="trang_thai"
                value={values.trang_thai || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Trạng thái...</MenuItem>
                <MenuItem value="chay_tiep" style={{color: colorByStatus('chay_tiep')}}>CHẠY TIẾP</MenuItem>
                <MenuItem value="tam_dung" style={{color: colorByStatus('tam_dung')}}>TẠM DỪNG</MenuItem>
                <MenuItem value="da_huy" style={{color: colorByStatus('da_huy')}}>HỦY ĐƠN</MenuItem>
                <MenuItem value="cho_nap" style={{color: colorByStatus('cho_nap')}}>CHỜ NẠP</MenuItem>
                <MenuItem value="dang_nap" style={{color: colorByStatus('dang_nap')}}>ĐANG NẠP</MenuItem>
                <MenuItem value="da_nap" style={{color: colorByStatus('da_nap')}}>ĐÃ NẠP</MenuItem>
                <MenuItem value="that_bai" style={{color: colorByStatus('that_bai')}}>HOÀN TIỀN</MenuItem>
              </Select1>
            </Box>
          </PerfectScrollbar>
        </LocalizationProvider>
      </CardContent>
    </Card>
    <Card {...rest}>
      <div style={{width: '80px', margin: 'auto'}}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => exportExcel()}
          style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '0', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px', border: '1px solid orange' }}
          >
          ⬇️
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onLookup}
          style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px', border: '1px solid orange' }}
          >
          {!isLoading ? '🔄' : ''}
        </Button>
      </div>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050, marginTop: '15px' }}>
          <Table>
            <TableHead>
              <TableRow style={{background: 'red'}}>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  #
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tên đơn
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mã yêu cầu
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Nhà mạng
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thuê bao
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Loại thuê bao
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Cần nạp
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Đã nạp
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Hủy nạp
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thất bại
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tồn
                </TableCell>
                <TableCell colSpan='2' style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Bội số
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Nhà mạng nạp
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Ngày tạo đơn
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Kênh yêu cầu
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Chiết khấu
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Đua
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Phí đua
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thực trả
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Chức năng
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Trạng thái
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {napmymobifonechitiet_items && napmymobifonechitiet_items.slice(page * limit, page * limit + limit).map((napmymobifonechitiet, index) => (
                <TableRow
                  hover
                  key={napmymobifonechitiet.id}
                  selected={selectedBlacklistIds.indexOf(napmymobifonechitiet.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifonechitiet.ten_don}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifonechitiet.id.substring(0, 3) + '***' + napmymobifonechitiet.id.substring(napmymobifonechitiet.id.length - 3)}
                      <Button style={{padding: 0, color: 'blue', fontSize: 20, width: '20px', minWidth: 0}} onClick={() => copyToClipboard(napmymobifonechitiet.id)}>
                        📋
                      </Button>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={napmymobifonechitiet.operator == 'MOBIFONE' ? 'green' : (napmymobifonechitiet.operator == 'VINAPHONE' ? 'blue' : 'red')}
                      variant="body1"
                    >
                      {napmymobifonechitiet.operator}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Button style={{padding: 0, color: 'blue', fontSize: 20, width: 'auto', minWidth: 0}} onClick={() => copyToClipboard(parseRealPhoneNumber(napmymobifonechitiet.thue_bao))}>
                        {parseRealPhoneNumber(napmymobifonechitiet.thue_bao)}
                      </Button>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={napmymobifonechitiet.number_type == 1 ? 'green' : 'blue'}
                      variant="body1"
                    >
                      {napmymobifonechitiet.number_type == 1 ? 'TRẢ TRƯỚC' : (napmymobifonechitiet.number_type == 2 ? 'TRẢ SAU' : '')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifonechitiet.can_nap).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifonechitiet.da_nap).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifonechitiet.huy_nap).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifonechitiet.that_bai).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifonechitiet.ton_nap).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {hienThiBoiSo(napmymobifonechitiet.boi_so)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {(napmymobifonechitiet.kenh_yeu_cau == 'napho' || napmymobifonechitiet.kenh_yeu_cau == 'mymobifone') && <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleEditBoiSo(napmymobifonechitiet.id)}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal' }}
                        >
                        ✎
                      </Button>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifonechitiet.nha_mang == 'khong_qua_api' && 'Không qua API'}
                      {napmymobifonechitiet.nha_mang == 'smart_vi' && 'TOP-UP'}
                      {napmymobifonechitiet.nha_mang == 'tkc' && 'TKC'}
                      {napmymobifonechitiet.nha_mang == 'dichvu' && "DV"}
                      {napmymobifonechitiet.nha_mang == 'bunca' && "BC"}
                      {napmymobifonechitiet.nha_mang == 'napho' && "Nạp hộ Mobifone"}
                      {napmymobifonechitiet.nha_mang == 'mymobifone' && "My Mobifone"}
                      {napmymobifonechitiet.nha_mang == 'gsmmobifone' && "GSM Mobifone"}
                      {napmymobifonechitiet.nha_mang == 'mobifone_gsm' && 'Mobifone GSM'}
                      {napmymobifonechitiet.nha_mang == 'mobifone_otp' && 'Mobifone (Nhập OTP)'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(napmymobifonechitiet.created_at).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifonechitiet.kenh_yeu_cau == 'khong_qua_api' && "Không qua API"}
                      {napmymobifonechitiet.kenh_yeu_cau == 'smart_vi' && "TOP-UP"}
                      {napmymobifonechitiet.kenh_yeu_cau == 'tkc' && "TKC"}
                      {napmymobifonechitiet.kenh_yeu_cau == 'dichvu' && "DV"}
                      {napmymobifonechitiet.kenh_yeu_cau == 'bunca' && "BC"}
                      {napmymobifonechitiet.kenh_yeu_cau == 'napho' && "Nạp hộ Mobifone"}
                      {napmymobifonechitiet.kenh_yeu_cau == 'mymobifone' && "My Mobifone"}
                      {napmymobifonechitiet.kenh_yeu_cau == 'gsmmobifone' && "GSM Mobifone"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifonechitiet.chiet_khau).format('0,0.0')}%
                    </Typography>
                  </TableCell>
                  <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        <span>{parseFloat(napmymobifonechitiet.dua) > 0 ? numeral(napmymobifonechitiet.dua).format('0,0.0') : numeral(napmymobifonechitiet.dua).format('0,0.0')}</span>
                      </Typography>
                      {parseFloat(napmymobifonechitiet.dua) + 1 <= parseFloat(napmymobifonechitiet.chiet_khau) && <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => xuLyChucNang('dua_don', napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: 0, backgroundColor: 'red' }}
                        >
                        +
                      </Button>}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifonechitiet.phi_dua).format('0,0.0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {parseFloat(napmymobifonechitiet.tien_sau_chiet_khau) > 0 ? numeral(napmymobifonechitiet.tien_sau_chiet_khau).format('0,0.0') : numeral(napmymobifonechitiet.can_nap).format('0,0.0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {napmymobifonechitiet.trang_thai != 'da_nap' && <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{width: '120px', color: (napmymobifonechitiet.trang_thai == 'da_nap' ? 'green' : (napmymobifonechitiet.trang_thai == 'da_huy' ? 'red' : 'black'))}}
                    >
                      {(napmymobifonechitiet.kenh_yeu_cau != 'mymobifone' || napmymobifonechitiet.kenh_yeu_cau == 'mymobifone' && !napmymobifonechitiet.is_expired) && <>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => xuLyChucNang('bat_dau_don', napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}
                          style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: 0, backgroundColor: 'green' }}
                          >
                          ▶
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => xuLyChucNang('tam_dung', napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}
                          style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '10px', backgroundColor: 'red' }}
                          >
                          ||
                        </Button>
                      </>}
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => xuLyChucNang('da_huy', napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '10px', backgroundColor: 'red' }}
                        >
                        X
                      </Button>
                    </Typography>}
                  </TableCell>
                  <TableCell style={{minWidth: '100px'}}>
                    {napmymobifonechitiet.trang_thai == 'cho_nap' && napmymobifonechitiet.kenh_yeu_cau == 'mymobifone' && napmymobifonechitiet.is_expired && <div style={{width: '100%'}}>
                      <Button
                        className='btnGuiOtp'
                        color="primary"
                        variant="contained"
                        onClick={(e) => guiOtp(napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}
                        style={{ height: 30, padding: 0, marginTop: 0, width: "50px", minWidth: '50px', marginLeft: '10px', backgroundColor: 'blue', fontSize: '10px', color: 'white' }}
                        >
                        Gửi OTP
                      </Button>
                      <TextField
                        className='txtNhapOtp'
                        name={`otp_${napmymobifonechitiet.id}`}
                        onChange={nhapOtpChange}
                        onKeyDown={(e) => nhapOtpKeyDown(e, napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}
                        value={otps[`otp_${napmymobifonechitiet.id}`] || ''}
                        placeholder="OTP"
                        variant="outlined"
                        style={{ height: 56, marginTop: 0, width: "50%", marginLeft: '10px' }}
                      />
                      <Button
                        className='btnXacNhanOtp'
                        color="primary"
                        variant="contained"
                        onClick={(e) => nhapOtp(napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}
                        style={{ height: 56, padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '10px', backgroundColor: 'transparent', fontSize: '20px' }}
                        >
                        ✅
                      </Button>
                    </div>}
                    {(napmymobifonechitiet.kenh_yeu_cau != 'mymobifone' || napmymobifonechitiet.kenh_yeu_cau == 'mymobifone' && !napmymobifonechitiet.is_expired || napmymobifonechitiet.kenh_yeu_cau == 'mymobifone' && napmymobifonechitiet.trang_thai != 'cho_nap') && <>
                      <div style={{width: '80%', display:'inline-block'}}>
                        {role.priority < 3 && <Select1 className='select_trang_thai' style={{ fontSize: "16px", width: "100%", marginBottom: '0', color: colorByStatus(napmymobifonechitiet.trang_thai) }} variant="outlined" value={napmymobifonechitiet.trang_thai} onChange={(e) => xuLyChucNang(e.target.value, napmymobifonechitiet.id, napmymobifonechitiet.napmymobifone_id)}>
                          <MenuItem value="-99"></MenuItem>
                          <MenuItem value="chay_tiep" style={{color: colorByStatus('chay_tiep')}}>CHẠY TIẾP</MenuItem>
                          <MenuItem value="tam_dung" style={{color: colorByStatus('tam_dung')}}>TẠM DỪNG</MenuItem>
                          <MenuItem value="da_huy" style={{color: colorByStatus('da_huy')}}>HỦY ĐƠN</MenuItem>
                          <MenuItem value="cho_nap" style={{color: colorByStatus('cho_nap')}}>CHỜ NẠP</MenuItem>
                          <MenuItem value="dang_nap" style={{color: colorByStatus('dang_nap')}}>ĐANG NẠP</MenuItem>
                          <MenuItem value="da_nap" style={{color: colorByStatus('da_nap')}}>ĐÃ NẠP</MenuItem>
                          <MenuItem value="that_bai" style={{color: colorByStatus('that_bai')}}>HOÀN TIỀN</MenuItem>
                        </Select1>}
                        {role.priority > 2 && <>
                          {napmymobifonechitiet.trang_thai == 'chay_tiep' && <span style={{color: colorByStatus('chay_tiep')}}>CHẠY TIẾP</span>}
                          {napmymobifonechitiet.trang_thai == 'tam_dung' && <span style={{color: colorByStatus('tam_dung')}}>TẠM DỪNG</span>}
                          {napmymobifonechitiet.trang_thai == 'da_huy' && <span style={{color: colorByStatus('da_huy')}}>HỦY ĐƠN</span>}
                          {napmymobifonechitiet.trang_thai == 'cho_nap' && <span style={{color: colorByStatus('cho_nap')}}>CHỜ NẠP</span>}
                          {napmymobifonechitiet.trang_thai == 'dang_nap' && <span style={{color: colorByStatus('dang_nap')}}>ĐANG NẠP</span>}
                          {napmymobifonechitiet.trang_thai == 'da_nap' && <span style={{color: colorByStatus('da_nap')}}>ĐÃ NẠP</span>}
                          {napmymobifonechitiet.trang_thai == 'that_bai' && <span style={{color: colorByStatus('that_bai')}}>HOÀN TIỀN</span>}
                        </>}
                      </div>
                      {napmymobifonechitiet.trang_thai == 'that_bai' && <div style={{display:'inline-block'}}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => hienThiLyDoHoanTien(napmymobifonechitiet.ly_do_hoan_tien)}
                          style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '10px', backgroundColor: 'transparent', fontSize: '20px' }}
                          >
                          📋
                        </Button>
                      </div>}
                    </>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <PerfectScrollbar>
        <div style={{margin: 15, minWidth: 1050}}>
          <span style={{marginRight: 15}}>Tổng cần nạp: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(napmymobifonechitiet_tong_can_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng đã nạp: <span style={{color: 'green', fontWeight: 'bold'}}>{numeral(napmymobifonechitiet_tong_da_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng hủy / thất bại: <span style={{color: 'red', fontWeight: 'bold'}}>{numeral(parseInt(napmymobifonechitiet_tong_huy_nap) + parseInt(napmymobifonechitiet_tong_that_bai)).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng tồn: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(napmymobifonechitiet_tong_ton_nap).format('0,0')}</span></span>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={napmymobifonechitiet_total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      {/* <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} /> */}
      <CreateEditDialogBoiSoChiTiet open={openEditBoiSo} handleClose={handleCloseEditBoiSo} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card></>
  );
};

export default NapMyMobifoneChiTietDetail;
