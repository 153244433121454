import { useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';
import { LstracuuAction } from 'src/actions/lstracuu.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { AppAction } from '../../actions/app.action';

const CustomerLookupBalance = (props) => {

  const dispatch = useDispatch()
  const { balance } = useSelector(state => state.lookup)

  const [values, setValues] = useState({
    phone: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    dispatch(AppAction.appLoading(true));
    let phone = values.phone.slice(-9);

    if (phone.trim() == '') {
      alert('Bạn phải nhập số điện thoại')
      dispatch(AppAction.appLoading(false));
      return;
    }

    dispatch(LstracuuAction.create({ so_dien_thoai: phone, noi_dung: `Tra cứu tài khoản chính:\n{\nisdn: ${phone}\n}` }));
    dispatch(LookupAction.lookupBalance(phone))
  }

  return (
    <form {...props}>
      <Card>
        <CardHeader
          title="#3 TRA CỨU TÀI KHOẢN CHÍNH"
          subheader="Không có giới hạn"
        />
        <Divider />
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              label="Số điện thoại"
              margin="normal"
              name="phone"
              onChange={handleChange}
              required
              value={values.phone}
              variant="outlined"
            />
            <Button
              color="primary"
              variant="contained"
              disabled={!values.phone}
              onClick={onLookup}
              style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
            >
              Tra cứu
            </Button>
          </Box>
        </CardContent>
        <Divider />
        {balance && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  Kết quả
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {balance.phone}
                </TableCell>
              </TableRow>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Tài khoản chính
                  </Typography>
                </TableCell>
                <TableCell>
                  {balance.balance}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>}
      </Card>
    </form>
  );
};

export default CustomerLookupBalance;
