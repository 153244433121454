import { AuthService } from 'src/services/auth.service';
import { deleteAllCookies } from 'src/utils/common';

import {
  APP_RESET,
  AppAction,
} from './app.action';
import {
  PROFILE_INFO_SUCCESS,
  PROFILE_PERMISSION_SUCCESS,
  PROFILE_ROLE_SUCCESS,
} from './profile.action';

export const AUTH_SIGNIN_SUCCESS = 'AUTH:SIGNIN_SUCCESS';
export const AUTH_SIGNIN_FAILD = 'AUTH:SIGNIN_FAILD';
export const AUTH_LOGOUT_SUCCESS = 'AUTH:LOGOUT_SUCCESS';

export const AuthAction = {
  login,
  logout
}


function login(navigate, payload) {
  return (dispatch) => {
    dispatch(AppAction.appLoading(true));
    AuthService.login(payload).then((rs) => {
      localStorage.setItem('accessToken', rs.data.access_token);
      dispatch(success(rs.data))
      dispatch(profileInfo(rs.data.user))
      dispatch(profileRole(rs.data.role))
      dispatch(profilePermissions(rs.data.permissions))
      navigate('/app/home', { replace: true });
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: AUTH_SIGNIN_SUCCESS, data } }
  function profileInfo(data) { return { type: PROFILE_INFO_SUCCESS, data } }
  function profileRole(data) { return { type: PROFILE_ROLE_SUCCESS, data } }
  function profilePermissions(data) { return { type: PROFILE_PERMISSION_SUCCESS, data } }
  function failure(error) { return { type: AUTH_SIGNIN_FAILD, error } }
}

function logout(navigate) {
  return (dispatch) => {
    dispatch({ type: APP_RESET });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('persist:root');
    deleteAllCookies()
    dispatch({ type: AUTH_LOGOUT_SUCCESS });
    navigate('/login', { replace: true });
  };

}


