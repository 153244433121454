import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { AccountAction } from 'src/actions/account.acction';
import { ProfileAction } from 'src/actions/profile.action';
import { RoleAction } from 'src/actions/role.acction';
import { SettingAction } from 'src/actions/setting.action';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    TableCell,
    Checkbox,
    Select,
    MenuItem,
} from '@material-ui/core';

const CreateEditDialogSelectSetting = (props) => {
    const dispatch = useDispatch()
    const { info, permissions, role } = useSelector(state => state.profile)
    const [moduleBanTien, batTatModuleBanTienValue] = useState({});

    const [nha_mang_dang_chon, setNhaMangDangChon] = useState("mobi")

    useEffect(() => {
        async function fetchSettingModuleBanTien() {
            const moduleBanTien_res = await dispatch(SettingAction.findByKey('bat_tat_module_ban_tien'))
            let moduleBanTien_value = JSON.parse(moduleBanTien_res.data.value);
            moduleBanTien_value = {
                topup: moduleBanTien_value.topup == '1' ? 1 : 2,
                tkc: moduleBanTien_value.tkc == '1' ? 1 : 2, 
                dv: moduleBanTien_value.dv == '1' ? 1 : 2,
                bc: moduleBanTien_value.bc == '1' ? 1 : 2,
                napho: moduleBanTien_value.napho == '1' ? 1 : 2,
                mymobifone: moduleBanTien_value.mymobifone == '1' ? 1 : 2,
                gsmmobifone: moduleBanTien_value.gsmmobifone == '1' ? 1 : 2,

                topup_vina: moduleBanTien_value.topup_vina == '1' ? 1 : 2,
                tkc_vina: moduleBanTien_value.tkc_vina == '1' ? 1 : 2, 
                dv_vina: moduleBanTien_value.dv_vina == '1' ? 1 : 2,
                bc_vina: moduleBanTien_value.bc_vina == '1' ? 1 : 2,

                topup_viettel: moduleBanTien_value.topup_viettel == '1' ? 1 : 2,
                tkc_viettel: moduleBanTien_value.tkc_viettel == '1' ? 1 : 2, 
                dv_viettel: moduleBanTien_value.dv_viettel == '1' ? 1 : 2,
                bc_viettel: moduleBanTien_value.bc_viettel == '1' ? 1 : 2,
            }
            batTatModuleBanTienValue(moduleBanTien_value)
        }
        fetchSettingModuleBanTien();
    }, [])

    useEffect(() => {
        resetData()
    }, [])

    const [values, setValues] = useState({
        tang_giam_chiet_khau_topup: '0',
        tang_giam_chiet_khau_tkc: '0',
        tang_giam_chiet_khau_dv: '0',
        tang_giam_chiet_khau_bc: '0',
        tang_giam_chiet_khau_napho: '0',
        tang_giam_chiet_khau_mymobifone: '0',
        tang_giam_chiet_khau_gsmmobifone: '0',

        tang_giam_chiet_khau_topup_vina: '0',
        tang_giam_chiet_khau_tkc_vina: '0',
        tang_giam_chiet_khau_dv_vina: '0',
        tang_giam_chiet_khau_bc_vina: '0',
        tang_giam_chiet_khau_napho_vina: '0',
        tang_giam_chiet_khau_mymobifone_vina: '0',
        tang_giam_chiet_khau_gsmmobifone_vina: '0',

        tang_giam_chiet_khau_topup_viettel: '0',
        tang_giam_chiet_khau_tkc_viettel: '0',
        tang_giam_chiet_khau_dv_viettel: '0',
        tang_giam_chiet_khau_bc_viettel: '0',
        tang_giam_chiet_khau_napho_viettel: '0',
        tang_giam_chiet_khau_mymobifone_viettel: '0',
        tang_giam_chiet_khau_gsmmobifone_viettel: '0',
    });

    const handleChange = (event) => {
        if (event.target.name == 'nha_mang_dang_chon') {
            setNhaMangDangChon(event.target.value);
            return;
        }
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const resetData = () => {
        setValues({
            tang_giam_chiet_khau_topup: '0',
            tang_giam_chiet_khau_tkc: '0',
            tang_giam_chiet_khau_dv: '0',
            tang_giam_chiet_khau_bc: '0',
            tang_giam_chiet_khau_napho: '0',
            tang_giam_chiet_khau_mymobifone: '0',
            tang_giam_chiet_khau_gsmmobifone: '0',

            tang_giam_chiet_khau_topup_vina: '0',
            tang_giam_chiet_khau_tkc_vina: '0',
            tang_giam_chiet_khau_dv_vina: '0',
            tang_giam_chiet_khau_bc_vina: '0',
            tang_giam_chiet_khau_napho_vina: '0',
            tang_giam_chiet_khau_mymobifone_vina: '0',
            tang_giam_chiet_khau_gsmmobifone_vina: '0',

            tang_giam_chiet_khau_topup_viettel: '0',
            tang_giam_chiet_khau_tkc_viettel: '0',
            tang_giam_chiet_khau_dv_viettel: '0',
            tang_giam_chiet_khau_bc_viettel: '0',
            tang_giam_chiet_khau_napho_viettel: '0',
            tang_giam_chiet_khau_mymobifone_viettel: '0',
            tang_giam_chiet_khau_gsmmobifone_viettel: '0',
        })
    }

    const handleSaveSetting = async () => {
        await dispatch(AccountAction.tangGiamChietKhau(values))
        await dispatch(AccountAction.batTatModuleBanTien(moduleBanTien));
        setNhaMangDangChon('mobi');
        resetData();
        props.handleClose();
    }

    const batTatModuleBanTien = (e, value) => {
        batTatModuleBanTienValue({
            ...moduleBanTien,
            [value]: e.target.value == 1 ? 2 : 1
        })
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cài đặt</DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <h3 style={{ color: 'blue' }}>Tăng/Giảm chiết khấu đồng loạt</h3>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Select
                            style={{ height: 56, width: "100%", marginLeft: 0 }}
                            name="nha_mang_dang_chon"
                            value={nha_mang_dang_chon || 'mobi'}
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value="mobi">MOBIFONE</MenuItem>
                            <MenuItem value="vina">VINAPHONE</MenuItem>
                            <MenuItem value="viettel">VIETTEL</MenuItem>
                        </Select>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="TOP-UP"
                            name="tang_giam_chiet_khau_topup"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_topup}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="TOP-UP"
                            name="tang_giam_chiet_khau_topup_vina"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_topup_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="TOP-UP"
                            name="tang_giam_chiet_khau_topup_viettel"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_topup_viettel}
                            variant="outlined"
                        />}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="TKC"
                            name="tang_giam_chiet_khau_tkc"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_tkc}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="TKC"
                            name="tang_giam_chiet_khau_tkc_vina"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_tkc_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="TKC"
                            name="tang_giam_chiet_khau_tkc_viettel"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_tkc_viettel}
                            variant="outlined"
                        />}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="DV"
                            name="tang_giam_chiet_khau_dv"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_dv}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="DV"
                            name="tang_giam_chiet_khau_dv_vina"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_dv_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="DV"
                            name="tang_giam_chiet_khau_dv_viettel"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_dv_viettel}
                            variant="outlined"
                        />}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="BC"
                            name="tang_giam_chiet_khau_bc"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_bc}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="BC"
                            name="tang_giam_chiet_khau_bc_vina"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_bc_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="BC"
                            name="tang_giam_chiet_khau_bc_viettel"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_bc_viettel}
                            variant="outlined"
                        />}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="Nạp hộ Mobifone"
                            name="tang_giam_chiet_khau_napho"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_napho}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="Nạp hộ Mobifone"
                            name="tang_giam_chiet_khau_napho_vina"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_napho_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="Nạp hộ Mobifone"
                            name="tang_giam_chiet_khau_napho_viettel"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_napho_viettel}
                            variant="outlined"
                        />}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="My Mobifone"
                            name="tang_giam_chiet_khau_mymobifone"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_mymobifone}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="My Mobifone"
                            name="tang_giam_chiet_khau_mymobifone_vina"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_mymobifone_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="My Mobifone"
                            name="tang_giam_chiet_khau_mymobifone_viettel"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_mymobifone_viettel}
                            variant="outlined"
                        />}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="GSM Mobifone"
                            name="tang_giam_chiet_khau_gsmmobifone"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_gsmmobifone}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="GSM Mobifone"
                            name="tang_giam_chiet_khau_gsmmobifone_vina"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_gsmmobifone_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="GSM Mobifone"
                            name="tang_giam_chiet_khau_gsmmobifone_viettel"
                            onChange={handleChange}
                            required
                            value={values.tang_giam_chiet_khau_gsmmobifone_viettel}
                            variant="outlined"
                        />}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={handleSaveSetting}>OK</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialogSelectSetting;