import { useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';
import { LstracuuAction } from 'src/actions/lstracuu.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { AppAction } from '../../actions/app.action';

import React from 'react';

import moment from 'moment';

import MainSocket from 'src/components/MainSocket.js'

const CustomerLookupDTCT = (props) => {

  const dispatch = useDispatch()
  const { balance } = useSelector(state => state.lookup)
  const [values, setValues] = useState({
    phone: '',
    package_code: '',
    page: '',
    maxPage: '',
  });
  const [data, setData] = useState([]);
  const { socket, socket_client_id } = MainSocket(useSelector(state => state));

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

//   const onLookup = () => {
//     dispatch(LookupAction.lookupBalance(values.phone))
//   }

const getFromPage = (page) => {
    dispatch(AppAction.appLoading(true));
    setValues({
        ...values,
        page: page,
    });

    var phone = (values.phone).substring((values.phone).length - 9)
    var package_code = values.package_code ? values.package_code : '';

    if (phone.trim() == '') {
      alert('Bạn phải nhập số điện thoại')
      dispatch(AppAction.appLoading(false));
      return;
    }

    console.log(phone, package_code, page)

    if (typeof values.socketSend == 'undefined' || values.socketSend == null) {
      dispatch(LstracuuAction.create({ so_dien_thoai: phone, noi_dung: `Tra cứu danh sách đối tượng chương trình:\n{\nisdn: ${phone},\npackage_code: ${package_code},\npage: ${page}\n}` }));

      values.socketSend = "traCuuDanhSachDoiTuongChuongTrinh";
      delete values.socketReceive;

      // socket.emit("traCuuDanhSachDoiTuongChuongTrinh", {client_id: socket_client_id, data: {
      //   isdn: phone,
      //   package_code: package_code,
      //   page: page,
      // }})
    }
}

const onLookup = () => {
    setValues({
        ...values,
        page: 1,
    });
    getFromPage(1)
  }

  // socket.on("traKetQuaTraCuuDanhSachDoiTuongChuongTrinh", (data) => {
  //   if (typeof values.socketReceive == 'undefined' || values.socketReceive == null) {
  //     values.socketReceive = "traKetQuaTraCuuDanhSachDoiTuongChuongTrinh";
  //     delete values.socketSend;

  //     data = data.data;
  //     if (data && typeof data.NUMBER_PHONE !== 'undefined' && values && typeof values.phone !== 'undefined' && data.NUMBER_PHONE.slice(-9) == values.phone.slice(-9)) {
  //         console.log(data);
  //         setData(data);
  //         setValues({
  //             ...values,
  //             maxPage: typeof data.result !== 'undefined' && data.result && typeof data.result.PaginateNumber !== 'undefined' && data.result.PaginateNumber && data.result.PaginateNumber > 0 ? data.result.PaginateNumber : values.maxPage,
  //         });
  //         // if (data.result === null) onLookup();
  //         // else setValues({
  //         //     phone: '',
  //         //     package_code: '',
  //         //     page: '',
  //         // })
  //         dispatch(AppAction.appLoading(false));
  //     }
  //   }
  // })

  return (
    <form {...props}>
      <Card>
        <CardHeader
          title="#2 TRA CỨU GÓI CƯỚC CÓ THỂ ĐĂNG KÝ"
          subheader="Không có giới hạn"
        />
        <Divider />
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              label="Số điện thoại"
              margin="normal"
              name="phone"
              onChange={handleChange}
              required
              value={values.phone}
              variant="outlined"
            />
            <TextField
              fullWidth
              style={{marginLeft: '15px'}}
              label="Mã gói cước"
              margin="normal"
              name="package_code"
              onChange={handleChange}
              value={values.package_code}
              variant="outlined"
            />
            <Button
              color="primary"
              variant="contained"
              disabled={!values.phone}
              onClick={onLookup}
              style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
            >
              Tra cứu
            </Button>
          </Box>
        </CardContent>
        <Divider />
        {data && typeof data.NUMBER_PHONE !== 'undefined' && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  Kết quả
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell colSpan={5}>
                    Các gói ưu đãi dành cho thuê bao {data.NUMBER_PHONE}
                </TableCell>
              </TableRow>

                <TableRow
                  hover
                >
                  <TableCell style={{fontWeight: 'bold'}}>
                    Mã gói cước
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Từ ngày
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Đến ngày
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Cú pháp ĐK
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Hạn ĐKKM
                  </TableCell>
                </TableRow>

              {data && typeof data.result !== 'undefined' && data.result && typeof data.result.FinalDataList !== 'undefined' && data.result.FinalDataList && (data.result.FinalDataList).map((pkg) => (
                <><TableRow
                  hover
                >
                  <TableCell>
                    {pkg.PackageCode}
                  </TableCell>
                  <TableCell>
                    {pkg.FromDate != "" ? moment(pkg.FromDate, "DD-MM-YYYY").format("DD-MM-YYYY") : null}
                  </TableCell>
                  <TableCell>
                    {pkg.ToDate != "" ? moment(pkg.ToDate, "DD-MM-YYYY").format("DD-MM-YYYY") : null}
                  </TableCell>
                  <TableCell>
                    {pkg.SyntaxRegister}
                  </TableCell>
                  <TableCell style={{color: 'red'}}>
                    {pkg.RegisterExpire != "" ? moment(pkg.RegisterExpire, "DD/MM/YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss") : null}
                  </TableCell>
                </TableRow></>
              ))}
                <TableRow
                    hover
                >
                    <TableCell colSpan={5}>
                        {values.maxPage && Array.from({ length: values.maxPage - 1 }).map((_, index) => (
                            <React.Fragment key={index}>
                            {values.page !== index + 1 && (
                                <Button onClick={() => getFromPage(index + 1)}>
                                {index + 1}
                                </Button>
                            )}
                            {values.page === index + 1 && (
                                <Button disabled="true" style={{color: 'black'}}>
                                {index + 1}
                                </Button>
                            )}
                            </React.Fragment>
                        ))}
                    </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Box>}
      </Card>
    </form>
  );
};

export default CustomerLookupDTCT;
