import { Helmet } from 'react-helmet';
import MobifoneDanhSachDonChiTietDetail
  from 'src/components/MobifoneDanhSachDonChiTiet-detail/MobifoneDanhSachDonChiTietDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

//import MobifoneDanhSachDonChiTietToolbar from '../components/MobifoneDanhSachDonChiTiet-detail/MobifoneDanhSachDonChiTietToolbar';

const MobifoneDanhSachDonChiTiet = () => (
  <>
    <Helmet>
      <title>Bắn tiền - Danh sách đơn chi tiết</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        {/* <MobifoneDanhSachDonChiTietToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <MobifoneDanhSachDonChiTietDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default MobifoneDanhSachDonChiTiet;
