import {
    useEffect,
    useState,
    useRef,
} from 'react';
import {
    Edit3 as Edit3Icon,
    Lock,
    RefreshCcw,
    Trash2 as Trash2Icon,
    Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { CauhinhgsmAction } from 'src/actions/cauhinhgsm.action';
import {
    CONFIRM_TYPES,
    DEFAULT_ROW_PER_PAGE,
    ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getBlacklistStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
    Box,
    Card,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    IconButton,
    Button,
    MenuItem,
    Select as Select1,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Menu,
} from '@material-ui/core';

import SuaNhieuMucDialog from '../cauhinhgsmcomlist-detail/SuaNhieuMucDialog';

import { showToast } from 'src/utils/common';

import { useParams } from 'react-router-dom';

import numeral from 'numeral';

import { Search as SearchIcon } from 'react-feather';

import {
    DatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as XLSX from 'xlsx';
import { AppAction } from 'src/actions/app.action';
import { saveAs } from 'file-saver';
import CreateEditDialogBoiSoChiTiet from '../MobifoneDanhSachDon-detail/CreateEditDialogBoiSoChiTiet';

import { Cached } from '@material-ui/icons';

const CauhinhgsmcomlistDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedBlacklistIds, setSelectedBlacklistIds] = useState([]);
    //const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
    const [limit, setLimit] = useState(999);
    const [page, setPage] = useState(0);
    const { filter, cauhinhgsmcomlist_items, cauhinhgsmcomlist_total } = useSelector(state => state.cauhinhgsm);
    const [confirm, setConfirm] = useState(false);
    const [confirmType, setConfirmType] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [selected, setSelected] = useState({});
    const [open, setOpen] = useState(false);
    // const { id } = useParams();
    const [id, setId] = useState(undefined);
    const { info, permissions, role } = useSelector(state => state.profile)
    const [openEditBoiSo, setOpenEditBoiSo] = useState(false);
    const [item, setItemValue] = useState({});
    const [itemCheckAll, setItemCheckAll] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [openSuaNhieuMuc, setOpenSuaNhieuMuc] = useState(false);
    const [yeuCau, setYeuCau] = useState("");
    const [mucDaChon, setMucDaChon] = useState([]);
    const [values, setValues] = useState({
        ma_tram: "",
        trang_thai: "-99",
    });

    const [intervalId, setInternvalId] = useState(0)
    const [refreshInterval, setRefreshInternval] = useState(null)
    const refreshIntervalRef = useRef(refreshInterval)
    const { isLoading } = useSelector(state => state.app);

    useEffect(() => {
        if (typeof props.id_don_tong !== 'undefined' && props.id_don_tong) {
            console.log('setId', props.id_don_tong);
            setId(props.id_don_tong)
        } else {
            console.log('setId', undefined);
            setId(undefined);
        }

        const payload = {
            ...filter,
            page: page,
            limit: limit,
            ma_tram: values.ma_tram,
            trang_thai: values.trang_thai,
        };

        console.log(cauhinhgsmcomlist_items, cauhinhgsmcomlist_total)
        dispatch(CauhinhgsmAction.updateFilter(payload));
        if (typeof props.id_don_tong !== 'undefined' && props.id_don_tong) {
            dispatch(CauhinhgsmAction.findAllByOrder(props.id_don_tong));
        }
    }, [props.id_don_tong, page, limit])

    useEffect(() => {
        console.log("updateRefreshInterval")
        refreshIntervalRef.current = refreshInterval
    }, [refreshInterval])

    useEffect(() => {
        //khi mở dialog
        if ("open" in props && props.open && typeof id == "string" && refreshInterval == null) {
            const interval = setInterval(() => {
                if (refreshIntervalRef.current == interval) {
                    console.log("test", props.id_don_tong);
                    if (typeof props.id_don_tong !== 'undefined' && props.id_don_tong) {
                        dispatch(CauhinhgsmAction.findAllByOrder(props.id_don_tong, false, true));
                    }
                }
            }, 1e4)

            setRefreshInternval(interval)
        }
    }, [id])

    const onLookup = (immediatelyPayload = {}) => {
        const payload = {
            page: 'no_update',
            limit: 'no_update',
            ma_tram: typeof immediatelyPayload.ma_tram !== 'undefined' ? immediatelyPayload.ma_tram : values.ma_tram,
      trang_thai: typeof immediatelyPayload.trang_thai !== 'undefined' ? immediatelyPayload.trang_thai : values.trang_thai,
        };
        dispatch(CauhinhgsmAction.updateFilter(payload));
        dispatch(CauhinhgsmAction.findAllByOrder(id))
    };

    const exportExcel = async () => {
        dispatch(AppAction.appLoading(true))
        try {
            const payload = {
                page: 0,
                limit: 9999999999,
                username: values.username,
                phone: values.phone,
                ma_don: id,
                kenh_yeu_cau: values.kenh_yeu_cau,
                trang_thai: values.trang_thai,
            };
            dispatch(CauhinhgsmAction.updateFilter(payload));
            const response = await dispatch(CauhinhgsmAction.listExport());

            const data = [
                [
                    'Mã đơn',
                    'Mã yêu cầu',
                    'Username lên đơn',
                    'Tên đơn',
                    'Nhà mạng',
                    'Thuê bao',
                    'Loại thuê bao',
                    'Cần nạp',
                    'Đã nạp',
                    'Hủy nạp',
                    'Thất bại',
                    'Tồn',
                    'Phí đua',
                    'Bội số',
                    'Nhà mạng',
                    'Ngày tạo đơn',
                    'Kênh yêu cầu',
                    'Trạng thái',
                ],
            ];

            if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
                var count = 1;
                response.data.values.forEach((element) => {
                    let loaiThueBao = '';
                    let boiSo = '';
                    let nhaMang = '';
                    let kenhYeuCau = ''
                    let trangThai = ''

                    if (element.number_type == 1) loaiThueBao = 'TRẢ TRƯỚC';
                    else if (element.number_type == 2) loaiThueBao = 'TRẢ SAU';

                    if (element.boi_so == 'menh_gia_bat_ky') boiSo = 'Mệnh giá bất kỳ';
                    else if (element.boi_so == '10000') boiSo = '10.000';
                    else if (element.boi_so == '20000') boiSo = '20.000';
                    else if (element.boi_so == 'the_nho_nho_hon_50000') boiSo = 'Thẻ nhỏ (<50k)';
                    else if (element.boi_so == '50000') boiSo = '50.000';
                    else if (element.boi_so == '100000') boiSo = '100.000';
                    else if (element.boi_so == '200000') boiSo = '200.000';
                    else if (element.boi_so == '300000') boiSo = '300.000';
                    else if (element.boi_so == '500000') boiSo = '500.000';
                    else if (element.boi_so == '1000000') boiSo = '1.000.000';
                    else boiSo = element.boi_so;

                    if (element.nha_mang == 'khong_qua_api') nhaMang = 'Không qua API';
                    else if (element.nha_mang == 'smart_vi') nhaMang = 'TOP-UP';
                    else if (element.nha_mang == 'tkc') nhaMang = 'TKC';
                    else if (element.nha_mang == 'bunca') nhaMang = 'BC';
                    else if (element.nha_mang == 'dichvu') nhaMang = 'DV';
                    else if (element.nha_mang == 'napho') nhaMang = 'Nạp hộ Mobifone';
                    else if (element.nha_mang == 'mymobifone') nhaMang = 'My Mobifone';
                    else if (element.nha_mang == 'gsmmobifone') nhaMang = 'GSM Mobifone';
                    else if (element.nha_mang == 'mobifone_gsm') nhaMang = 'Mobifone GSM';
                    else if (element.nha_mang == 'mobifone_otp') nhaMang = 'Mobifone (Nhập OTP)';

                    if (element.kenh_yeu_cau == 'khong_qua_api') kenhYeuCau = 'Không qua API';
                    else if (element.kenh_yeu_cau == 'smart_vi') kenhYeuCau = 'TOP-UP';
                    else if (element.kenh_yeu_cau == 'tkc') kenhYeuCau = 'TKC';
                    else if (element.kenh_yeu_cau == 'bunca') kenhYeuCau = 'BC';
                    else if (element.kenh_yeu_cau == 'dichvu') kenhYeuCau = 'DV';
                    else if (element.kenh_yeu_cau == 'napho') kenhYeuCau = 'Nạp hộ Mobifone';
                    else if (element.kenh_yeu_cau == 'mymobifone') kenhYeuCau = 'My Mobifone';
                    else if (element.kenh_yeu_cau == 'gsmmobifone') kenhYeuCau = 'GSM Mobifone';

                    if (element.trang_thai == 'chay_tiep') trangThai = 'CHẠY TIẾP';
                    else if (element.trang_thai == 'tam_dung') trangThai = 'TẠM DỪNG';
                    else if (element.trang_thai == 'da_huy') trangThai = 'HỦY ĐƠN';
                    else if (element.trang_thai == 'cho_nap') trangThai = 'CHỜ NẠP';
                    else if (element.trang_thai == 'dang_nap') trangThai = 'ĐANG NẠP';
                    else if (element.trang_thai == 'da_nap') trangThai = 'ĐÃ NẠP';
                    else if (element.trang_thai == 'that_bai') trangThai = 'HOÀN TIỀN';

                    data.push([
                        element.ma_don,
                        element.ma_yeu_cau,
                        element.username_len_don,
                        element.ten_don,
                        element.operator,
                        parseRealPhoneNumber(element.thue_bao),
                        loaiThueBao,
                        element.can_nap,
                        element.da_nap,
                        element.huy_nap,
                        element.that_bai,
                        element.ton_nap,
                        element.phi_dua,
                        boiSo,
                        nhaMang,
                        moment(element.created_at).format('DD-MM-YYYY HH:mm:ss'),
                        kenhYeuCau,
                        trangThai,
                    ])
                    count++;
                })

                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.aoa_to_sheet(data);
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'Danh-sach-don-nap-tien.xlsx');
            }

            dispatch(AppAction.appLoading(false))
        } catch (error) {
            if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
        }
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onLookup();
        }
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

        onLookup({[event.target.name]: event.target.value});
    };

    const handleSelectAll = (event) => {
        let newSelectedBlacklistIds;

        if (event.target.checked) {
            newSelectedBlacklistIds = cauhinhgsmcomlist_items.map((cauhinhgsmcomlist) => cauhinhgsmcomlist.id);
        } else {
            newSelectedBlacklistIds = [];
        }

        setSelectedBlacklistIds(newSelectedBlacklistIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedBlacklistIds.indexOf(id);
        let newSelectedBlacklistIds = [];

        if (selectedIndex === -1) {
            newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds, id);
        } else if (selectedIndex === 0) {
            newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(1));
        } else if (selectedIndex === selectedBlacklistIds.length - 1) {
            newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedBlacklistIds = newSelectedBlacklistIds.concat(
                selectedBlacklistIds.slice(0, selectedIndex),
                selectedBlacklistIds.slice(selectedIndex + 1)
            );
        }

        setSelectedBlacklistIds(newSelectedBlacklistIds);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleConfirmOpen = (type, cauhinhgsmcomlist) => {
        setSelected(cauhinhgsmcomlist);
        setConfirmType(type);
        if (confirmType === CONFIRM_TYPES.DELETE) {
            setTitle("Xoá bản ghi")
            setContent(`Xác nhận xoá bản ghi `)
        }
        setConfirm(true);
    };

    const onConfirm = () => {
        setConfirm(false);
        if (confirmType === CONFIRM_TYPES.DELETE) {
            dispatch(CauhinhgsmAction.del(selected.id))
            return;
        }
    }

    const handleConfirmClose = () => {
        setConfirm(false);
    };

    const onSelected = (id) => {
        dispatch(CauhinhgsmAction.findOne(id));
        setOpen(true);
    };

    const handleClose = () => {
        console.log("close with interval", refreshInterval, props)

        if (refreshInterval != null) {
            console.log("closing")
            console.log(refreshInterval)
            clearInterval(refreshInterval)
            setRefreshInternval(null)
        }
    };

    const chayTiep = async (id, id_don_tong) => {
        let question = "Bạn có muốn chạy tiếp đơn?";
        if (window.confirm(question)) {
            try {
                // showToast('success', `Chạy tiếp đơn '${id}'`);
                dispatch(CauhinhgsmAction.chayTiepChiTiet(id, id_don_tong));
            } catch (error) {

            }
        } else {

        }
    };

    const daNap = async (id, id_don_tong) => {
        let question = `Bạn có muốn chuyển trạng thái đơn này sang ĐÃ NẠP?`;
        if (window.confirm(question)) {
            try {
                // showToast('success', `Đổi trạng thái đơn '${id}' sang ĐÃ NẠP`);
                dispatch(CauhinhgsmAction.daNapChiTiet(id, id_don_tong));
            } catch (error) {

            }
        } else {

        }
    };

    const huyDon = async (id, id_don_tong) => {
        let question = "Bạn có muốn hủy đơn này?";
        if (window.confirm(question)) {
            try {
                // showToast('success', `Đang hủy đơn '${id}'`);
                dispatch(CauhinhgsmAction.huyDonChiTiet(id, id_don_tong));
            } catch (error) {

            }
        } else {

        }
    };

    const tamDung = async (id, id_don_tong) => {
        let question = "Bạn có muốn tạm dừng đơn này?";
        if (window.confirm(question)) {
            try {
                // showToast('success', `Đã tạm dừng đơn '${id}'`);
                dispatch(CauhinhgsmAction.tamDungChiTiet(id, id_don_tong));
            } catch (error) {

            }
        } else {

        }
    };

    const choNap = async (id, id_don_tong) => {
        let question = "Bạn có muốn đổi trạng thái đơn này sang CHỜ NẠP?";
        if (window.confirm(question)) {
            try {
                // showToast('success', `Đổi trạng thái đơn '${id}' sang CHỜ NẠP`);
                dispatch(CauhinhgsmAction.choNapChiTiet(id, id_don_tong));
            } catch (error) {

            }
        } else {

        }
    };

    const dangNap = async (id, id_don_tong) => {
        let question = "Bạn có muốn đổi trạng thái đơn này sang ĐANG NẠP?";
        if (window.confirm(question)) {
            try {
                // showToast('success', `Đổi trạng thái đơn '${id}' sang ĐANG NẠP`);
                dispatch(CauhinhgsmAction.dangNapChiTiet(id, id_don_tong));
            } catch (error) {

            }
        } else {

        }
    };

    const thatBai = async (id, id_don_tong) => {
        let question = "Bạn có muốn đổi trạng thái đơn này sang HOÀN TIỀN?";
        if (window.confirm(question)) {
            try {
                // showToast('success', `Đổi trạng thái đơn '${id}' sang HOÀN TIỀN`);
                dispatch(CauhinhgsmAction.thatBaiChiTiet(id, id_don_tong));
            } catch (error) {

            }
        } else {

        }
    };

    const batDauDon = async (id, id_don_tong) => {
        dispatch(CauhinhgsmAction.batDauDonChiTiet(id, id_don_tong));
    };

    const duaDon = async (id, id_don_tong) => {
        dispatch(AppAction.appLoading(true))
        const res = await dispatch(CauhinhgsmAction.duaDonChiTiet(id, id_don_tong));
        if (res.data.result) {
            if (res.data.result == 'success') {
                // showToast('success', res.data.msg);
            } else {
                // showToast('failed', res.data.msg);
            }
            dispatch(AppAction.appLoading(true))
        }
    };

    const parseRealPhoneNumber = (msisdn) => {
        let phone = msisdn.slice(-9);
        let firstChar = phone.substring(0, 1);
        if (firstChar == '2') {
            return `1${phone}`;
        }
        return phone;
    }

    const hienThiLyDoHoanTien = (lyDo) => {
        alert(`Lý do hoàn tiền:\n${lyDo ? lyDo : ''}`);
    }

    const handleEditBoiSo = (id) => {
        dispatch(CauhinhgsmAction.findOneChiTietDon(id));
        setOpenEditBoiSo(true);
    }

    const handleCloseEditBoiSo = () => {
        setOpenEditBoiSo(false);
    }

    const hienThiBoiSo = (boi_so) => {
        let boiSo = '';
        if (boi_so == 'menh_gia_bat_ky') boiSo = 'Mệnh giá bất kỳ';
        else if (boi_so == '10000') boiSo = '10.000';
        else if (boi_so == '20000') boiSo = '20.000';
        else if (boi_so == 'the_nho_nho_hon_50000') boiSo = 'Thẻ nhỏ (<50k)';
        else if (boi_so == '50000') boiSo = '50.000';
        else if (boi_so == '100000') boiSo = '100.000';
        else if (boi_so == '200000') boiSo = '200.000';
        else if (boi_so == '300000') boiSo = '300.000';
        else if (boi_so == '500000') boiSo = '500.000';
        else if (boi_so == '1000000') boiSo = '1.000.000';
        else boiSo = boi_so;
        return boiSo;
    }

    const xuLyChucNang = async (value, id, id_don_tong) => {
        if (value == 'chay_tiep') await chayTiep(id, id_don_tong);
        else if (value == 'tam_dung') await tamDung(id, id_don_tong);
        else if (value == 'da_nap') await daNap(id, id_don_tong);
        else if (value == 'da_huy') await huyDon(id, id_don_tong);
        else if (value == 'cho_nap') await choNap(id, id_don_tong);
        else if (value == 'dang_nap') await dangNap(id, id_don_tong);
        else if (value == 'that_bai') await thatBai(id, id_don_tong);
        else if (value == 'dua_don') await duaDon(id, id_don_tong);
        else if (value == 'bat_dau_don') await batDauDon(id, id_don_tong);
    };

    const colorByStatus = (status) => {
        if (status == "chay_tiep") return '#1338be';
        else if (status == "tam_dung") return 'orange';
        else if (status == "da_huy") return 'red';
        else if (status == "cho_nap") return '#65350f';
        else if (status == "dang_nap") return '#ff0099';
        else if (status == "da_nap") return 'green';
        else if (status == "that_bai") return 'red';
        else return '';
    }

    const batTatLoaiDichVu = (event, id, id_don_tong, yeu_cau) => {
        console.log(id, yeu_cau);
        dispatch(CauhinhgsmAction.batTatLoaiDichVuChiTiet(id, id_don_tong, { yeu_cau }));
    }

    const setItem = (e, value) => {
        setItemValue({
            ...item,
            [value]: e.target.value == 1 ? 0 : 1
        });
    }

    const setItemCheckAllOnChange = (event) => {
        console.log(event.target.checked);

        if (event.target.checked) {
            setItemCheckAll(1);
            chonItem(event);
        } else {
            setItemCheckAll(0);
            boChonItem(event);
        }
    };

    const chonItem = (event) => {
        // if (item && Object.entries(item).length > 0) {
        //   let newItems = {}
        //   Object.entries(item).forEach(([key, value]) => {
        //     newItems[key] = 1;
        //   });
        //   setItemValue(newItems);
        // }

        const items = cauhinhgsmcomlist_items;

        if (items && items.length > 0) {
            let newItems = {}
            for (let i = 0; i < limit; i++) {
                const key = page * limit + i;
                const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
                newItems[id] = 1;
            }
            setItemValue(newItems);
        }
    };

    const boChonItem = (event) => {
        // if (item && Object.entries(item).length > 0) {
        //   let newItems = {}
        //   Object.entries(item).forEach(([key, value]) => {
        //     newItems[key] = 0;
        //   });
        //   setItemValue(newItems);
        // }

        const items = cauhinhgsmcomlist_items;

        if (items && items.length > 0) {
            let newItems = {}
            for (let i = 0; i < limit; i++) {
                const key = page * limit + i;
                const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
                newItems[id] = 0;
            }
            setItemValue(newItems);
        }
    };

    const handleEdit = () => {
        const selectedItems = extractedSelectedItems()
        console.log(selectedItems)
        if (selectedItems.length < 1) {
            alert('Chưa chọn mục nào để sửa')
        } else {
            try {
                let selectedItem = selectedItems[0];
                setItemValue({ [selectedItem]: 1 })
                setItemCheckAll(false);
                dispatch(CauhinhgsmAction.findOne(selectedItem));
                setIsUpdate(true)
                setOpen(true)
            } catch (error) {
                //
            }
        }
    }

    const extractedSelectedItems = () => {
        const extractedObj = {};

        // Iterate over the object's keys
        for (const key in item) {
            // Check if key is -1 or value is 0, then copy the property to extractedObj
            if (key != '-1' && item[key] != 0) {
                extractedObj[key] = item[key];
            }
        }

        return Object.keys(extractedObj);
    }

    const handleSuaPortNhieuMuc = (event) => {
        const selectedItems = extractedSelectedItems()
        console.log(selectedItems)
        if (selectedItems.length < 1) {
            alert('Chưa chọn mục nào để sửa')
        } else {
            try {
                const yeuCauXuLy = event.target.value.trim();
                setYeuCau(yeuCauXuLy);
                setMucDaChon(selectedItems);
                if (yeuCauXuLy == 'mo' || yeuCauXuLy == 'dong') {
                    dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, { yeuCauXuLy: 'refresh_khi_mo_cong', selectedItems, data: {} }))
                    dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, { yeuCauXuLy, selectedItems, data: {} }))
                    // showToast('success', `Cập nhật thành công`);
                } else {
                    setOpenSuaNhieuMuc(true);
                }
            } catch (error) {
                //
            }
        }
    }

    const handleDelete = () => {
        const selectedItems = extractedSelectedItems()
        console.log(selectedItems)
        if (selectedItems.length < 1) {
            alert('Chưa chọn mục nào để sửa')
        } else {
            try {
                dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, { yeuCauXuLy: 'dong', selectedItems, data: {} }))
                // showToast('success', `Đã xử lý`);
            } catch (error) {
                //
            }
        }
    }

    const refreshPort = (cauhinhgsmcomlist_id) => {
        dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, { yeuCauXuLy: 'refresh', selectedItems: [cauhinhgsmcomlist_id], data: {} }))
        //showToast('success', `Cổng đã được làm mới`);
    }

    const refreshMultiPort = () => {
        const selectedItems = extractedSelectedItems()
        console.log(selectedItems)
        if (selectedItems.length < 1) {
            alert('Chưa chọn mục nào để sửa')
        } else {
            try {
                dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, { yeuCauXuLy: 'refresh', selectedItems, data: {} }))
                //showToast('success', `Đã xử lý`);
            } catch (error) {
                //
            }
        }
    }

    const handleCloseSuaNhieuMuc = () => {
        setOpenSuaNhieuMuc(false);
    };

    return (
        <Dialog sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root.MuiDialog-paperScrollPaper": {
                    width: "800px",
                    maxWidth: "90vw",
                },
                //   "& .MuiTablePagination-toolbar": {
                //     paddingRight: "50px",
                //   }
            },
        }} open={props.open} onClose={() => {
            props.handleClose()
            handleClose()
        }}>
            {/* <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle> */}
            <DialogContent>
                <Card className="filter_card">
                    <CardContent>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <PerfectScrollbar>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    //minWidth: 1050,
                                    maxWidth: "100%",
                                }}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        fontSize="small"
                                                        color="action"
                                                    >
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            )
                                        }}
                                        name="ma_tram"
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        value={values.ma_tram}
                                        placeholder="Mã, SĐT..."
                                        variant="outlined"
                                        style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
                                    />
                                    <Select1
                                        style={{ height: 56, marginTop: 6, width: "250px", marginLeft: 0 }}
                                        name="trang_thai"
                                        value={values.trang_thai || -99}
                                        onChange={(newValue) => handleChange(newValue)}
                                        variant="outlined"
                                        startAdornment={(
                                            <InputAdornment position="start">
                                                <SvgIcon fontSize="small" color="action">
                                                    <SearchIcon />
                                                </SvgIcon>
                                            </InputAdornment>
                                        )}
                                    >
                                        <MenuItem value="-99">Trạng thái...</MenuItem>
                                        <MenuItem value="hoat_dong">Hoạt động</MenuItem>
                                        <MenuItem value="ngung_hoat_dong">Ngừng hoạt động</MenuItem>
                                    </Select1>
                                </Box>
                            </PerfectScrollbar>
                        </LocalizationProvider>
                    </CardContent>
                </Card>
                <Card>
                    <div style={{ width: '200px', margin: 'auto' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleEdit}
                            style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal', borderRadius: '4px 0 0 4px' }}
                        >
                            Sửa
                        </Button>
                        <Select1
                            style={{ height: '20px', marginTop: 0, width: '40px', marginLeft: 0, padding: 0, top: '2px', border: '1px solid orange', background: 'orange', borderRadius: '0px 4px 4px 0' }}
                            name="sua_port_nhieu_muc"
                            value={-99}
                            onChange={(newValue) => handleSuaPortNhieuMuc(newValue)}
                            variant="outlined"
                        >
                            <MenuItem value="mo">🟢 MỞ</MenuItem>
                            <MenuItem value="sua_uu_tien">📝 Sửa ưu tiên</MenuItem>
                            <MenuItem value="sua_gioi_han">📝 Sửa giới hạn</MenuItem>
                            <MenuItem value="sua_menh_gia">📝 Sửa mệnh giá</MenuItem>
                            <MenuItem value="sua_dau_so_nap">📝 Sửa đầu số nạp</MenuItem>
                            <MenuItem value="dong">🔴 ĐÓNG</MenuItem>
                        </Select1>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleDelete}
                            style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal', borderRadius: '4px 0 0 4px' }}
                        >
                            Xóa
                        </Button>
                        {/* <Button
                        color="primary"
                        variant="contained"
                        onClick={() => exportExcel()}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '0', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: 30, border: '1px solid orange' }}
                        >
                        ⬇️
                    </Button> */}
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onLookup}
                            style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
                        >
                            {!isLoading ? <Cached style={{ color: "#3498db" }} />  : ''}
                        </Button>
                    </div>
                    <PerfectScrollbar>
                        <Box sx={{
                            //minWidth: 1050, 
                            maxWidth: "100%",
                            marginTop: '15px',
                        }}>
                            <Table style={{overflow: 'hidden'}}>
                                <TableHead>
                                    <TableRow style={{ background: 'red' }}>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            #
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            <Checkbox
                                                checked={itemCheckAll == 1 ? true : false}
                                                onChange={(event) => setItemCheckAllOnChange(event)}
                                                value={itemCheckAll == 1 ? 1 : 0}
                                                style={{ width: '20px', color: 'white' }}
                                            />
                                        </TableCell>
                                        <TableCell colSpan={3} style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            Tên cổng
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            %
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            Số điện thoại
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            Mạng
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            Ban đầu
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            Hiện tại
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            SL đã bắn
                                        </TableCell>
                                        <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            Giới hạn
                                        </TableCell>
                                        <TableCell style={{ fontSize: 24, color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            ☑
                                        </TableCell>
                                        <TableCell style={{ fontSize: 24, color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={(e) => refreshMultiPort()}
                                                style={{ height: '25px', padding: 0, marginTop: 0, width: "25px", minWidth: '25px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: 24, color: 'white' }}
                                            >
                                                ♻
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cauhinhgsmcomlist_items && cauhinhgsmcomlist_items.slice(page * limit, page * limit + limit).map((cauhinhgsmcomlist, index) => (
                                        <TableRow
                                            hover
                                            key={cauhinhgsmcomlist.id}
                                            selected={selectedBlacklistIds.indexOf(cauhinhgsmcomlist.id) !== -1}
                                        >
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                {page * limit + index + 1}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Checkbox
                                                    checked={item[cauhinhgsmcomlist.id] == 1 ? true : false}
                                                    onChange={(event) => setItem(event, cauhinhgsmcomlist.id)}
                                                    value={item[cauhinhgsmcomlist.id] == 1 ? 1 : 0}
                                                />
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color={cauhinhgsmcomlist.trang_thai ? 'green' : 'red'}
                                                    variant="body1"
                                                    fontWeight="bold"
                                                >
                                                    {cauhinhgsmcomlist.ten_cong}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {cauhinhgsmcomlist.mes_cong}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >

                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {cauhinhgsmcomlist.muc_uu_tien}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {cauhinhgsmcomlist.so_dien_thoai}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0, width: 150 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {cauhinhgsmcomlist.so_dien_thoai && <>
                                                        {cauhinhgsmcomlist.operator.toUpperCase()}
                                                        {cauhinhgsmcomlist.loai_thue_bao && <span style={{ fontSize: 12, color: 'red' }}> [{cauhinhgsmcomlist.loai_thue_bao == '1' ? 'MC' : 'MG'}]</span>}
                                                    </>}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {numeral(cauhinhgsmcomlist.ban_dau).format('0,0')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {numeral(cauhinhgsmcomlist.hien_tai).format('0,0')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {numeral(cauhinhgsmcomlist.so_luong_da_ban).format('0,0')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {numeral(cauhinhgsmcomlist.gioi_han_so_tien).format('0,0')}
                                                    {cauhinhgsmcomlist.gioi_han_lan_trong_thang > 0 && <span style={{ fontWeight: 'bold', color: 'red' }}> [{numeral(cauhinhgsmcomlist.gioi_han_lan_trong_thang).format('0,0')}]</span>}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, textAlign: 'center', backgroundColor: cauhinhgsmcomlist.trang_thai ? 'green' : 'red' }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    <Checkbox
                                                        checked={cauhinhgsmcomlist.trang_thai}
                                                        onChange={(event) => batTatLoaiDichVu(event, cauhinhgsmcomlist.id, cauhinhgsmcomlist.cauhinhgsm_id, 'trang_thai')}
                                                        value={cauhinhgsmcomlist.trang_thai ? 1 : 2}
                                                        style={{ width: '20px', color: 'white' }}
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 0, paddingBottom: 0, textAlign: 'center' }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={(e) => refreshPort(cauhinhgsmcomlist.id)}
                                                        style={{ background: cauhinhgsmcomlist.so_dien_thoai ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '25px', padding: 0, marginTop: 0, width: "25px", minWidth: '25px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: 30, color: 'orange' }}
                                                    >
                                                        {cauhinhgsmcomlist.so_dien_thoai ? '♻' : ''}
                                                    </Button>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </PerfectScrollbar>
                    <TablePagination
                        component="div"
                        count={cauhinhgsmcomlist_total}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                    />
                    <SuaNhieuMucDialog open={openSuaNhieuMuc} handleClose={handleCloseSuaNhieuMuc} type={yeuCau} mucDaChon={mucDaChon} idgsm={id} />
                    <CreateEditDialogBoiSoChiTiet open={openEditBoiSo} handleClose={handleCloseEditBoiSo} type={"EDIT"} />
                    <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.handleClose()
                    handleClose()
                }}>Đóng</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CauhinhgsmcomlistDialog;
