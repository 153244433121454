import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { QuanlythenapAction } from 'src/actions/quanlythenap.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    TableCell,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.quanlythenap)
    const [isOpen, setIsOpen] = useState(false);
    const [trang_thai, setTrangThai] = useState("");
    const [gia_gui, setGiaGui] = useState(0)
    const [callback_url, setCallbackUrl] = useState("")
    const [ma_doi_chieu, setMaDoiChieu] = useState("")
    const [nha_mang, setNhaMang] = useState("-99")
    const [ma_the, setMaThe] = useState("")
    const [seri, setSeri] = useState("")

    const [values, setValues] = useState({
        menh_gia: ['-99'],
    });

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setGiaGui(selected.gia_gui)
                setCallbackUrl(selected.callback_url)
                setMaDoiChieu(selected.ma_doi_chieu)
                setTrangThai(selected.trang_thai)
            }
        }
    }, [selected])

    const onPressPositive = async () => {
        if (props.type === 'EDIT') {
            if (gia_gui === 0) {
                return;
            }
            if (callback_url == "") {
                return;
            }
            if (ma_doi_chieu == "") {
                return;
            }
            if (trang_thai == "") {
                return;
            }
            try {
                dispatch(QuanlythenapAction.update(selected.id, { 
                    gia_gui: gia_gui, 
                    callback_url: callback_url, 
                    ma_doi_chieu: ma_doi_chieu,
                    trang_thai: trang_thai,
                }))
                resetState();
                props.handleClose();
            } catch (error) {
                console.log('update_error', error)
            }
        } else {
            if (nha_mang == '-99') {
                return;
            }
            if (ma_the == "") {
                return;
            }
            if (seri == "") {
                return;
            }
            if (gia_gui === 0) {
                return;
            }
            try {
                const response = await dispatch(QuanlythenapAction.create({ 
                    nha_mang: nha_mang, 
                    ma_the: ma_the, 
                    seri: seri,
                    gia_gui: gia_gui,
                }))

                if (response.status == 0) {
                    showToast('success', response.message);
                    resetState();
                    props.handleClose();
                } else {
                    showToast('failed', response.message);
                }
            } catch (error) {
                console.log('create_error', error)
            }
        }
    }

    const resetState = () => {
        setGiaGui()
        setCallbackUrl("")
        setMaDoiChieu("")
        setTrangThai("")
        setNhaMang("-99")
        setMaThe("")
        setSeri("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const handleChange = (event) => {
        if (event.target.name == 'menh_gia') {
            setIsOpen(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setValues({
                ...values,
                [event.target.name]: targetValues,
            });
        } else {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleClickChonNhaMang = async () => {
        setIsOpen(true);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
                <DialogContent style={{ width: 600, paddingTop: 20 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        {props.type === 'EDIT' && <>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Select1
                                    style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                    name="trang_thai"
                                    value={trang_thai || '-99'}
                                    onChange={(e) => setTrangThai(e.target.value)}
                                    variant="outlined"
                                >
                                    <MenuItem value="-99">Trạng thái...</MenuItem>
                                    <MenuItem value="cho_xu_ly">Chờ xử lý</MenuItem>
                                    <MenuItem value="dang_xu_ly">Đang xử lý</MenuItem>
                                    <MenuItem value="dang_xu_ly_loi">Đang xử lý (lỗi)</MenuItem>
                                    <MenuItem value="the_dung_menh_gia">Thẻ đúng mệnh giá</MenuItem>
                                    <MenuItem value="the_sai_menh_gia">Thẻ sai mệnh giá</MenuItem>
                                    <MenuItem value="the_loi_huy">Thẻ lỗi / hủy</MenuItem>
                                </Select1>
                            </Grid>
                            {/* <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Select1
                                    style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                    name="gia_gui"
                                    value={gia_gui || 0}
                                    onChange={(e) => setGiaGui(e.target.value)}
                                    variant="outlined"
                                >
                                    <MenuItem value="0">Mệnh giá...</MenuItem>
                                    <MenuItem value="10000">10.000</MenuItem>
                                    <MenuItem value="20000">20.000</MenuItem>
                                    <MenuItem value="30000">30.000</MenuItem>
                                    <MenuItem value="50000">50.000</MenuItem>
                                    <MenuItem value="100000">100.000</MenuItem>
                                    <MenuItem value="200000">200.000</MenuItem>
                                    <MenuItem value="300000">300.000</MenuItem>
                                    <MenuItem value="500000">500.000</MenuItem>
                                    <MenuItem value="1000000">1.000.000</MenuItem>
                                </Select1>
                            </Grid> */}
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="URL Callback"
                                    name="callback_url"
                                    onChange={(e) => setCallbackUrl(e.target.value)}
                                    required
                                    value={callback_url}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Mã đối chiếu"
                                    name="ma_doi_chieu"
                                    onChange={(e) => setMaDoiChieu(e.target.value)}
                                    value={ma_doi_chieu}
                                    variant="outlined"
                                />
                            </Grid>
                        </>}

                        {props.type !== 'EDIT' && <>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Select1
                                    style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                    name="nha_mang"
                                    value={nha_mang || '-99'}
                                    onChange={(e) => setNhaMang(e.target.value)}
                                    variant="outlined"
                                >
                                    <MenuItem value="-99">Nhà mạng...</MenuItem>
                                    <MenuItem value="MOBI">MOBI</MenuItem>
                                    <MenuItem value="VINA">VINA</MenuItem>
                                    <MenuItem value="VIETTEL">VIETTEL</MenuItem>
                                </Select1>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Mã thẻ"
                                    name="ma_the"
                                    onChange={(e) => setMaThe(e.target.value)}
                                    required
                                    value={ma_the}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Số seri"
                                    name="seri"
                                    onChange={(e) => setSeri(e.target.value)}
                                    required
                                    value={seri}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Select1
                                    style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                    name="gia_gui"
                                    value={gia_gui || 0}
                                    onChange={(e) => setGiaGui(e.target.value)}
                                    variant="outlined"
                                >
                                    <MenuItem value="0">Mệnh giá...</MenuItem>
                                    <MenuItem value="10000">10.000</MenuItem>
                                    <MenuItem value="20000">20.000</MenuItem>
                                    <MenuItem value="30000">30.000</MenuItem>
                                    <MenuItem value="50000">50.000</MenuItem>
                                    <MenuItem value="100000">100.000</MenuItem>
                                    <MenuItem value="200000">200.000</MenuItem>
                                    <MenuItem value="300000">300.000</MenuItem>
                                    <MenuItem value="500000">500.000</MenuItem>
                                    <MenuItem value="1000000">1.000.000</MenuItem>
                                </Select1>
                            </Grid>
                        </>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Huỷ</Button>
                    <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    );
}

export default CreateEditDialog;