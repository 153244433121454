import React, { useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { UploadAction } from 'src/actions/upload.acction';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import { UploadchiadataAction } from 'src/actions/uploadchiadata.action';

const UploadListToolbar = (props) => {
  const dispatch = useDispatch()
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);

  const fileInput = React.useRef();

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, range: 1 });
      const temp = dataParse.splice(0).map((c) => ({
        so_dien_thoai: c[1],
        loai_thue_bao: c[2],
        goi_cuoc: c[3],
        ngay_dang_ky: c[4],
        ngay_het_han: c[5],        
        chu_ky_hien_tai: c[6],
        trang_thai: c[7],
        goi_co_the_dang_ky: c[8],
        so_chu_ky_mien_phi_con_lai: c[9],
        FileName: files[0].name,
      }));
      let temp1 = {}
      if (temp != null && temp.length > 0) {
        temp.forEach(function (element) {
          temp1[element.so_dien_thoai + "_" + element.goi_cuoc] = element
        })
      }
      temp1 = Object.values(temp1)
      dispatch(UploadchiadataAction.saveDataUpload(temp1));
      setPreview(true)
      e.target.value = null;
      alert("Tải file thành công: \"" + files[0].name + "\" (" + temp1.length + " số)");
      dispatch(UploadchiadataAction.list());
    };
    reader.readAsBinaryString(f)
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <input
          ref={fileInput}
          type="file"
          onChange={(e) => handleImportFile(e)}
          style={{ display: 'none' }}
        />

        <Button sx={{ mx: 1 }} onClick={() => fileInput.current.click()}>
          Tải dữ liệu
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search product"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default UploadListToolbar;
