import {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {
  BarChart2 as BarChart2Icon,
  Briefcase as BriefcaseIcon,
  CheckSquare as CheckSquareIcon,
  Command as CommandIcon,
  DollarSign as DollarSignIcon,
  Home as HomeIcon,
  List as ListIcon,
  MessageSquare as MessageSquareIcon,
  PhoneCall as PhoneCallIcon,
  PieChart as PieChartIcon,
  Search as SearchIcon,
  Send as SendIcon,
  Settings as SettingsIcon,
  Shield as ShieldIcon,
  Upload as UploadIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  RefreshCcw,
} from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { isGrantPermission } from 'src/utils/utils';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';

import NavItem from './NavItem';
import numeral from 'numeral';
import { AccountAction } from 'src/actions/account.acction';

import MobifoneDanhSachDon from "../pages/MobifoneDanhSachDon"
import { components } from 'react-select';

import { emitter } from 'src/utils/emitter';

const workspaceItems = [
  // {
  //   href: '/app/customer-care',
  //   icon: UserPlusIcon,
  //   title: 'Khách hàng mới',
  //   permissions: ['customer.get', 'customer.care']
  // },
  // {
  //   href: '/app/khr',
  //   icon: UserPlusIcon,
  //   title: 'Khách hàng riêng',
  //   permissions: ['khr']
  // },
  // {
  //   href: '/app/blacklist',
  //   icon: UserPlusIcon,
  //   title: 'Blacklist',
  //   permissions: ['blacklist']
  // },
  // {
  //   href: '/app/baodo',
  //   icon: UserPlusIcon,
  //   title: 'Báo đỏ',
  //   permissions: ['baodo']
  // },
  // {
  //   href: '/app/care-history',
  //   icon: PhoneCallIcon,
  //   title: 'Nhật ký CSKH',
  //   permissions: ['customer.care']
  // },
  // {
  //   href: '/app/customer-lookup',
  //   icon: SearchIcon,
  //   title: 'Tra cứu thuê bao',
  //   permissions: ['lookup.balance', 'lookup.package', 'lookup.info']
  // },
  // {
  //   icon: MessageSquareIcon,
  //   title: 'Tra cứu tin nhắn',
  //   permissions: ['history.999', 'history.909', 'history.9084', 'history.9332'],
  //   children: [
  //     {
  //       href: '/app/customer-message-909',
  //       icon: MessageSquareIcon,
  //       title: 'LS 909',
  //       permissions: ['history.909'],
  //     },
  //     {
  //       href: '/app/customer-message-999',
  //       icon: MessageSquareIcon,
  //       title: 'Trung tâm tin nhắn',
  //       permissions: ['history.999'],
  //     },
  //     {
  //       href: '/app/customer-message-9084',
  //       icon: MessageSquareIcon,
  //       title: 'Kết quả đăng ký',
  //       permissions: ['history.9084'],
  //     },
  //     {
  //       href: '/app/customer-message-9332',
  //       icon: MessageSquareIcon,
  //       title: 'LS 9332',
  //       permissions: ['history.9332'],
  //     },
  //   ]
  // },
  // {
  //   href: '/app/requestrefund',
  //   icon: DollarSignIcon,
  //   title: 'Yêu cầu hoàn tiền',
  //   permissions: ['requestrefund']
  // },
  // {
  //   href: '/app/invite',
  //   icon: SendIcon,
  //   title: 'Mời gói cước',
  //   permissions: ['care.otp', 'care.9332', 'care.selling', 'care.dk']
  // },
  // {
  //   href: '/app/approval',
  //   icon: CheckSquareIcon,
  //   title: 'Quản lý hoàn tiền',
  //   permissions: ['requet.approve.package-refund', 'requet.approve.charge-discount', 'requet.approve.charge-package']
  // },
  {
    href: '/app/mobifone/danhsachdon',
    icon: BarChart2Icon,
    title: 'Bắn tiền',
    component: "MobifoneDanhSachDon",
    permissions: ['mobi_danhsachdon']
  },
  {
    href: '/app/mobifone/napmymobifone',
    icon: BarChart2Icon,
    title: 'Nạp cước',
    component: "NapMyMobifone",
    permissions: ['mobi_mobifonemoney']
  },
  {
    href: '/sys/quanlynapcuoc',
    icon: BarChart2Icon,
    title: 'QL nạp cước',
    component: "Quanlynapcuoc",
    permissions: ['quanlynapcuoc']
  },
  {
    href: '/sys/lssodu',
    icon: BarChart2Icon,
    title: 'Lịch sử số dư',
    component: "Lssodu",
    permissions: ['lssodu']
  },
  {
    href: '/sys/dongtien',
    icon: BarChart2Icon,
    title: 'Dòng tiền',
    component: "Dongtien",
    permissions: ['dongtien']
  },
  {
    href: '/app/bank-lookup',
    icon: DollarSignIcon,
    title: 'Bank',
    component: "BankLookup",
    permissions: ['lookup.bank']
  },
  // {
  //   icon: BarChart2Icon,
  //   title: 'Lên đơn nạp',
  //   permissions: ['mobi_naple', 'mobi_napdon', 'mobi_danhsachdon'],
  //   children: [
  //     {
  //       href: '/app/mobifone/naple',
  //       icon: BarChart2Icon,
  //       title: 'Nạp lẻ',
  //       permissions: ['mobi_naple']
  //     },
  //     {
  //       href: '/app/mobifone/napdon',
  //       icon: BarChart2Icon,
  //       title: 'Nạp đơn',
  //       permissions: ['mobi_napdon']
  //     },
  //     {
  //       href: '/app/mobifone/mobifonemoney',
  //       icon: BarChart2Icon,
  //       title: 'Mobifone Money',
  //       permissions: ['mobi_mobifonemoney']
  //     },
  //     {
  //       href: '/app/mobifone/danhsachdon',
  //       icon: BarChart2Icon,
  //       title: 'Danh sách đơn',
  //       permissions: ['mobi_danhsachdon']
  //     },
  //   ]
  // },
];

const systemItems = [
  {
    href: '/sys/accounts',
    icon: UserIcon,
    title: 'Quản lý đại lý',
    component: "AccountList",
    permissions: ['user.get', 'user.edit', 'user.add', 'user.delete']
  },
  {
    href: '/sys/thamso',
    icon: CommandIcon,
    title: 'Tham số',
    component: "Thamso",
    permissions: ['thamso']
  },
  {
    icon: ListIcon,
    title: 'QL thẻ nạp',
    permissions: ['thenap', 'quanlythenap'],
    children: [
      {
        href: '/sys/thenap',
        icon: BriefcaseIcon,
        title: 'Thẻ nạp',
        component: "Thenap",
        permissions: ['thenap'],
      },
      {
        href: '/sys/quanlythenap',
        icon: BriefcaseIcon,
        title: 'Quản lý thẻ nạp',
        component: "Quanlythenap",
        permissions: ['quanlythenap'],
      },
    ]
  },
  {
    icon: ListIcon,
    title: 'Thống kê thẻ',
    permissions: ['thongkethegio', 'thongkethengay'],
    children: [
      {
        href: '/sys/thongkethegio',
        icon: BriefcaseIcon,
        title: 'Theo giờ',
        component: "Thongkethegio",
        permissions: ['thongkethegio'],
      },
      {
        href: '/sys/thongkethengay',
        icon: BriefcaseIcon,
        title: 'Theo ngày',
        component: "Thongkethengay",
        permissions: ['thongkethengay'],
      },
    ]
  },
  {
    href: '/sys/taikhoanmymobi',
    icon: CommandIcon,
    title: 'My Mobifone',
    component: "Taikhoanmymobi",
    permissions: ['taikhoanmymobi']
  },
  {
    href: '/sys/cauhinhgsm',
    icon: CommandIcon,
    title: 'Cấu hình GSM',
    component: "Cauhinhgsm",
    permissions: ['cauhinhgsm']
  },
  // {
  //   href: '/sys/lsnaptien',
  //   icon: BarChart2Icon,
  //   title: 'Lịch sử nạp tiền',
  //   permissions: ['lsnaptien']
  // },
  // {
  //   href: '/sys/danhsachcom',
  //   icon: BarChart2Icon,
  //   title: 'Danh sách COM',
  //   permissions: ['danhsachcom']
  // },
  {
    href: '/sys/lstracuu',
    icon: UserIcon,
    title: 'Lịch sử tra cứu',
    permissions: ['lstracuu']
  },
  // {
  //   icon: ListIcon,
  //   title: 'QL khách hàng',
  //   permissions: ['customer.add', 'customer.scan'],
  //   children: [
  //     {
  //       href: '/sys/customers',
  //       icon: BriefcaseIcon,
  //       title: 'Khách hàng',
  //       permissions: ['customer.add', 'customer.scan', 'customer.edit'],
  //     },
  //     {
  //       href: '/sys/upload',
  //       icon: UploadIcon,
  //       title: 'Upload',
  //       permissions: ['customer.add'],
  //     },
  //     {
  //       href: '/sys/division',
  //       icon: PieChartIcon,
  //       title: 'Chia data',
  //       permissions: ['customer.edit'],
  //     },
  //   ]
  // },
  {
    href: '/sys/permissions',
    icon: CommandIcon,
    title: 'Danh sách quyền',
    component: "PermissionList",
    permissions: ['permission.get']
  },
  {
    href: '/sys/roles',
    icon: ShieldIcon,
    title: 'Phân quyền theo vai trò',
    component: "RoleList",
    permissions: ['role.get', 'role.edit', 'role.add', 'role.delete']
  },
  {
    href: '/sys/giahoantien',
    icon: DollarSignIcon,
    title: 'Giá hoàn tiền',
    permissions: ['giahoantien',]
  },
  {
    href: '/sys/systemaccount',
    icon: UserIcon,
    title: 'Tài khoản hệ thống',
    component: "Systemaccount",
    permissions: ['systemaccount',]
  },
  {
    href: '/sys/quetdata',
    icon: CommandIcon,
    title: 'Quét data',
    permissions: ['quetdata',]
  },
  {
    href: '/sys/quetdatakhonggoi',
    icon: CommandIcon,
    title: 'Quét data không gói',
    permissions: ['quetdatakhonggoi',]
  },
  {
    href: '/sys/quetdtct',
    icon: CommandIcon,
    title: 'Quét đối tượng chương trình',
    permissions: ['quetdtct',]
  },
  {
    href: '/sys/uploadchiadata',
    icon: CommandIcon,
    title: 'Upload chia data',
    permissions: ['uploadchiadata',]
  },
  {
    href: '/sys/phanquyendata',
    icon: CommandIcon,
    title: 'Phân quyền data',
    permissions: ['phanquyendata',]
  },
  {
    href: '/sys/chiadata',
    icon: CommandIcon,
    title: 'Chia data',
    permissions: ['chiadata',]
  },
  {
    href: '/sys/settings',
    icon: SettingsIcon,
    title: 'Cài đặt',
    permissions: ['settings']
  },
  {
    href: '/sys/lichsuhoatdong',
    icon: CommandIcon,
    title: 'Lịch sử hoạt động',
    component: "LichsuhoatdongLookup",
    permissions: ['lichsuhoatdong']
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const dispatch = useDispatch()

  const [upload, setUpload] = useState(false);
  const [balance, setBalance] = useState('0');

  const { info, permissions, role } = useSelector(state => state.profile)

  const getBalance = async (username = null) => {
    console.log(username);

    const res1 = await dispatch(AccountAction.getBalance(username));
    setBalance(parseInt(res1.toString()));
  }

  useEffect(() => {
    async function fetchData() {
      await getBalance(info.username);
    }
    fetchData();
  }, [])

  const handleUploadClick = () => {
    handleUploadClick(!upload);
  };

  useEffect(() => {
    //MENU_CUSTOM
    if (openMobile && onMobileClose) {
      //onMobileClose();
    }
  }, [location.pathname]);

  const addTab = (component = null) => {
    if (component != null) {
      console.log("add_tab")
      emitter.emit("add_tab", component)
    }
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={info.avatar || '/static/images/avatars/avatar_default.png'}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/sys/profile"
        />
        <Typography
          color="#ecf0f1"
          variant="h5"
        >
          {info.fullname}
        </Typography>
        <Typography
          color="#ecf0f1"
          variant="body2"
        >
          {role.name}
        </Typography>
        <Typography
          color="#ecf0f1"
          variant="h5"
          fontSize={'24px'}
          fontWeight={'normal'}
        >
          <span style={{ color: '#f1c40f' }}>{numeral(balance).format('0,0')}</span>
          <IconButton onClick={() => getBalance(info.username)}>
            <RefreshCcw color='#3498db' />
          </IconButton>
        </Typography>
        <Typography
          color="#ecf0f1"
          variant="h5"
          fontSize={'24px'}
          fontWeight={'normal'}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              // window.location.href = `${window.location.origin}/app/machuyentien`;
              addTab('Machuyentien');
            }}
            style={{ height: '20px', padding: 0, marginTop: 0, width: "90px", minWidth: '20px', marginLeft: '0', backgroundColor: 'orange', fontWeight: 'normal' }}
            >
            Autobank
          </Button>
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        {/* <NavItem
          href={"/app/dashboard"}
          key={"Trang chủ"}
          title={"Trang chủ"}
          icon={HomeIcon}
        />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          Không gian làm việc
        </Typography> */}
        <List>
          <NavItem
            //href={"/app/dashboard"}
            onClick={() => addTab('Dashboard')}
            key={"Trang chủ"}
            title={"Trang chủ"}
            icon={HomeIcon}
            sx={{
              "& .MuiButton-text": {
                color: "#fff",
                padding: "0px 8px"
              },
            }}
          />
          {workspaceItems.map((item) => (!info.username.trim().startsWith('HNIMB') || info.username.trim().startsWith('HNIMB') && item.href != '/app/requestrefund' && item.href != '/app/requestrefundreport') && isGrantPermission(permissions, item.permissions) && (
            <NavItem
              href={typeof item.component !== 'undefined' ? '#' : item.href}
              onClick={() => addTab(typeof item.component !== 'undefined' ? item.component : null)}
              key={item.title}
              title={item.title}
              icon={item.icon}
              children={item.children}
              sx={{
                "& .MuiButton-text": {
                  color: "#fff",
                  padding: "0px 8px"
                },
              }}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography
          color="textPrimary"
          variant="h6"
          style={{ color: "#fff" }}
        >
          Quản trị
        </Typography>
        <List>
          {systemItems.map((item) => isGrantPermission(permissions, item.permissions) && (
            <NavItem
              href={typeof item.component !== 'undefined' ? '#' : item.href}
              onClick={() => addTab(typeof item.component !== 'undefined' ? item.component : null)}
              key={item.title}
              title={item.title}
              icon={item.icon}
              children={item.children}
              sx={{
                "& .MuiButton-text": {
                  color: "#fff",
                  padding: "0px 8px"
                },
              }}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
              backgroundColor: "#34495e",
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      {/* MENU_CUSTOM */}
      <Hidden xsDown>
        <Drawer
          anchor="left"
          onClose={onMobileClose} //đồng bộ với lgUp
          open={openMobile} //đồng bộ với lgUp
          variant="persistent"
          PaperProps={{
            sx: {
              color: "#fff",
              backgroundColor: "#34495e",
              width: 256,
              top: 54,
              height: 'calc(100% - 54px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
