import {
  useEffect,
  useState,
} from 'react';

import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
  Shield,
  Command as CommandIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { AccountAction } from 'src/actions/account.acction';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from '@material-ui/core';

import ConfirmDialog from '../ConfirmDialog';
import AccountEditDialog from './AccountEditDialog';
import AccountEditSoDuDialog from './AccountEditSoDuDialog';
import AccountResetPasswordDialog from './AccountResetPasswordDialog';
import CreateRoleDialog from './CreateRoleDialog';
import numeral from 'numeral';
import { SettingAction } from 'src/actions/setting.action';

const AccountListResults = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { role } = useSelector(state => state.profile)
  const { items, total, filter, tong_so_du } = useSelector(state => state.account)
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editSoDu, setEditSoDu] = useState(false);
  const [setKenhHoanGoi, setKenhHoanGoiChoNv] = useState([]);
  const [open, setOpen] = useState(false);
  const [balances, setBalances] = useState({});
  const [moduleBanTien, batTatModuleBanTienValue] = useState({});

  useEffect(() => {
      async function fetchSettingModuleBanTien() {
          const moduleBanTien_res = await dispatch(SettingAction.findByKey('bat_tat_module_ban_tien'))
          let moduleBanTien_value = JSON.parse(moduleBanTien_res.data.value);
          moduleBanTien_value = {
            topup: moduleBanTien_value.topup == '1' ? 1 : 2,
            tkc: moduleBanTien_value.tkc == '1' ? 1 : 2, 
            dv: moduleBanTien_value.dv == '1' ? 1 : 2,
            bc: moduleBanTien_value.bc == '1' ? 1 : 2,
            napho: moduleBanTien_value.napho == '1' ? 1 : 2,
            mymobifone: moduleBanTien_value.mymobifone == '1' ? 1 : 2,
            gsmmobifone: moduleBanTien_value.gsmmobifone == '1' ? 1 : 2,

            topup_vina: moduleBanTien_value.topup_vina == '1' ? 1 : 2,
            tkc_vina: moduleBanTien_value.tkc_vina == '1' ? 1 : 2, 
            dv_vina: moduleBanTien_value.dv_vina == '1' ? 1 : 2,
            bc_vina: moduleBanTien_value.bc_vina == '1' ? 1 : 2,

            topup_viettel: moduleBanTien_value.topup_viettel == '1' ? 1 : 2,
            tkc_viettel: moduleBanTien_value.tkc_viettel == '1' ? 1 : 2, 
            dv_viettel: moduleBanTien_value.dv_viettel == '1' ? 1 : 2,
            bc_viettel: moduleBanTien_value.bc_viettel == '1' ? 1 : 2,
        }
          batTatModuleBanTienValue(moduleBanTien_value)
      }
      fetchSettingModuleBanTien();
  }, [])

  const onSelected = (id) => {
    dispatch(AccountAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPasswordOpen = (account) => {
    setSelected(account);
    setResetPassword(true);
  };

  const handleEditOpen = (account) => {
    dispatch(AccountAction.selectedAccount(account));
    setEdit(true);
  };

  const handleEditSoDuOpen = (account) => {
    dispatch(AccountAction.selectedAccount(account));
    setEditSoDu(true);
  };

  const handleResetPasswordClose = () => {
    setResetPassword(false);
  };

  const handleConfirmOpen = (type, account) => {
    setSelected(account);
    setConfirmType(type)
    if (confirmType === CONFIRM_TYPES.DELETE) {
      // setTitle("Xoá tài khoản")
      // setContent(`Xác nhận xoá tài khoản `)
      setTitle("Khoá / Mở khóa tài khoản")
      setContent(`Xác nhận khoá / mở khóa tài khoản `)
    }
    if (confirmType === CONFIRM_TYPES.LOCK) {
      setTitle("Khoá tài khoản")
      setContent(`Xác nhận khoá tài khoản `)
    }
    if (confirmType === CONFIRM_TYPES.UNLOCK) {
      setTitle("Mở khoá tài khoản")
      setContent(`Xác nhận mở khoá tài khoản `)
    }
    setConfirm(true);
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(AccountAction.del(selected.id))
      return;
    }

    if (confirmType === CONFIRM_TYPES.LOCK) {
      dispatch(AccountAction.lock(selected.id))
      return;
    }

    if (confirmType === CONFIRM_TYPES.UNLOCK) {
      dispatch(AccountAction.unlock(selected.id))
      return;
    }
  }

  const resetHardwareId = async (userId) => {
    await dispatch(AccountAction.resetHardwareId(userId))
  };

  const getBalance = async (username = null) => {
    console.log(username);

    //cập nhật toàn bộ
    if (username == null) {
      if (items) {
        let _balances = {};
        items.forEach(async (account, idx) => {
          const res1 = await dispatch(AccountAction.getBalance(account.username));
          _balances[account.username] = parseInt(res1.toString());
        });
        setBalances(_balances);
      }
    }
    //cập nhật lẻ theo username
    else {
      const res1 = await dispatch(AccountAction.getBalance(username));
      setBalances({
        ...balances,
        [username]: parseInt(res1.toString()),
      });
    }
  }

  // useEffect(() => {
  //   async function fetchData() {
  //     await getBalance();
  //   }
  //   fetchData();
  // }, [])

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };

    dispatch(AccountAction.updateFilter(payload));
    dispatch(AccountAction.list(page, limit));

    // async function fetchData(payload) {
    //   console.log(items);

    //   dispatch(AccountAction.updateFilter(payload));
    //   const res = await dispatch(AccountAction.list(payload.page, payload.limit));
    //   console.log(res.data.values)
    //   if (res.data.values) {
    //     let _balances = balances;
    //     res.data.values.forEach(async (account, idx) => {
    //       const res1 = await dispatch(AccountAction.getBalance(account.username));
    //       if (res1) {
    //         _balances[account.username] = res1;
    //         // setBalances({
    //         //   ...balances,
    //         //   [account.username]: res1,
    //         // })
    //         //setBalances(_balances);
    //       }
    //     });
    //     console.log(_balances);
    //     setBalances(_balances);
    //   }
    // }
    // fetchData(payload);

    // if (role.priority < 2) {
    //   const syncdskenhmoigoi = async () => {
    //     const getdskenhmoigoi = await dispatch(AccountAction.getdskenhmoigoi());

    //     console.log(getdskenhmoigoi);

    //     if (getdskenhmoigoi && typeof getdskenhmoigoi.data !== 'undefined' && getdskenhmoigoi.data && typeof getdskenhmoigoi.data.values !== 'undefined' && getdskenhmoigoi.data.values) {
    //       console.log(getdskenhmoigoi.data.values)
    //       getdskenhmoigoi.data.values.forEach((item, index) => {
    //         let setKenh = setKenhHoanGoi;

    //         if (typeof setKenh[item.staff_username] === 'undefined') {
    //           setKenh[item.staff_username] = {}
    //         }

    //         if (item.active == true) {
    //           setKenh[item.staff_username][item.kenh] = true
    //         } else {
    //           setKenh[item.staff_username][item.kenh] = false
    //         }

    //         setKenhHoanGoiChoNv(setKenh);
    //       })
    //     }
    //   }
      
    //   syncdskenhmoigoi()
    // }
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0)
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const chonKenhHoanGoi = async (accountUsername, channelName, channelText) => {
    setKenhHoanGoiChoNv({
      ...setKenhHoanGoi,
      [accountUsername]: {
        ...setKenhHoanGoi[accountUsername],
        [channelName]: typeof setKenhHoanGoi[accountUsername] !== 'undefined' && typeof setKenhHoanGoi[accountUsername][channelName] !== 'undefined' && setKenhHoanGoi[accountUsername][channelName] == true ? false : true,
      },
    });

    //Khi hủy chọn kênh
    if (typeof setKenhHoanGoi[accountUsername] !== 'undefined' && typeof setKenhHoanGoi[accountUsername][channelName] !== 'undefined' && setKenhHoanGoi[accountUsername][channelName] == true) {
      await dispatch(AccountAction.huyChonKenh({accountUsername, channelName, channelText}));
    }
    //Khi chọn kênh
    else {
      await dispatch(AccountAction.chonKenh({accountUsername, channelName, channelText}));
    }
  };

  const getApiKey = async (id) => {
    const apiKey = await dispatch(AccountAction.getApiKey(id));
    prompt('API KEY:', apiKey);
  }

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow style={{background: 'red'}}>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  #
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tên
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Username
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Bộ phận
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Email
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Vai trò
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Trạng thái
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Số dư
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Chiết khấu
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Hành động
                </TableCell>
                {1 < 0 && role.priority < 2 && <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Kênh mời gói
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((account, idx) => (
                <TableRow
                  hover
                  key={account.username}
                  selected={selectedCustomerIds.indexOf(account.username) !== -1}
                >
                  <TableCell sx={{ pl: 4 }}>
                    {page * limit + idx + 1}
                  </TableCell>
                  <TableCell>
                    {account.full_name}
                  </TableCell>
                  <TableCell>
                    {role.priority < 3 && <Button
                      onClick={(e) => getApiKey(account.id)}
                      style={{ backgroundColor: 'transparent', color: 'blue', textTransform: 'none' }}
                      >
                      {account.username}
                    </Button>}
                    {role.priority > 2 && account.username}
                  </TableCell>
                  <TableCell>
                    {account.department_username}
                  </TableCell>
                  <TableCell>
                    {account.email}
                  </TableCell>
                  <TableCell>
                    <Chip label={account.role_name} style={{color: account.role_priority != 5 ? 'white' : 'black', background: account.role_priority == 3 ? 'purple' : (account.role_priority == 4 ? 'blue' : (account.role_priority == 5 ? 'pink' : 'red'))}} />
                  </TableCell>

                  <TableCell>
                    {!account.is_deleted ? <Chip label="Đang hoạt động" color='success' />
                      : <Chip label="Ngừng hoạt động" color='error' />}
                  </TableCell>

                  {1 < 0 && <TableCell>
                    {account.active ? <Chip label="Đang hoạt động" color='success' />
                      : <Chip label="Ngừng hoạt động" color='error' />}
                  </TableCell>}

                  <TableCell>
                    <span className="table_balance" style={{fontSize: '28px'}}>{numeral(account.balance).format('0,0')}</span>
                    {(account.role_priority == 1 || account.role_priority != 1 && role.priority < account.role_priority) && <>
                      <IconButton style={{float: 'right'}} aria-label="edit" onClick={() => handleEditSoDuOpen(account)}>
                        <Edit3Icon color='green' />
                      </IconButton>
                    </>}
                  </TableCell>

                  <TableCell>
                    {typeof account !== 'undefined' && typeof account.new_cai_dat_chiet_khau !== 'undefined' && <>
                      {(role.priority < 3 || role.priority > 2 && moduleBanTien.topup == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- TOP-UP: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{account.new_cai_dat_chiet_khau.chiet_khau_smart_vi} %, <span style={{color: 'blue'}}>VINA: </span>{account.new_cai_dat_chiet_khau.chiet_khau_smart_vi_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{account.new_cai_dat_chiet_khau.chiet_khau_smart_vi_viettel} %</span><br></br></span>}
                      {(role.priority < 3 || role.priority > 2 && moduleBanTien.tkc == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- TKC: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{account.new_cai_dat_chiet_khau.chiet_khau_tkc} %, <span style={{color: 'blue'}}>VINA: </span>{account.new_cai_dat_chiet_khau.chiet_khau_tkc_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{account.new_cai_dat_chiet_khau.chiet_khau_tkc_viettel} %</span><br></br></span>}
                      {/* {(role.priority < 3 || role.priority > 2 && moduleBanTien.dv == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- DV: <span style={{color: 'brown'}}><span style={{color: 'green'}}>MOBI: </span>{account.new_cai_dat_chiet_khau.chiet_khau_dichvu} %, <span style={{color: 'blue'}}>VINA: </span>{account.new_cai_dat_chiet_khau.chiet_khau_dichvu_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{account.new_cai_dat_chiet_khau.chiet_khau_dichvu_viettel} %</span><br></br></span>} */}
                      {/* {(role.priority < 3 || role.priority > 2 && moduleBanTien.bc == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- BC: <span style={{color: 'brown'}}><span style={{color: 'green'}}>MOBI: </span>{account.new_cai_dat_chiet_khau.chiet_khau_bunca} %, <span style={{color: 'blue'}}>VINA: </span>{account.new_cai_dat_chiet_khau.chiet_khau_bunca_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{account.new_cai_dat_chiet_khau.chiet_khau_bunca_viettel} %</span><br></br></span>} */}
                      {(role.priority < 3 || role.priority > 2 && moduleBanTien.napho == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp hộ: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{account.new_cai_dat_chiet_khau.chiet_khau_napho} %, <span style={{color: 'blue'}}>VINA: </span>{account.new_cai_dat_chiet_khau.chiet_khau_napho_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{account.new_cai_dat_chiet_khau.chiet_khau_napho_viettel} %</span><br></br></span>}
                      {(role.priority < 3 || role.priority > 2 && moduleBanTien.mymobifone == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp My: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{account.new_cai_dat_chiet_khau.chiet_khau_mymobifone} %, <span style={{color: 'blue'}}>VINA: </span>{account.new_cai_dat_chiet_khau.chiet_khau_mymobifone_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{account.new_cai_dat_chiet_khau.chiet_khau_mymobifone_viettel} %</span><br></br></span>}
                      {(role.priority < 3 || role.priority > 2 && moduleBanTien.gsmmobifone == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp GSM: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{account.new_cai_dat_chiet_khau.chiet_khau_gsmmobifone} %, <span style={{color: 'blue'}}>VINA: </span>{account.new_cai_dat_chiet_khau.chiet_khau_gsmmobifone_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{account.new_cai_dat_chiet_khau.chiet_khau_gsmmobifone_viettel} %</span><br></br></span>}
                    </>}
                  </TableCell>

                  <TableCell style={{ justifyContent: 'center', alignItems: 'center' }}>

                    {1 < 0 && account.role_priority > 1 && <IconButton aria-label="edit" onClick={() => handleConfirmOpen(account.active ? CONFIRM_TYPES.LOCK : CONFIRM_TYPES.UNLOCK, account)}>
                      {account.active ? <Lock color='#9500ae' /> : <Unlock color='#2196f3' />}
                    </IconButton>}

                    {account.role_priority > 1 && <IconButton aria-label="edit" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, account)}>
                      {!account.is_deleted ? <Lock color='#9500ae' /> : <Unlock color='#2196f3' />}
                    </IconButton>}

                    <IconButton onClick={() => handleResetPasswordOpen(account)}>
                      <RefreshCcw color='blue' />
                    </IconButton>

                    <IconButton aria-label="edit" onClick={() => handleEditOpen(account)}>
                      <Edit3Icon color='green' />
                    </IconButton>

                    {1 < 0 && account.role_priority > 1 && <IconButton aria-label="delete" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, account)}>
                      <Trash2Icon color='red' />
                    </IconButton>}

                    {role.priority < 2 && account.role_priority > 1 && <IconButton aria-label="edit_permission" onClick={() => onSelected(account.id)}>
                      <Shield color='blue' />
                    </IconButton>}

                    {role.priority < 2 && <IconButton aria-label="reset_hardware_id" onClick={() => resetHardwareId(account.id)}>
                      <CommandIcon color='red' />
                    </IconButton>}

                  </TableCell>

                  {1 < 0 && role.priority < 2 && account.role_priority == 3 && <TableCell>
                    <label>
                      <input
                        type="checkbox"
                        checked={typeof setKenhHoanGoi[account.username] !== 'undefined' && typeof setKenhHoanGoi[account.username]['ma_xac_nhan_1'] !== 'undefined' && setKenhHoanGoi[account.username]['ma_xac_nhan_1'] == true ? true : false}
                        onChange={() => chonKenhHoanGoi(account.username, 'ma_xac_nhan_1', 'Mã xác nhận 1')}
                      />
                      <span style={{marginLeft: '5px'}}>Mã xác nhận 1</span>
                    </label>

                    <br></br>

                    <label>
                      <input
                        type="checkbox"
                        checked={typeof setKenhHoanGoi[account.username] !== 'undefined' && typeof setKenhHoanGoi[account.username]['ma_xac_nhan_2'] !== 'undefined' && setKenhHoanGoi[account.username]['ma_xac_nhan_2'] == true ? true : false}
                        onChange={() => chonKenhHoanGoi(account.username, 'ma_xac_nhan_2', 'Mã xác nhận 2')}
                      />
                      <span style={{marginLeft: '5px'}}>Mã xác nhận 2</span>
                    </label>

                    <br></br>

                    <label>
                      <input
                        type="checkbox"
                        checked={typeof setKenhHoanGoi[account.username] !== 'undefined' && typeof setKenhHoanGoi[account.username]['ma_xac_nhan_3'] !== 'undefined' && setKenhHoanGoi[account.username]['ma_xac_nhan_3'] == true ? true : false}
                        onChange={() => chonKenhHoanGoi(account.username, 'ma_xac_nhan_3', 'Mã xác nhận 3')}
                      />
                      <span style={{marginLeft: '5px'}}>Mã xác nhận 3</span>
                    </label>
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <PerfectScrollbar>
        <div style={{margin: 15, minWidth: 1050}}>
          <span style={{marginRight: 15}}>Tổng số dư: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_so_du).format('0,0')}</span></span>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <AccountResetPasswordDialog open={resetPassword} account={selected} handleClose={handleResetPasswordClose} />
      <ConfirmDialog open={confirm} title={title || "Khoá / Mở khóa tài khoản"} content={content || `Xác nhận khoá / mở khóa tài khoản `} highlight={selected.username} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
      <AccountEditDialog open={edit} account={selected} handleClose={() => setEdit(false)} />
      <AccountEditSoDuDialog open={editSoDu} account={selected} handleClose={() => setEditSoDu(false)} getBalance={getBalance} />
      <CreateRoleDialog open={open} handleClose={handleClose} type={"EDIT"} />
    </Card >
  );
};

export default AccountListResults;
