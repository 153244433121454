import { useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import { AccountAction } from 'src/actions/account.acction';

const AccountProfileDetails = (props) => {
  const dispatch = useDispatch()
  const { info } = useSelector(state => state.profile)
  const [values, setValues] = useState({
    fullname: info.fullname,
    email: info.email,
    phone: info.phone,
    password: '',
  });

  const handleSaveProfile = () => {
    dispatch(AccountAction.saveProfile(values))
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Cập nhật thông tin cá nhân"
          title="Thông tin cá nhân"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Họ tên"
                name="fullname"
                onChange={handleChange}
                required
                value={values.fullname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Số điện thoại"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{marginTop: 15}}
          >
            {/* <Grid
              item
              md={12}
              xs={12}
            >
              <h3 style={{color: 'red'}}>* Lưu ý: Để thay đổi mật khẩu, vui lòng nhập đủ 3 trường dưới đây. Nếu nhập thiếu 1 trong 3 trường, thì hệ thống sẽ không tiến hành đổi mật khẩu.</h3>
            </Grid> */}
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Mật khẩu cũ"
                name="password"
                onChange={handleChange}
                type="password"
                required
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Mật khẩu mới"
                name="password_new"
                onChange={handleChange}
                type="password"
                required
                value={values.password_new}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Xác nhận mật khẩu mới"
                name="password_new_confirm"
                onChange={handleChange}
                type="password"
                required
                value={values.password_new_confirm}
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Select State"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveProfile}
          >
            Lưu
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
