import { RequestrefundService } from 'src/services/requestrefund.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const REQUESTREFUND_NEW_CARE_SUCCESS = 'REQUESTREFUND:NEW_CARE_SUCCESS'
export const REQUESTREFUND_LIST_SUCCESS = 'REQUESTREFUND:FIND_ALL_SUCCESS'
export const REQUESTREFUND_LIST_FAILD = 'REQUESTREFUND:FIND_ALL_FAILD'
export const REQUESTREFUND_COUNT_ASSIGN_SUCCESS = 'REQUESTREFUND:COUNT_ASSIGN_SUCCESS'
export const REQUESTREFUND_COUNT_ASSIGN_FAILD = 'REQUESTREFUND:COUNT_ASSIGN_FAILD'

export const REQUESTREFUND_CREATE_SUCCESS = 'REQUESTREFUND:CREATE_SUCCESS'
export const REQUESTREFUND_CREATE_FAILD = 'REQUESTREFUND:CREATE_FAILD'

export const REQUESTREFUND_IMPORT_SUCCESS = 'REQUESTREFUND:IMPORT_SUCCESS'
export const REQUESTREFUND_SAVE_SUCCESS = 'REQUESTREFUND:SAVE_SUCCESS'
export const REQUESTREFUND_SAVE_FAILD = 'REQUESTREFUND:SAVE_SUCCESS'

export const REQUESTREFUND_DEL_SUCCESS = 'REQUESTREFUND:DEL_SUCCESS'
export const REQUESTREFUND_DEL_FAILD = 'REQUESTREFUND:DEL_FAILD'

export const REQUESTREFUND_ONE_SUCCESS = 'REQUESTREFUND:FIND_ONE_SUCCESS'
export const REQUESTREFUND_ONE_FAILD = 'REQUESTREFUND:FIND_ONE_FAILD'

export const REQUESTREFUND_HISTORY_LIST_SUCCESS = 'REQUESTREFUND:HISTORY_FIND_ALL_SUCCESS'
export const REQUESTREFUND_HISTORY_LIST_FAILD = 'REQUESTREFUND:HISTORY_FIND_ALL_FAILD'

export const REQUESTREFUND_UPDATE_FILTER = 'REQUESTREFUND:REQUESTREFUND_UPDATE_FILTER'

export const RequestrefundAction = {
    list,
    findAssign,
    create24hOrder,
    createHoanTienGoiCuoc,
    createNapTienChietKhau,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    approve,
    setTrangThaiDuyetHoanGoiStatus,
    setTransferStatus,
    setTrangThaiGiaoDich,
    setHinhThuc,
    setYeuCau,
    updateFilter,
    updateUser,
    updateTienHoanThem,
    updateTienHoan,
    updatePackageCode,
    setCreatedTime,
}

function updateFilter(payload) {
    return { type: REQUESTREFUND_UPDATE_FILTER, data: payload }
}

function setCreatedTime(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.setCreatedTime(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function updateUser(username, id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.updateUser(username, id).then((rs) => {
            dispatch(success(rs));
            dispatch(RequestrefundAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function updateTienHoanThem(other_refund, id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.updateTienHoanThem(other_refund, id).then((rs) => {
            dispatch(success(rs));
            dispatch(RequestrefundAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function updateTienHoan(refund_price, id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.updateTienHoan(refund_price, id).then((rs) => {
            dispatch(success(rs));
            dispatch(RequestrefundAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function updatePackageCode(package_code, id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.updatePackageCode(package_code, id).then((rs) => {
            dispatch(success(rs));
            dispatch(RequestrefundAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { requestrefund } = getState()
        dispatch(AppAction.appLoading(true));
        RequestrefundService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...requestrefund.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_LIST_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        const { requestrefund } = getState();
        dispatch(AppAction.appLoading(true));

        const { filter } = requestrefund;
        const page = filter.page;
        const limit = filter.limit;

        return new Promise((resolve, reject) => {
            RequestrefundService.findAll(filter.page, filter.limit, filter).then((rs) => {
                if (!!filter && filter.page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count
                    }));
                } else {
                    const oldItems = requestrefund.items;
                    const rsDataValues = rs.data.values;

                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count
                    }));
                }
                resolve(rs);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        });
    };
    function success(data) { return { type: REQUESTREFUND_LIST_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: REQUESTREFUND_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            RequestrefundService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(RequestrefundAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: REQUESTREFUND_SAVE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_SAVE_FAILD, error } }
}

function create24hOrder(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.create24hOrder(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };

    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function createHoanTienGoiCuoc(payload, currentOriginUrl = null) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.createHoanTienGoiCuoc(payload, currentOriginUrl)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };

    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function createNapTienChietKhau(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.createNapTienChietKhau(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };

    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(RequestrefundAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function setTrangThaiDuyetHoanGoiStatus(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.setTrangThaiDuyetHoanGoiStatus(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function setTransferStatus(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.setTransferStatus(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function setHinhThuc(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.setHinhThuc(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function setYeuCau(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.setYeuCau(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function setTrangThaiGiaoDich(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            RequestrefundService.setTrangThaiGiaoDich(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(RequestrefundAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: REQUESTREFUND_CREATE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(RequestrefundAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_DEL_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_ONE_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        RequestrefundService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(RequestrefundAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { requestrefund } = getState()
        dispatch(AppAction.appLoading(true));
        RequestrefundService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...requestrefund.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: REQUESTREFUND_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: REQUESTREFUND_HISTORY_LIST_FAILD, error } }
}