import { Helmet } from 'react-helmet';
import DanhsachcomDetail
  from 'src/components/Danhsachcom-detail/DanhsachcomDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

//import DanhsachcomToolbar from '../components/Danhsachcom-detail/DanhsachcomToolbar';

const Danhsachcom = () => (
  <>
    <Helmet>
      <title>Danh sách COM</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        {/* <DanhsachcomToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <DanhsachcomDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Danhsachcom;
