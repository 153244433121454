import { Navigate } from 'react-router-dom';

import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import AccountList from './pages/AccountList';
import ApprovalRefund from './pages/ApprovalList';
import BankLookup from './pages/BankLookup';
import CustomerCareHistoryList from './pages/CustomerCareHistoryList';
import CustomerCareList from './pages/CustomerCareList';
import CustomerList from './pages/CustomerList';
import CustomerLookup from './pages/CustomerLookup';
import CustomerMessage999 from './pages/CustomerMessage999';
import CustomerMessage9084 from './pages/CustomerMessage9084';
import Dashboard from './pages/Dashboard';
import Division from './pages/Division';
import Giahoantien from './pages/Giahoantien';
import Invitation from './pages/Invitation';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PermissionList from './pages/PermissionList';
import Register from './pages/Register';
import Report90849332List from './pages/Report90849332List';
import ReportstaffrevenueList from './pages/ReportstaffrevenueList';
import ReportbranchrevenueList from './pages/ReportbranchrevenueList';
import TinhluongList from './pages/TinhluongList';
import DsdttinhluongList from './pages/DsdttinhluongList';
import Requestrefund from './pages/Requestrefund';
import Requestrefundreport from './pages/Requestrefundreport';
import RoleList from './pages/RoleList';
import Settings from './pages/Settings';
import Systemaccount from './pages/Systemaccount';
import Quetdata from './pages/Quetdata';
import Quetdatakhonggoi from './pages/Quetdatakhonggoi';
import Quetdtct from './pages/Quetdtct';
import UploadList from './pages/UploadList';
import Uploadchiadata from './pages/Uploadchiadata';
import Phanquyendata from './pages/Phanquyendata';
import Chiadata from './pages/Chiadata';
import Chamsoc from './pages/Chamsoc';
import Khr from './pages/Khr';
import Baodo from './pages/Baodo';
import Blacklist from './pages/Blacklist';
import LstracuuLookup from './pages/LstracuuLookup';

import MobifoneNapLe from './pages/MobifoneNapLe';
import MobifoneNapDon from './pages/MobifoneNapDon';
import MobifoneDanhSachDon from './pages/MobifoneDanhSachDon';
import MobifoneDanhSachDonChiTiet from './pages/MobifoneDanhSachDonChiTiet';
import NapMyMobifone from './pages/NapMyMobifone';
import NapMyMobifoneChiTiet from './pages/NapMyMobifoneChiTiet';
import Danhsachcom from './pages/Danhsachcom';
import Lsnaptien from './pages/Lsnaptien';
import Lssodu from './pages/Lssodu';
import Cauhinhgsm from './pages/Cauhinhgsm';
import Cauhinhgsmcomlist from './pages/Cauhinhgsmcomlist';
import Taikhoanmymobi from './pages/Taikhoanmymobi';
import Thenap from './pages/Thenap';
import Quanlythenap from './pages/Quanlythenap';
import Quanlynapcuoc from './pages/Quanlynapcuoc';
import Thamso from './pages/Thamso';
import Thongkethengay from './pages/Thongkethengay';
import Thongkethegio from './pages/Thongkethegio';
import Dongtien from './pages/Dongtien';
import Machuyentien from './pages/Machuyentien';
import _HomePage from './pages/_HomePage';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'home', element: <_HomePage /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'care-history', element: <CustomerCareHistoryList /> },
      { path: 'customer-care', element: <CustomerCareList /> },
      { path: 'customer-lookup', element: <CustomerLookup /> },
      { path: 'customer-message-999', element: <CustomerMessage999 /> },
      { path: 'customer-message-9084', element: <CustomerMessage9084 /> },
      { path: 'bank-lookup', element: <BankLookup /> },
      { path: 'invite', element: <Invitation /> },
      { path: 'request-sms', element: <Account /> },
      { path: 'approval', element: <ApprovalRefund /> },
      { path: 'requestrefund', element: <Requestrefund /> },
      { path: 'requestrefundreport', element: <Requestrefundreport /> },
      { path: 'report90849332', element: <Report90849332List /> },
      { path: 'reportstaffrevenue', element: <ReportstaffrevenueList /> },
      { path: 'reportbranchrevenue', element: <ReportbranchrevenueList /> },
      { path: 'dsdttinhluong', element: <DsdttinhluongList /> },
      { path: 'tinhluong', element: <TinhluongList /> },
      { path: 'chamsoc', element: <Chamsoc /> },
      { path: 'khr', element: <Khr /> },
      { path: 'baodo', element: <Baodo /> },
      { path: 'blacklist', element: <Blacklist /> },
      { path: 'machuyentien', element: <Machuyentien /> },

      { path: 'mobifone/naple', element: <MobifoneNapLe /> },
      { path: 'mobifone/napdon', element: <MobifoneNapDon /> },
      { path: 'mobifone/danhsachdon', element: <MobifoneDanhSachDon /> },
      { path: 'mobifone/danhsachdonchitiet/:id', element: <MobifoneDanhSachDonChiTiet /> },
      { path: 'mobifone/napmymobifone', element: <NapMyMobifone /> },
      { path: 'mobifone/napmymobifonechitiet/:id', element: <NapMyMobifoneChiTiet /> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'sys',
    element: <DashboardLayout />,
    children: [
      { path: 'thongkethengay', element: <Thongkethengay /> },
      { path: 'thongkethegio', element: <Thongkethegio /> },
      { path: 'thamso', element: <Thamso /> },
      { path: 'quanlynapcuoc', element: <Quanlynapcuoc /> },
      { path: 'thenap', element: <Thenap /> },
      { path: 'quanlythenap', element: <Quanlythenap /> },
      { path: 'taikhoanmymobi', element: <Taikhoanmymobi /> },
      { path: 'cauhinhgsm', element: <Cauhinhgsm /> },
      { path: 'cauhinhgsm/cauhinhgsmcomlist/:id', element: <Cauhinhgsmcomlist /> },
      { path: 'lstracuu', element: <LstracuuLookup /> },
      { path: 'profile', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'permissions', element: <PermissionList /> },
      { path: 'roles', element: <RoleList /> },
      { path: 'accounts', element: <AccountList /> },
      { path: 'upload', element: <UploadList /> },
      { path: 'division', element: <Division /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: 'giahoantien', element: <Giahoantien /> },
      { path: 'systemaccount', element: <Systemaccount /> },
      { path: 'quetdata', element: <Quetdata /> },
      { path: 'quetdatakhonggoi', element: <Quetdatakhonggoi /> },
      { path: 'quetdtct', element: <Quetdtct /> },
      { path: 'uploadchiadata', element: <Uploadchiadata /> },
      { path: 'phanquyendata', element: <Phanquyendata /> },
      { path: 'chiadata', element: <Chiadata /> },
      { path: 'danhsachcom', element: <Danhsachcom /> },
      { path: 'lsnaptien', element: <Lsnaptien /> },
      { path: 'lssodu', element: <Lssodu /> },
      { path: 'dongtien', element: <Dongtien /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/home" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
