import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const ThongkethengayService = {

  handleSuaNhieuMuc: (payload) => {
    return axios.post(`/api/v1/thongkethengay/handleSuaNhieuMuc`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },
  
  yeuCauChayLaiCallback: (id) => {
    return axios.get(`/api/v1/thongkethengay/callbackVeDoiTac/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
  
  approve: (id, payload) => {
    return axios.put(`/api/v1/thongkethengay/approve/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  xoaMucDaChon: (payload) => {
    return axios.put(`/api/v1/thongkethengay/xoaMucDaChon`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  batTatLoaiDichVu: (id, payload) => {
    return axios.put(`/api/v1/thongkethengay/batTatLoaiDichVu/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  create: (payload) => {
    return axios.post('/api/v1/thongkethengay', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/thongkethengay/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/thongkethengay/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/thongkethengay?page=${page}&count=${count}`;

    if (payload.ma_tram) {
      url += `&ma_tram=${payload.ma_tram}`
    };

    if (payload.kieu_nap) {
      url += `&kieu_nap=${payload.kieu_nap}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.tai_khoan) {
      url += `&tai_khoan=${payload.tai_khoan}`
    };

    if (payload.gia_gui) {
      url += `&gia_gui=${payload.gia_gui}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllByOrder: (id, page, count, payload) => {
    let url = `/api/v1/thongkethengay/findAllByOrder/${id}?page=${page}&count=${count}`;

    if (payload.ma_tram) {
      url += `&ma_tram=${payload.ma_tram}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/thongkethengay/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/thongkethengay/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findPosition: (position) => {
    return axios.get(`/api/v1/thongkethengay/findPosition/${position}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/thongkethengay/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/thongkethengay/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/thongkethengay/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  }
}