import { useState } from 'react';

import { CustomerTypes } from 'src/utils/constants';

import io from 'socket.io-client';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  TableRow,
  TableCell,
  Typography,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { AppAction } from '../../actions/app.action';
import { LstracuuAction } from 'src/actions/lstracuu.action';

import MainSocket from 'src/components/MainSocket.js'

const CustomerLookupInfo = (props) => {
  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const { socket, socket_client_id } = MainSocket(useSelector(state => state));
  const { role } = useSelector(state => state.profile);
  const [values, setValues] = useState({
    phone: '',
    phoneType: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    dispatch(AppAction.appLoading(true));
    if (typeof values.phoneType === 'undefined') {
      setValues({
        ...values,
        phoneType: "MC",
      });
    }

    var phoneType = typeof values.phoneType !== 'undefined' ? values.phoneType : "MC"

    var phone = (values.phone).substring((values.phone).length - 9)
    
    if (phone.trim() == '') {
      alert('Bạn phải nhập số điện thoại')
      dispatch(AppAction.appLoading(false));
      return;
    }

    console.log(phone, phoneType)

    if (typeof values.socketSend == 'undefined' || values.socketSend == null) {
      dispatch(LstracuuAction.create({ so_dien_thoai: phone, noi_dung: `Tra cứu thông tin chi tiết thuê bao:\n{\nisdn: ${phone},\nphoneType: ${phoneType}\n}` }));

      values.socketSend = "traCuuThongTinChiTietThueBao";
      delete values.socketReceive;

      // socket.emit("traCuuThongTinChiTietThueBao", {client_id: socket_client_id, data: {
      //   isdn: phone,
      //   phoneType: phoneType
      // }})
    }
  }

  // socket.on("traKetQuaTraCuuThongTinChiTietThueBao", (data) => {
  //   if (typeof values.socketReceive == 'undefined' || values.socketReceive == null) {
  //     values.socketReceive = "traKetQuaTraCuuThongTinChiTietThueBao";
  //     delete values.socketSend;

  //     data = data.data;
  //     if (data && typeof data.NUMBER_PHONE !== 'undefined' && values && typeof values.phone !== 'undefined' && data.NUMBER_PHONE.slice(-9) == values.phone.slice(-9)) {
  //       console.log(data);
  //       setData(data);
  //       dispatch(AppAction.appLoading(false));
  //     }
  //   }
  // })

  return (
    <form {...props}>
      {role.priority < 5 && <Card sx={{ mt: 3 }}>
        <CardHeader
          title="#4 TRA CỨU THÔNG TIN CHI TIẾT THUÊ BAO (GH)"
          subheader="Giờ tra cứu 7:00 - 22:00 | Số lần tra cứu: 4 lần mỗi giờ"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Loại thuê bao"
            name="phoneType"
            onChange={handleChange}
            required
            select
            SelectProps={{ native: true }}
            value={values.phoneType}
            variant="outlined"
          >
            <option
              key="MC"
              value="MC"
            >
              Trả trước
            </option>

            <option
              key="MG"
              value="MG"
            >
              Trả sau
            </option>
          </TextField>

          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            name="phone"
            required
            onChange={handleChange}
            value={values.phone}
            variant="outlined"
          />

        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
          >
            Tra cứu
          </Button>
        </Box>
        {data.NUMBER_PHONE && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>

              <TableRow>
                <TableCell width="40%">
                  KẾT QUẢ
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Số thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.NUMBER_PHONE}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              {values.phoneType == "MC" && <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Loại thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.NUM_TYPE.replace(/undefined/g, '')}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>}

              {values.phoneType == "MC" && <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Tài khoản chính
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.PHONE_BALANCE}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>}

              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Loại SIM
                  </Typography>
                </TableCell>
                <TableCell>
                  {values.phoneType == "MC" ? "SIM " : ""}{data.SIM_TYPE}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Gói cước hiện tại
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.CURRENT_PACKAGE}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              {values.phoneType == "MG" && <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Ngày hợp đồng
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.NUMBER_CONTRACT_DATE}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>}

              {values.phoneType == "MG" && <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Hạn mức trong nước
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.NUMBER_LIMIT}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>}

              {values.phoneType == "MG" && <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Hạn mức cước thuê bao MobiF1
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.NUMBER_LIMIT_F1}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>}

            </TableBody>

            <TableHead>

              <TableRow>
                <TableCell width="40%">
                  LỊCH SỬ ĐĂNG KÝ GÓI
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width="40%">
                  Tên gói
                </TableCell>
                <TableCell>
                  Ngày bắt đầu
                </TableCell>
                <TableCell>
                  Ngày kết thúc
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
            {data.PACKAGE_DATA.map((item) => (
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    {item.PACKAGE_NAME}
                  </Typography>
                </TableCell>
                <TableCell>
                  {item.START_DATE}
                </TableCell>
                <TableCell>
                  {item.END_DATE}
                </TableCell>
              </TableRow>
            ))}
            </TableBody>

          </Table>
        </Box>}
      </Card>}
    </form>
  )
};

export default CustomerLookupInfo;
