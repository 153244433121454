import { Helmet } from 'react-helmet';
import LssoduDetail
  from 'src/components/Lssodu-detail/LssoduDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

//import LssoduToolbar from '../components/Lssodu-detail/LssoduToolbar';

const Lssodu = () => (
  <>
    <Helmet>
      <title>Lịch sử số dư</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        {/* <LssoduToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <LssoduDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Lssodu;
