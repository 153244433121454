import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ThamsoAction } from 'src/actions/thamso.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getThamsoStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

const ThamsoLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedThamsoIds, setSelectedThamsoIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { items, total } = useSelector(state => state.thamso);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(ThamsoAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedThamsoIds;

    if (event.target.checked) {
      newSelectedThamsoIds = items.map((thamso) => thamso.id);
    } else {
      newSelectedThamsoIds = [];
    }

    setSelectedThamsoIds(newSelectedThamsoIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedThamsoIds.indexOf(id);
    let newSelectedThamsoIds = [];

    if (selectedIndex === -1) {
      newSelectedThamsoIds = newSelectedThamsoIds.concat(selectedThamsoIds, id);
    } else if (selectedIndex === 0) {
      newSelectedThamsoIds = newSelectedThamsoIds.concat(selectedThamsoIds.slice(1));
    } else if (selectedIndex === selectedThamsoIds.length - 1) {
      newSelectedThamsoIds = newSelectedThamsoIds.concat(selectedThamsoIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedThamsoIds = newSelectedThamsoIds.concat(
        selectedThamsoIds.slice(0, selectedIndex),
        selectedThamsoIds.slice(selectedIndex + 1)
      );
    }

    setSelectedThamsoIds(newSelectedThamsoIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, thamso) => {
    setSelected(thamso);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(ThamsoAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(ThamsoAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Nhóm
                </TableCell>
                <TableCell>
                  Tham số
                </TableCell>
                <TableCell>
                  Giá trị
                </TableCell>
                <TableCell>
                  Chức năng
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((thamso, index) => (
                <TableRow
                  hover
                  key={thamso.id}
                  selected={selectedThamsoIds.indexOf(thamso.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thamso.category}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thamso.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {thamso.value}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => onSelected(thamso.id)}>
                      <Edit3Icon color='green' />
                    </IconButton>
                    {/* <IconButton aria-label="delete" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, thamso)}>
                      <Trash2Icon color='red' />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default ThamsoLookupDetail;
