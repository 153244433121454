import { LssoduService } from 'src/services/lssodu.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const LSSODU_NEW_CARE_SUCCESS = 'LSSODU:NEW_CARE_SUCCESS'
export const LSSODU_LIST_SUCCESS = 'LSSODU:FIND_ALL_SUCCESS'
export const LSSODU_LIST_FAILD = 'LSSODU:FIND_ALL_FAILD'
export const LSSODU_COUNT_ASSIGN_SUCCESS = 'LSSODU:COUNT_ASSIGN_SUCCESS'
export const LSSODU_COUNT_ASSIGN_FAILD = 'LSSODU:COUNT_ASSIGN_FAILD'

export const LSSODU_CREATE_SUCCESS = 'LSSODU:CREATE_SUCCESS'
export const LSSODU_CREATE_FAILD = 'LSSODU:CREATE_FAILD'

export const LSSODU_IMPORT_SUCCESS = 'LSSODU:IMPORT_SUCCESS'
export const LSSODU_SAVE_SUCCESS = 'LSSODU:SAVE_SUCCESS'
export const LSSODU_SAVE_FAILD = 'LSSODU:SAVE_SUCCESS'

export const LSSODU_DEL_SUCCESS = 'LSSODU:DEL_SUCCESS'
export const LSSODU_DEL_FAILD = 'LSSODU:DEL_FAILD'

export const LSSODU_ONE_SUCCESS = 'LSSODU:FIND_ONE_SUCCESS'
export const LSSODU_ONE_FAILD = 'LSSODU:FIND_ONE_FAILD'

export const LSSODU_HISTORY_LIST_SUCCESS = 'LSSODU:HISTORY_FIND_ALL_SUCCESS'
export const LSSODU_HISTORY_LIST_FAILD = 'LSSODU:HISTORY_FIND_ALL_FAILD'

export const LSSODU_UPDATE_FILTER = 'LSSODU:UPDATE_FILTER'

export const LssoduAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    approve,
    setTrangThai,
    updateFilter,
}

function updateFilter(payload) {
    return { type: LSSODU_UPDATE_FILTER, data: payload }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            LssoduService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(LssoduAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: LSSODU_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_CREATE_FAILD, error } }
}

function setTrangThai(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            LssoduService.setTrangThai(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(LssoduAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: LSSODU_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        LssoduService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSSODU_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { lssodu } = getState()
        dispatch(AppAction.appLoading(true));
        LssoduService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...lssodu.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSSODU_LIST_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            const { lssodu } = getState();
            const page = lssodu && typeof lssodu.filter !== 'undefined' && lssodu.filter && typeof lssodu.filter.page !== 'undefined' ? lssodu.filter.page : 0;
            const limit = lssodu && typeof lssodu.filter !== 'undefined' && lssodu.filter && typeof lssodu.filter.limit !== 'undefined' ? lssodu.filter.limit : 10;
            LssoduService.findAll(page, limit, lssodu.filter).then((rs) => {
                if (page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count,
                        tong_hoa_hong: rs.data.tong_hoa_hong,
                        cong_so_du: rs.data.cong_so_du,
                        tru_so_du: rs.data.tru_so_du,
                        tru_so_du_don_nap: rs.data.tru_so_du_don_nap,
                        hoan_tien_that_bai_don: rs.data.hoan_tien_that_bai_don,
                    }));
                } else {
                    const oldItems = lssodu.items;
                    const rsDataValues = rs.data.values;

                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count,
                        tong_hoa_hong: rs.data.tong_hoa_hong,
                        cong_so_du: rs.data.cong_so_du,
                        tru_so_du: rs.data.tru_so_du,
                        tru_so_du_don_nap: rs.data.tru_so_du_don_nap,
                        hoan_tien_that_bai_don: rs.data.hoan_tien_that_bai_don,
                    }));
                }
                resolve(rs);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        });
    };
    function success(data) { return { type: LSSODU_LIST_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: LSSODU_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            LssoduService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(LssoduAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: LSSODU_SAVE_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            LssoduService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(LssoduAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: LSSODU_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        LssoduService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(LssoduAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSSODU_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        LssoduService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(LssoduAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSSODU_DEL_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        LssoduService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSSODU_ONE_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        LssoduService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(LssoduAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { lssodu } = getState()
        dispatch(AppAction.appLoading(true));
        LssoduService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...lssodu.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LSSODU_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: LSSODU_HISTORY_LIST_FAILD, error } }
}