import { Helmet } from 'react-helmet';
import BankLookupDetail
  from 'src/components/bank-lookup-detail/BankLookupDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import BankListToolbar from '../components/bank-lookup-detail/BankListToolbar';

const BankLookup = () => (
  <>
    <Helmet>
      <title>Bank</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <BankListToolbar />
        <Box sx={{ pt: 3 }}>
          <BankLookupDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default BankLookup;
