import {
  REPORT_9084_QUERY,
  REPORT_9084_SUCCESS,
  REPORT_9084new_QUERY,
  REPORT_9084new_SUCCESS,
  REPORT_9084new1_QUERY,
  REPORT_9084new1_SUCCESS,
  REPORT_9332_QUERY,
  REPORT_9332_SUCCESS,
  UPDATE_FILTER,
  UPDATE_FILTER1,
  UPDATE_FILTERnew,
  UPDATE_FILTERnew1,
} from 'src/actions/report.acction';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import dayjs from 'dayjs';

const today = new Date();

const initState = {
  filter: {
    phone: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  filter1: {
    phone: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  filternew: {
    phone: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  filternew1: {
    phone: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  query9332: {},
  items9332: [],
  total9332: 0,
  items9084: [],
  total9084: 0,
  items9084new: [],
  total9084new: 0,
  items9084new1: [],
  total9084new1: 0,
}

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_FILTER:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filter !== 'undefined' && state.filter) {
        if (action.data.page == 'no_update' && typeof state.filter.page !== 'undefined') action.data.page = state.filter.page;
        if (action.data.limit == 'no_update' && typeof state.filter.limit !== 'undefined') action.data.limit = state.filter.limit;
      }
      return {
        ...state,
        filter: action.data,
      }
    }

    case UPDATE_FILTER1:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filter1 !== 'undefined' && state.filter1) {
        if (action.data.page == 'no_update' && typeof state.filter1.page !== 'undefined') action.data.page = state.filter1.page;
        if (action.data.limit == 'no_update' && typeof state.filter1.limit !== 'undefined') action.data.limit = state.filter1.limit;
      }
      return {
        ...state,
        filter1: action.data,
      }
    }

    case UPDATE_FILTERnew:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filternew !== 'undefined' && state.filternew) {
        if (action.data.page == 'no_update' && typeof state.filternew.page !== 'undefined') action.data.page = state.filternew.page;
        if (action.data.limit == 'no_update' && typeof state.filternew.limit !== 'undefined') action.data.limit = state.filternew.limit;
      }
      return {
        ...state,
        filternew: action.data,
      }
    }

    case UPDATE_FILTERnew1:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filternew1 !== 'undefined' && state.filternew1) {
        if (action.data.page == 'no_update' && typeof state.filternew1.page !== 'undefined') action.data.page = state.filternew1.page;
        if (action.data.limit == 'no_update' && typeof state.filternew1.limit !== 'undefined') action.data.limit = state.filternew1.limit;
      }
      return {
        ...state,
        filternew1: action.data,
      }
    }

    case REPORT_9332_QUERY: {
      return {
        ...state,
        query9332: action.data,
      }
    }

    case REPORT_9084_QUERY: {
      return {
        ...state,
        query9084: action.data,
      }
    }

    case REPORT_9084new_QUERY: {
      return {
        ...state,
        query9084new: action.data,
      }
    }

    case REPORT_9084new1_QUERY: {
      return {
        ...state,
        query9084new1: action.data,
      }
    }

    case REPORT_9332_SUCCESS: {
      console.log("REPORT_9332_SUCCESS: ", action.data.items)
      return {
        ...state,
        items9332: action.data.items,
        total9332: action.data.total,
      }
    }

    case REPORT_9084_SUCCESS: {
      return {
        ...state,
        items9084: action.data.items,
        total9084: action.data.total,
      }
    }

    case REPORT_9084new_SUCCESS: {
      return {
        ...state,
        items9084new: action.data.items,
        total9084new: action.data.total,
      }
    }

    case REPORT_9084new1_SUCCESS: {
      return {
        ...state,
        items9084new1: action.data.items,
        total9084new1: action.data.total,
      }
    }

    default: {
      return state;
    }
  }
}
export default reportReducer