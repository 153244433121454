import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CauhinhgsmAction } from 'src/actions/cauhinhgsm.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getCauhinhgsmStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';

import CreateEditDialog from './CreateEditDialog';
import CauhinhgsmcomlistDialog from './CauhinhgsmcomlistDialog';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import numeral from 'numeral';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { showToast } from 'src/utils/common';

import { Edit, Download, Cached } from '@material-ui/icons';

const CauhinhgsmLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedCauhinhgsmIds, setSelectedCauhinhgsmIds] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role } = useSelector(state => state.profile);
  const { items, total, filter } = useSelector(state => state.cauhinhgsm);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [openCauhinhgsmcomlist, setOpenCauhinhgsmcomlist] = useState(false);
  const [id_don_tong, setIdDonTong] = useState("");
  const [item, setItemValue] = useState({});
  const [itemCheckAll, setItemCheckAll] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const { isLoading } = useSelector(state => state.app);

  const [values, setValues] = useState({
    ma_tram: "",
    ten_tram: "",
    loai_dich_vu: "-99",
    trang_thai: "-99",
  });

  const extractedSelectedItems = () => {
    const extractedObj = {};

    // Iterate over the object's keys
    for (const key in item) {
      // Check if key is -1 or value is 0, then copy the property to extractedObj
      if (key != '-1' && item[key] != 0) {
        extractedObj[key] = item[key];
      }
    }

    return Object.keys(extractedObj);
  }

  const handleEdit = () => {
    const selectedItems = extractedSelectedItems()
    console.log(selectedItems)
    if (selectedItems.length < 1) {
      alert('Chưa chọn mục nào để sửa')
    } else {
      try {
        let selectedItem = selectedItems[0];
        setItemValue({[selectedItem] : 1})
        setItemCheckAll(false);
        dispatch(CauhinhgsmAction.findOne(selectedItem));
        setIsUpdate(true)
        setOpen(true)
      } catch (error) {
        //
      }
    }
  }

  const handleDelete = () => {
    const selectedItems = extractedSelectedItems()
    console.log(selectedItems)
    if (selectedItems.length < 1) {
      alert('Chưa chọn mục nào để xóa')
    } else {
      let question = "Bạn có muốn xóa các mục đã chọn?";
      if (window.confirm(question)) {
        try {
          dispatch(CauhinhgsmAction.xoaMucDaChon(selectedItems));
          showToast('success', `Đã xóa các mục đã chọn`);
        } catch (error) {
          //
        }
      } else {
        
      }
    }
  }

  const setItem = (e, value) => {
    setItemValue({
      ...item,
      [value]: e.target.value == 1 ? 0 : 1
    });
  }

  const setItemCheckAllOnChange = (event) => {
    console.log(event.target.checked);

    if (event.target.checked) {
      setItemCheckAll(1);
      chonItem(event);
    } else {
      setItemCheckAll(0);
      boChonItem(event);
    }
  };

  const chonItem = (event) => {
    // if (item && Object.entries(item).length > 0) {
    //   let newItems = {}
    //   Object.entries(item).forEach(([key, value]) => {
    //     newItems[key] = 1;
    //   });
    //   setItemValue(newItems);
    // }

    if (items && items.length > 0) {
      let newItems = {}
      for (let i = 0; i < limit; i++) {
        const key = page * limit + i;
        const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
        newItems[id] = 1;
      }
      setItemValue(newItems);
    }
  };

  const boChonItem = (event) => {
    // if (item && Object.entries(item).length > 0) {
    //   let newItems = {}
    //   Object.entries(item).forEach(([key, value]) => {
    //     newItems[key] = 0;
    //   });
    //   setItemValue(newItems);
    // }

    if (items && items.length > 0) {
      let newItems = {}
      for (let i = 0; i < limit; i++) {
        const key = page * limit + i;
        const id = typeof items[key] !== 'undefined' ? items[key].id : -1;
        newItems[id] = 0;
      }
      setItemValue(newItems);
    }
  };

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
      ma_tram: values.ma_tram,
      ten_tram: values.ten_tram,
      loai_dich_vu: values.loai_dich_vu,
      trang_thai: values.trang_thai,
    };
    dispatch(CauhinhgsmAction.updateFilter(payload));

    const getItemsList = async () => {
      const list = await dispatch(CauhinhgsmAction.list(page, limit));
      if (list && typeof list.data !== 'undefined' && list.data && typeof list.data.values !== 'undefined' && list.data.values) {
        let newItems = {}
        list.data.values.forEach((item, index) => {
          newItems[item.id] = 0;
        });
        setItemValue(newItems);
      }
    };

    getItemsList()
  }, [page, limit])

  const onLookup = async (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      ma_tram: typeof immediatelyPayload.ma_tram !== 'undefined' ? immediatelyPayload.ma_tram : values.ma_tram,
      ten_tram: typeof immediatelyPayload.ten_tram !== 'undefined' ? immediatelyPayload.ten_tram : values.ten_tram,
      loai_dich_vu: typeof immediatelyPayload.loai_dich_vu !== 'undefined' ? immediatelyPayload.loai_dich_vu : values.loai_dich_vu,
      trang_thai: typeof immediatelyPayload.trang_thai !== 'undefined' ? immediatelyPayload.trang_thai : values.trang_thai,
    };
    dispatch(CauhinhgsmAction.updateFilter(payload));
    dispatch(CauhinhgsmAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const exportExcel = async (ma_don = null) => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        username: values.username,
        phone: values.phone,
        ma_don: ma_don == null ? values.ma_don : ma_don,
        kenh_yeu_cau: values.kenh_yeu_cau,
        trang_thai: values.trang_thai,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(CauhinhgsmAction.updateFilter(payload));
      const response = await dispatch(CauhinhgsmAction.listExport());

      const data = [
        [
          'Mã GSM', 
          'Tên GSM',
          'Mã máy trạm', 
          'Loại thiết bị',
          'Bắn tiền',
          'Nạp sim', 
          'Nạp trả trước', 
          'Nạp trả sau', 
          'Cổng', 
          'Trạng thái',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          data.push([
            element.ma_nhom,
            element.ten_nhom,
            element.ma_may_tram,
            element.loai_thiet_bi,
            element.ban_tien ? 'Có' : 'Không',
            element.nap_sim ? 'Có' : 'Không',
            element.nap_tra_truoc ? 'Có' : 'Không',
            element.nap_tra_sau ? 'Có' : 'Không',
            element.trang_thai ? 'Hoạt động' : 'Ngừng hoạt động',
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Danh-sach-cau-hinh-gsm.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({[event.target.name]: event.target.value});
  };

  const handleSelectAll = (event) => {
    let newSelectedCauhinhgsmIds;

    if (event.target.checked) {
      newSelectedCauhinhgsmIds = items.map((cauhinhgsm) => cauhinhgsm.id);
    } else {
      newSelectedCauhinhgsmIds = [];
    }

    setSelectedCauhinhgsmIds(newSelectedCauhinhgsmIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCauhinhgsmIds.indexOf(id);
    let newSelectedCauhinhgsmIds = [];

    if (selectedIndex === -1) {
      newSelectedCauhinhgsmIds = newSelectedCauhinhgsmIds.concat(selectedCauhinhgsmIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCauhinhgsmIds = newSelectedCauhinhgsmIds.concat(selectedCauhinhgsmIds.slice(1));
    } else if (selectedIndex === selectedCauhinhgsmIds.length - 1) {
      newSelectedCauhinhgsmIds = newSelectedCauhinhgsmIds.concat(selectedCauhinhgsmIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCauhinhgsmIds = newSelectedCauhinhgsmIds.concat(
        selectedCauhinhgsmIds.slice(0, selectedIndex),
        selectedCauhinhgsmIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCauhinhgsmIds(newSelectedCauhinhgsmIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, cauhinhgsm) => {
    setSelected(cauhinhgsm);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(CauhinhgsmAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleClickOpen = () => {
    setIsUpdate(false)
    setOpen(true);
  };

  const handleEditOpen = (id) => {
    dispatch(CauhinhgsmAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCauhinhgsmcomlist = () => {
    console.log("close")
    setIdDonTong(undefined);
    setOpenCauhinhgsmcomlist(false);
  };

  const handleOpenCauhinhgsmcomlist = (id) => {
    setIdDonTong(id);
    setOpenCauhinhgsmcomlist(true);
  };

  const batTatLoaiDichVu = (event, id, yeu_cau) => {
    console.log(id, yeu_cau);
    dispatch(CauhinhgsmAction.batTatLoaiDichVu(id, {yeu_cau}));
  }

  const danhsachcong = (inputString = null) => {
    if (inputString) {
      // Split input string into segments
      const segments = inputString.split(', ');
  
      // Sort segments based on the numbers
      segments.sort((a, b) => {
        const numberA = parseInt(a.split('|')[0]);
        const numberB = parseInt(b.split('|')[0]);
        return numberA - numberB;
      });
  
      // Map sorted segments to JSX elements
      const renderedSegments = segments.map((segment, index) => {
        const [number, colorCode] = segment.split('|');
        const color = colorCode === '1' ? 'green' : 'red';
  
        // Check if it's not the last segment
        const isLastSegment = index === segments.length - 1;
        // Add a comma and space if it's not the last segment
        const separator = isLastSegment ? '' : ', ';
  
        return (
          <span key={number}>
            <span style={{ color, fontWeight: 'bold' }}>{number}</span>
            {separator}
          </span>
        );
      });
  
      return renderedSegments;
    }
    return (<span></span>);
  }

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PerfectScrollbar>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 1050,
            }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="ma_tram"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.ma_tram}
                placeholder="Mã/Tên nhóm, Mã trạm..."
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
              />
              {/* <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="ten_tram"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.ten_tram}
                placeholder="Tìm theo tên trạm"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
              /> */}
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="loai_dich_vu"
                value={values.loai_dich_vu || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Loại dịch vụ...</MenuItem>
                <MenuItem value="tkc">TKC</MenuItem>
                <MenuItem value="nap_the">Nạp thẻ</MenuItem>
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "300px", marginLeft: 0 }}
                name="trang_thai"
                value={values.trang_thai || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Trạng thái...</MenuItem>
                <MenuItem value="hoat_dong">Hoạt động</MenuItem>
                <MenuItem value="ngung_hoat_dong">Ngừng hoạt động</MenuItem>
              </Select1>
            </Box>
          </PerfectScrollbar>
        </LocalizationProvider>
      </CardContent>
    </Card>
    <Card {...rest} style={{ width: "100%"}}>
      <div style={{width: '300px', margin: 'auto'}}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '0', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Thêm
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleEdit}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Sửa
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleDelete}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Xóa
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => exportExcel()}
          style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
          <Download style={{ color: "#3498db" }} />
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onLookup}
          style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
          {!isLoading ? <Cached style={{ color: "#3498db" }} /> : ''}
        </Button>
      </div>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table style={{marginTop: 15}}>
            <TableHead>
              <TableRow style={{background: 'red', height: '20px'}}>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  #
                </TableCell>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  <Checkbox
                      checked={itemCheckAll == 1 ? true : false}
                      onChange={(event) => setItemCheckAllOnChange(event)}
                      value={itemCheckAll == 1 ? 1 : 0}
                      style={{width: '20px', color: 'white'}}
                  />
                </TableCell>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mã
                </TableCell>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tên nhóm
                </TableCell>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Giới hạn
                </TableCell>
                <TableCell rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Bắn tiền
                </TableCell>
                <TableCell colSpan={3} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Nạp thẻ
                </TableCell>
                <TableCell colSpan={2} rowSpan={2} style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Cổng (COM)
                </TableCell>
                <TableCell rowSpan={2} style={{fontSize: 24, textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  ☑
                </TableCell>
              </TableRow>
              <TableRow style={{background: 'red', height: '20px'}}>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  SIM
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  TT
                </TableCell>
                <TableCell style={{textAlign: 'center', color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  TS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((cauhinhgsm, index) => (
                <TableRow
                  hover
                  key={cauhinhgsm.id}
                  selected={selectedCauhinhgsmIds.indexOf(cauhinhgsm.id) !== -1}
                >
                  <TableCell style={{textAlign: 'center'}}>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Checkbox
                      checked={item[cauhinhgsm.id] == 1 ? true : false}
                      onChange={(event) => setItem(event, cauhinhgsm.id)}
                      value={item[cauhinhgsm.id] == 1 ? 1 : 0}
                    />
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {cauhinhgsm.ma_nhom}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'left'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <span style={{fontWeight: 'bold'}}>{cauhinhgsm.ten_nhom}</span>
                      <span style={{color: 'red'}}> ({cauhinhgsm.nha_mang})</span>
                      <br></br>
                      <span style={{color: cauhinhgsm.trang_thai ? '#337ab7' : 'red'}}>[{cauhinhgsm.ma_may_tram}]</span>
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {cauhinhgsm.gioi_han_canh_bao > 0 && <span style={{color: 'orange'}}>{numeral(cauhinhgsm.gioi_han_canh_bao).format('0,0')}</span>}
                      <br></br>
                      {cauhinhgsm.gioi_han_off_cong > 0 && <span style={{color: 'red'}}>{numeral(cauhinhgsm.gioi_han_off_cong).format('0,0')}</span>}
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Checkbox
                        checked={cauhinhgsm.ban_tien}
                        onChange={(event) => batTatLoaiDichVu(event, cauhinhgsm.id, 'ban_tien')}
                        value={cauhinhgsm.ban_tien ? 1 : 2}
                        style={{width: '20px'}}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Checkbox
                        checked={cauhinhgsm.nap_sim}
                        onChange={(event) => batTatLoaiDichVu(event, cauhinhgsm.id, 'nap_sim')}
                        value={cauhinhgsm.nap_sim ? 1 : 2}
                        style={{width: '20px'}}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Checkbox
                        checked={cauhinhgsm.nap_tra_truoc}
                        onChange={(event) => batTatLoaiDichVu(event, cauhinhgsm.id, 'nap_tra_truoc')}
                        value={cauhinhgsm.nap_tra_truoc ? 1 : 2}
                        style={{width: '20px'}}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Checkbox
                        checked={cauhinhgsm.nap_tra_sau}
                        onChange={(event) => batTatLoaiDichVu(event, cauhinhgsm.id, 'nap_tra_sau')}
                        value={cauhinhgsm.nap_tra_sau ? 1 : 2}
                        style={{width: '20px'}}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'center'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <a
                        href='#'
                        target="_blank"
                        style={{fontSize: 24}}
                        onClick={(e) => {
                          e.preventDefault();

                          //do not un-comment eslint lines
                          /* eslint-disable no-restricted-globals */
                          // const winWidth = screen.availWidth;
                          // const winHeight = screen.availHeight;
                          /* eslint-enable no-restricted-globals */
                          // window.open(
                          //   `${window.location.origin}/sys/cauhinhgsm/cauhinhgsmcomlist/${cauhinhgsm.id}`,
                          //   `${cauhinhgsm.id}`,
                          //   `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${winWidth},height=${winHeight}`
                          // );
                          
                          handleOpenCauhinhgsmcomlist(cauhinhgsm.id);
                        }}
                      >
                        <Edit style={{ color: "#e67e22" }} />
                      </a>
                    </Typography>
                  </TableCell>
                  <TableCell style={{textAlign: 'left'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {danhsachcong(cauhinhgsm.com_list)}
                    </Typography>
                  </TableCell>
                  <TableCell style={{paddingLeft: 0, paddingRight: 0, textAlign: 'center', backgroundColor: cauhinhgsm.trang_thai ? 'green' : 'red'}}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <Checkbox
                        checked={cauhinhgsm.trang_thai}
                        onChange={(event) => batTatLoaiDichVu(event, cauhinhgsm.id, 'trang_thai')}
                        value={cauhinhgsm.trang_thai ? 1 : 2}
                        style={{width: '20px', color: 'white'}}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CauhinhgsmcomlistDialog open={openCauhinhgsmcomlist} handleClose={handleCloseCauhinhgsmcomlist} type="EDIT" id_don_tong={id_don_tong} />
      <CreateEditDialog open={open} handleClose={handleClose} type={isUpdate ? "EDIT" : "CREATE"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card></>
  );
};

export default CauhinhgsmLookupDetail;
