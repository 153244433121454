import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { MachuyentienAction } from 'src/actions/machuyentien.action';
import { BankAction } from 'src/actions/bank.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getMachuyentienStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  Select as Select1,
  MenuItem,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';

import Select from 'react-select';

import { Search as SearchIcon } from 'react-feather';

import CreateEditDialog from './CreateEditDialog';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AccountAction } from 'src/actions/account.acction';

const MachuyentienLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedMachuyentienIds, setSelectedMachuyentienIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { role } = useSelector(state => state.profile);
  const { items, total, filter, tongChuyenKhoan, tongDonChuyen } = useSelector(state => state.bank);
  const [selectedBankIds, setSelectedBankIds] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [maChuyenKhoan, setMaChuyenKhoan] = useState("");
  const [thongTinChuyenKhoan, setThongTinChuyenKhoan] = useState([]);
  const [values, setValues] = useState({
    username: "",
    message: "",
    is_read: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const [autoBankAmount, setAutoBankAmount] = useState(0);
  const { items: account_items, total: account_total } = useSelector(state => state.account)

  const allOption = { username: '', fullname: 'Tất cả', role: { priority: 0 } };
  const options = [allOption, ...account_items];
  
  useEffect(() => {
    async function fetchData() {
      const maChuyenKhoan = await dispatch(BankAction.getNoiDungChuyenKhoan());
      setMaChuyenKhoan(maChuyenKhoan)

      const getThongTinChuyenKhoan = await dispatch(BankAction.getThongTinChuyenKhoan());
      setThongTinChuyenKhoan(getThongTinChuyenKhoan)
    }
    fetchData();
    dispatch(AccountAction.listNoLimit());
  }, [])

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(BankAction.updateFilter(payload));
    dispatch(BankAction.list());
  }, [page, limit])

  const onAutoBankAmountChange = event => {
    event.target.value = numeral(event.target.value).format('0,0')
    setAutoBankAmount(Number(event.target.value.replace(/\,/g, "")))

    if (isNaN(event.target.value.replace(/\,/g, ""))) {
      setAutoBankAmount(0)
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const onLookup = (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: typeof immediatelyPayload.username !== 'undefined' ? immediatelyPayload.username : values.username,
      message: typeof immediatelyPayload.message !== 'undefined' ? immediatelyPayload.message : values.message,
      is_read: typeof immediatelyPayload.is_read !== 'undefined' ? immediatelyPayload.is_read : values.is_read,
      startDate: typeof immediatelyPayload.startDate !== 'undefined' ? immediatelyPayload.startDate.format("DD/MM/YYYY") : values.startDate.format("DD/MM/YYYY"),
      endDate: typeof immediatelyPayload.endDate !== 'undefined' ? immediatelyPayload.endDate.format("DD/MM/YYYY") : values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(BankAction.updateFilter(payload));
    dispatch(BankAction.list())
  };

  const handleStartDateChange = (date) => {
    setValues({
      ...values,
      startDate: date
    });

    onLookup({ startDate: date });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });

    onLookup({ endDate: date });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({ [event.target.name]: event.target.value });
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });

    onLookup({[name]: newValue});
  };

  const setTrangThaiXuLy = async (id, is_read) => {
    console.log("Đổi trạng thái xử lý", id, is_read)

    const result = await dispatch(BankAction.setTrangThaiXuLy(id, { is_read }))
  };

  const handleSelectAll = (event) => {
    let newSelectedMachuyentienIds;

    if (event.target.checked) {
      newSelectedMachuyentienIds = items.map((machuyentien) => machuyentien.id);
    } else {
      newSelectedMachuyentienIds = [];
    }

    setSelectedMachuyentienIds(newSelectedMachuyentienIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedMachuyentienIds.indexOf(id);
    let newSelectedMachuyentienIds = [];

    if (selectedIndex === -1) {
      newSelectedMachuyentienIds = newSelectedMachuyentienIds.concat(selectedMachuyentienIds, id);
    } else if (selectedIndex === 0) {
      newSelectedMachuyentienIds = newSelectedMachuyentienIds.concat(selectedMachuyentienIds.slice(1));
    } else if (selectedIndex === selectedMachuyentienIds.length - 1) {
      newSelectedMachuyentienIds = newSelectedMachuyentienIds.concat(selectedMachuyentienIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedMachuyentienIds = newSelectedMachuyentienIds.concat(
        selectedMachuyentienIds.slice(0, selectedIndex),
        selectedMachuyentienIds.slice(selectedIndex + 1)
      );
    }

    setSelectedMachuyentienIds(newSelectedMachuyentienIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, machuyentien) => {
    setSelected(machuyentien);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(MachuyentienAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(MachuyentienAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approve = async (id, trang_thai_duyet) => {
    const question = trang_thai_duyet == 1 ? "Xác nhận duyệt đơn này?" : "Xác nhận từ chối đơn này?";
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MachuyentienAction.approve(id, { trang_thai_duyet }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const setTrangThai = async (id, trang_thai) => {
    const question = trang_thai == 'SUCCESS' ? "Xác nhận đặt trạng thái 'Thành công'?" : "Xác nhận đặt trạng thái 'Thất bại'?";
    if (window.confirm(question)) {
      try {
        const response = await dispatch(MachuyentienAction.setTrangThai(id, { trang_thai }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  function replaceAll(inputString, search, replacement) {
    // Create a regular expression with the 'g' flag to replace all occurrences
    const regex = new RegExp(search, 'g');

    // Use the replace method with the regular expression to replace all occurrences
    const result = inputString.replace(regex, replacement);

    return result;
  }

  return (
    <>
      <Card {...rest} style={{ width: "100%" }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <h3 style={{ textAlign: 'center', color: 'blue', marginBottom: '15px' }}>Nội dung chuyển khoản Autobank</h3>
            <Table>
              <TableBody>
                {thongTinChuyenKhoan && thongTinChuyenKhoan.map((ttck, index) => (
                  <>
                    <TableRow>
                      <TableCell className="textAlignRight" style={{ fontWeight: 'bold', width: '50%' }}>
                        BANK {page * limit + index + 1}:
                      </TableCell>
                      <TableCell className="textAlignLeft" style={{ width: '10%' }}>
                        {ttck.nganHang.toUpperCase()}
                      </TableCell>
                      <TableCell rowSpan="3" className="textAlignLeft" style={{ width: '40%' }}>
                        <img style={{ width: '100px', height: '100px' }} src={`https://img.vietqr.io/image/${ttck.nganHang}-${ttck.soTaiKhoan}-qr_only.jpg?amount=${autoBankAmount}&addInfo=EZ${maChuyenKhoan}`} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="textAlignRight" style={{ fontWeight: 'bold', width: '50%' }}>
                        TÀI KHOẢN:
                      </TableCell>
                      <TableCell className="textAlignLeft" style={{ width: '10%' }}>
                        {ttck.soTaiKhoan}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="textAlignRight" style={{ fontWeight: 'bold', width: '50%' }}>
                        TÊN CTK:
                      </TableCell>
                      <TableCell className="textAlignLeft" style={{ width: '10%' }}>
                        {ttck.chuTaiKhoan}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{height: 30}}></TableCell>
                    </TableRow>
                  </>
                ))}
                <TableRow>
                  <TableCell className="textAlignRight" style={{ fontWeight: 'bold', width: '50%' }}>
                    NỘI DUNG CK:
                  </TableCell>
                  <TableCell className="textAlignLeft" style={{ width: '10%' }}>
                    <Button style={{ padding: 0, color: 'blue', fontSize: 20, width: 'auto', minWidth: 0 }} onClick={() => copyToClipboard(`EZ${maChuyenKhoan}`)}>
                      {`EZ${maChuyenKhoan}`}
                    </Button>
                  </TableCell>
                  <TableCell style={{ width: '40%' }}></TableCell>
                </TableRow>
                <TableRow style={{ height: 50 }}>
                  <TableCell className="textAlignRight" style={{ fontWeight: 'bold', width: '50%' }}>
                    SỐ TIỀN MUỐN NẠP:
                  </TableCell>
                  <TableCell className="textAlignLeft" style={{ width: '10%' }}>
                    <TextField
                      size="small"
                      placeholder="Số tiền"
                      variant="outlined"
                      onChange={onAutoBankAmountChange}
                    />
                  </TableCell>
                  <TableCell style={{ width: '40%' }}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>

      <Card className="filter_card" style={{ marginTop: '15px' }}>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: 56,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                  }}
                  options={options}
                  getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username != '' ? (item.username + ' - ') : ''}${item.fullname}`}
                  placeholder={values.username || "Tìm user..."}
                  components={{
                    DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                    IndicatorSeparator: null,
                  }}
                  value={values.username || null}
                  onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                  isSearchable
                />
              </div>
              <DatePicker
                label="Thời gian bắt đầu"
                style={{ width: '100%', marginRight: 24 }}
                format='DD/MM/YYYY'
                value={values.startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
              />
              <DatePicker
                label="Thời gian kết thúc"
                style={{ width: '100%', marginLeft: 24 }}
                value={values.endDate}
                format='DD/MM/YYYY'
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="message"
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                value={values.message}
                placeholder="Tìm tin nhắn"
                variant="outlined"
              />
              <Select1
                style={{ height: 56, width: "200px", marginLeft: 0, color: values.is_read == 1 ? 'green' : (values.is_read == 2 ? 'red' : 'black') }}
                name="is_read"
                value={values.is_read || -99}
                onChange={(newValue) => handleMessageChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Trạng thái xử lý...</MenuItem>
                <MenuItem value="1" style={{ color: 'green' }}>Đã xử lý</MenuItem>
                <MenuItem value="2" style={{ color: 'red' }}>Chưa xử lý</MenuItem>
              </Select1>
              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 0, width: 200, marginLeft: 24 }}
              >
                Tra cứu
              </Button>
            </Box>
          </LocalizationProvider>
        </CardContent>
      </Card>

      <Card {...rest} style={{ width: "100%" }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: "green" }}>
                    TỔNG CHUYỂN KHOẢN
                  </TableCell>
                  <TableCell style={{ color: "green" }}>
                    {numeral(tongChuyenKhoan).format('0,0')}
                  </TableCell>
                  <TableCell style={{ color: "green" }}>
                    TỔNG ĐƠN CHUYỂN
                  </TableCell>
                  <TableCell style={{ color: "green" }}>
                    {numeral(tongDonChuyen).format('0,0')}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {role.priority < 3 && <TableCell></TableCell>}
                  {/* <TableCell></TableCell>
                <TableCell></TableCell>
                {role.priority < 3 && <TableCell></TableCell>}
                {role.priority < 3 && <TableCell></TableCell>}
                <TableCell></TableCell> */}
                </TableRow>
                <TableRow style={{ background: 'red' }}>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    #
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    User nhận CK
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Kênh
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Số tiền
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Thời gian
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Nội dung
                  </TableCell>
                  {role.priority < 3 && <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Trạng thái tự động xử lý
                  </TableCell>}
                  {/* <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Số điện thoại
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Gói cước
                </TableCell>
                {role.priority < 3 && <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Người cập nhật
                </TableCell>}
                {role.priority < 3 && <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Trạng thái duyệt cập nhật
                </TableCell>}
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Hành động
                </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.slice(page * limit, (page + 1) * limit).map((bank, index) => (
                  <TableRow
                    hover
                    key={bank.id}
                    selected={selectedBankIds.indexOf(bank.id) !== -1}
                  >
                    <TableCell>
                      {page * limit + index + 1}
                    </TableCell>
                    <TableCell>
                      {bank.username} - {bank.fullname}
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {bank.sender}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {numeral(bank.money).format('0,0')}
                    </TableCell>
                    <TableCell>
                      {moment(bank.datetime).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      {replaceAll(bank.str_mes, 'Đại lý chuyển khoản', 'AUTOBANK đại lý cộng số dư')}
                    </TableCell>
                    {role.priority < 3 && <TableCell>
                      <Select1 style={{ fontSize: "14px", width: "100%", color: bank.is_read ? 'green' : 'red' }} variant="outlined" value={bank.is_read} onChange={(e) => setTrangThaiXuLy(bank.id, e.target.value)}>
                        <MenuItem value="0">Chưa xử lý</MenuItem>
                        <MenuItem value="1">Đã xử lý</MenuItem>
                      </Select1>
                    </TableCell>}
                    {/* <TableCell>
                    {bank.is_read == 0 && <TextField
                      name='so_dien_thoai'
                      onChange={(e) => handleUpdate(bank.id, 'so_dien_thoai', e.target.value)}
                      value={(typeof bankUpdate[bank.id] !== 'undefined' && typeof bankUpdate[bank.id]['so_dien_thoai'] !== 'undefined' ? bankUpdate[bank.id]['so_dien_thoai'] : '') || bank.so_dien_thoai}
                      placeholder="Số điện thoại"
                      variant="outlined"
                      disabled={role.priority > 2 && bank.so_dien_thoai != null && bank.so_dien_thoai.trim() != '' ? true : false}
                      style={{ height: 56, width: 150, marginLeft: 0 }}
                    />}
                    {bank.is_read == 1 && bank.so_dien_thoai}
                  </TableCell>
                  <TableCell>
                    {bank.is_read == 0 && <TextField
                      name='goi_cuoc'
                      onChange={(e) => handleUpdate(bank.id, 'goi_cuoc', e.target.value)}
                      value={(typeof bankUpdate[bank.id] !== 'undefined' && typeof bankUpdate[bank.id]['goi_cuoc'] !== 'undefined' ? bankUpdate[bank.id]['goi_cuoc'] : '') || bank.goi_cuoc}
                      placeholder="Gói cước"
                      variant="outlined"
                      disabled={role.priority > 2 && bank.goi_cuoc != null && bank.goi_cuoc.trim() != '' ? true : false}
                      style={{ height: 56, width: 150, marginLeft: 0 }}
                    />}
                    {bank.is_read == 1 && bank.goi_cuoc}
                  </TableCell>
                  {role.priority < 3 && <TableCell>
                    {bank.nguoi_cap_nhat}
                  </TableCell>}
                  {role.priority < 3 && <TableCell style={{color: bank.trang_thai_duyet_thong_tin == 1 ? 'green' : bank.trang_thai_duyet_thong_tin == 2 ? 'red' : ''}}>
                    {bank.trang_thai_duyet_thong_tin == 1 ? 'Đã duyệt' : (bank.trang_thai_duyet_thong_tin == 2 ? 'Đã từ chối' : '')}
                  </TableCell>}
                  <TableCell>
                    {bank.is_read == 0 && 
                      <>{(role.priority < 3 || role.priority > 2 && (!bank.so_dien_thoai || !bank.goi_cuoc)) && <Button onClick={() => updateChange(bank.id, bank.so_dien_thoai, bank.goi_cuoc)}>
                        Cập nhật
                      </Button>}
                      {role.priority < 3 && bank.so_dien_thoai && bank.goi_cuoc && !bank.username_duyet_trang_thai_duyet_thong_tin && <><Button style={{color: 'green'}} onClick={() => approve(bank.id)}>
                        Duyệt
                      </Button>
                      <Button style={{color: 'red'}} onClick={() => deny(bank.id)}>
                        Từ chối
                      </Button></>}</>
                    }
                  </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
        />
      </Card>
    </>
  );
};

export default MachuyentienLookupDetail;
