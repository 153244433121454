import { useState, useEffect } from 'react';

import { Search as SearchIcon } from 'react-feather';

import { useDispatch, useSelector } from 'react-redux';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import AccountCreateDialog from './AccountCreateDialog';
import { AccountAction } from 'src/actions/account.acction';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CreateEditDialogSelectSetting from './CreateEditDialogSelectSetting';

import { useStyles } from '../RotateStyles';
import { Settings, Download, Cached } from '@material-ui/icons';


const AccountListToolbar = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile);
  const [openSelectSetting, setOpenSelectSetting] = useState(false);
  const { isLoading } = useSelector(state => state.app);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenSelectSetting = () => {
    setOpenSelectSetting(true);
  };

  const handleCloseSelectSetting = () => {
    setOpenSelectSetting(false);
  };

  const [values, setValues] = useState({
    username: "",
  });

  const handleSearchChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({[event.target.name]: event.target.value});
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      username: "",
    };
    dispatch(AccountAction.updateFilter(payload));
  }, []);

  const onLookup = (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: typeof immediatelyPayload.username !== 'undefined' ? immediatelyPayload.username : values.username,
    };
    dispatch(AccountAction.updateFilter(payload));
    dispatch(AccountAction.list())
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <PerfectScrollbar>
              <Box sx={{ minWidth: 1050 }}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                          >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  name="username"
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  value={values.username}
                  placeholder="Tìm theo username NV"
                  variant="outlined"
                  style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
                />
              </Box>
            </PerfectScrollbar>
          </CardContent>
        </Card>
        <Card>
          <div style={{width: '250px', margin: 'auto'}}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
              style={{ height: '20px', padding: 0, marginTop: 0, width: "150px", minWidth: '20px', marginLeft: '0', backgroundColor: 'orange', fontWeight: 'normal' }}
              >
              Tạo người dùng
            </Button>
            {role.priority < 3 && <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpenSelectSetting}
              style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
              >
              <Settings className={classes.rotateIcon} style={{ color: "black" }} />
            </Button>}
            <Button
              color="primary"
              variant="contained"
              onClick={onLookup}
              style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
              >
              {!isLoading ? <Cached style={{ color: "#3498db" }} /> : ''}
            </Button>
          </div>
        </Card>
      </Box>
      <AccountCreateDialog open={open} handleClose={handleClose} />
      <CreateEditDialogSelectSetting open={openSelectSetting} handleClose={handleCloseSelectSetting} type={"SELECT_SETTING"} />
    </Box>
  )
}

export default AccountListToolbar;
