import { Helmet } from 'react-helmet';
import LsnaptienDetail
  from 'src/components/Lsnaptien-detail/LsnaptienDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

//import LsnaptienToolbar from '../components/Lsnaptien-detail/LsnaptienToolbar';

const Lsnaptien = () => (
  <>
    <Helmet>
      <title>Lịch sử nạp tiền</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        {/* <LsnaptienToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <LsnaptienDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Lsnaptien;
