import { Helmet } from 'react-helmet';
import Budget from '../components/dashboard/Budget';
import LatestOrders from '../components/dashboard/LatestOrders';
import LatestProducts from '../components/dashboard/LatestProducts';
import Sales from '../components/dashboard/Sales';
import TasksProgress from '../components/dashboard/TasksProgress';
import TotalCustomers from '../components/dashboard/TotalCustomers';
import TotalProfit from '../components/dashboard/TotalProfit';
import TrafficByDevice from '../components/dashboard/TrafficByDevice';
import { AppAction } from 'src/actions/app.action';

import {
  useEffect,
  useState,
  useRef ,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Container, 
  Grid
} from '@material-ui/core';

import { Save } from '@mui/icons-material';

import { isGrantPermission } from 'src/utils/utils';
import { SettingAction } from 'src/actions/setting.action';
import { emitter } from 'src/utils/emitter';

const Dashboard = () => {
  const dispatch = useDispatch();
  const textareaRef = useRef(null); // Ref for the textarea
  const [textareaHeight, setTextareaHeight] = useState('49px'); // State for textarea height
  const [thong_bao_value, setThongBaoValue] = useState('');
  const { info, permissions, role } = useSelector(state => state.profile)

  useEffect(() => {
    dispatch(AppAction.appLoading(false));

    async function fetchSettingThongBao() {
      const thongBao_res = await dispatch(SettingAction.findByKey('thong_bao'))
      setThongBaoValue(thongBao_res.data.value);

      setTimeout(() => {
        const textareaContentHeight = textareaRef.current.scrollHeight;
        setTextareaHeight(textareaContentHeight + 'px');
      }, 0);
    }
  fetchSettingThongBao();
  }, [])

  const luuThongBao = async () => {
    const response = await dispatch(SettingAction.update("thong_bao", { value: textareaRef.current.value }))
    if (typeof response.status !== 'undefined' && response.status == 'success') alert("Cập nhật cài đặt thành công");
  };

  const handleTextareaChange = () => {
    setThongBaoValue(textareaRef.current.value);
    const { scrollHeight } = textareaRef.current;
    setTextareaHeight(scrollHeight + 'px');
  };

  const addTab = (component = null) => {
    if (component != null) {
      emitter.emit("add_tab", component)
    }
  }

  return (
    <>
      <Helmet>
        <title>Bảng điều khiển</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            {isGrantPermission(permissions, ['cauhinhgsm']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='/sys/cauhinhgsm'> */}
              <a href='#' onClick={() => addTab('Cauhinhgsm')}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/cot_song.png'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    Cấu hình GSM
                  </Typography>
                </div>
              </a>
            </Grid>}
            {isGrantPermission(permissions, ['quanlynapcuoc']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='/sys/quanlynapcuoc'> */}
              <a href='#' onClick={() => addTab('Quanlynapcuoc')}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/the_cao.jpg'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    QL nạp cước
                  </Typography>
                </div>
              </a>
            </Grid>}
            {isGrantPermission(permissions, ['quanlybantien']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='#'> */}
              <a href='#' onClick={() => addTab()}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/ban_tien.png'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    QL bắn tiền
                  </Typography>
                </div>
              </a>
            </Grid>}
            {isGrantPermission(permissions, ['quanlythenap']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='/sys/quanlythenap'> */}
              <a href='#' onClick={() => addTab('Quanlythenap')}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/bien_ban.png'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    QL thẻ nạp
                  </Typography>
                </div>
              </a>
            </Grid>}
            {isGrantPermission(permissions, ['user.get', 'user.edit', 'user.add', 'user.delete']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='/sys/accounts'> */}
              <a href='#' onClick={() => addTab('AccountList')}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/user.png'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    QL đại lý
                  </Typography>
                </div>
              </a>
            </Grid>}
            {isGrantPermission(permissions, ['lssodu']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='/sys/lssodu'> */}
              <a href='#' onClick={() => addTab('Lssodu')}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/dong_tien.png'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    QL dòng tiền
                  </Typography>
                </div>
              </a>
            </Grid>}
            {isGrantPermission(permissions, ['mobi_danhsachdon']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='/app/mobifone/danhsachdon'> */}
              <a href='#' onClick={() => addTab('MobifoneDanhSachDon')}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/ban_tien_tkc.jpg'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    Bắn tiền TKC
                  </Typography>
                </div>
              </a>
            </Grid>}
            {isGrantPermission(permissions, ['mobi_mobifonemoney']) && <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={6}
              style={{padding: '0', marginTop: '50px'}}
            >
              {/* <a href='/app/mobifone/napmymobifone'> */}
              <a href='#' onClick={() => addTab('NapMyMobifone')}>
                <div style={{borderRadius: '5px', border: '1px solid gray', width: '150px', height: 'auto', margin: 'auto', padding: '5px'}}>
                  <img style={{width: 'auto', height: '60px', margin: 'auto', display: 'flex', justifyContent: 'center'}} src='/static/images/nap_cuoc.png'></img>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{textAlign: 'center'}}
                  >
                    Nạp cước
                  </Typography>
                </div>
              </a>
            </Grid>}
          </Grid>
          <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{marginTop: '50px'}}
            >
              <Typography
                color="textPrimary"
                variant="body1"
                style={{textAlign: 'left', fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline', color: 'red'}}
              >
                * Thông báo 
                {/* {role.priority < 2 && <Button
                  color="primary"
                  variant="contained"
                  onClick={luuThongBao}
                  style={{ background: 'transparent', minWidth: 0, width: '20px', height: '20px', padding: 0, marginLeft: 15, fontSize: '20px', border: 'none' }}
                  >
                    <Save />
                </Button>} */}

                {role.priority < 2 && <Save style={{ color: "black", cursor: "pointer", padding: 0, marginLeft: 15, marginTop: 15 }} onClick={luuThongBao} />}
              </Typography>
              {role.priority < 2 && <textarea
                value={thong_bao_value}
                ref={textareaRef} // Assign ref to textarea
                style={{ width: '100%', borderRadius: '5px', padding: '15px', height: textareaHeight }} // Apply dynamic height
                onChange={handleTextareaChange} // Listen for textarea changes
              />}
              {role.priority >= 2 && <textarea
                value={thong_bao_value}
                ref={textareaRef} // Assign ref to textarea
                style={{ width: '100%', borderRadius: '5px', padding: '15px', height: textareaHeight }} // Apply dynamic height
                readOnly
              />}
            </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
