import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    ".filter_card": {
      overflow: 'visible !important',
    },
    ".filter_card .scrollbar-container": {
      overflow: 'visible !important',
    },
    ".filter_card .MuiCardContent-root": {
      padding: "0px !important",
    },
    //inputs' container
    ".filter_card .MuiBox-root": {
      height: "50px !important",
    },
    //container of each input
    ".filter_card .MuiFormControl-root": {
      height: "30px !important",
    },
    //general input
    ".filter_card .MuiOutlinedInput-root.MuiInputBase-root": {
      height: "30px !important",
      fontSize: "13px !important",
    },
    //Box search & select
    ".filter_card .full-width-select > div > div": {
      height: "auto !important",
      minHeight: "30px !important",
      fontSize: "13px !important",
    },
    ".full-width-select": {
      marginTop: '5px',
    },
    "#react-select-2-listbox": {
      height: "auto !important",
    },
    //Button
    ".filter_card .MuiButton-root": {
      height: "30px !important",
      fontSize: "10px !important",
    },
    //DatePicker
    ".filter_card .MuiFormControl-root:has(button)": {
      marginTop: "5px !important",
      width: "100%"
    },
    ".MuiSelect-select": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "td, th": {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
      paddingLeft: '2px !important',
      paddingRight: '2px !important',
      fontSize: '0.8rem !important',
      // borderLeft: '1px solid #D3D3D3 !important',
      // borderRight: '1px solid #D3D3D3 !important',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: '1px solid gray !important',
      borderBottom: '1px solid gray !important',
      textAlign: 'center !important',
    },
    ".setting_form td, .setting_form th": {
      border: 'none !important',
    },
    "td *": {
      fontSize: '0.75rem !important',
      textAlign: 'center !important',
    },
    "th *": {
      fontSize: '0.75rem !important',
      textAlign: 'center !important',
    },
    ".table_balance": {
      fontSize: '1rem !important',
      paddingTop: '5px',
      display: 'inline-block',
    },
    "textarea": {
      resize: 'none',
      overflow: 'hidden',
      minHeight: '50px',
      fontSize: '16px',
      lineHeight: '1.2',
    },
    ".btnGuiOtp": {
      height: '20px', 
      padding: '0px', 
      marginTop: 0, 
      width: "50px", 
      minWidth: '50px', 
      marginLeft: '10px', 
      backgroundColor: 'blue', 
      fontSize: '10px !important', 
      color: 'white',
    },
    ".txtNhapOtp": {
      height: '20px', 
      marginTop: '0px',
      width: '50% !important', 
      marginLeft: '10px',
      padding: '0 5px !important',
    },
    ".txtNhapOtp > div": {
      height: '100%',
    },
    ".txtNhapOtp > div > input": {
      fontSize: '10px !important',
      padding: '0px !important',
    },
    ".btnXacNhanOtp": {
      height: '20px !important', 
      padding: 0, 
      marginTop: '1px !important', 
      width: '20px !important', 
      minWidth: '20px', 
      marginLeft: '10px', 
      backgroundColor: 'transparent', 
      fontSize: '20px !important',
    },
    ".select_trang_thai": {
      width: '100px !important',
      marginLeft: '-10%',
    },
    ".select_trang_thai > div": {
      fontSize: '10px !important',
    },
    ".textAlignLeft": {
      textAlign: 'left !important',
    },
    ".textAlignRight": {
      textAlign: 'right !important',
    },
    '@media (max-width: 600px)': {
      "td, th": {
        fontSize: '1.55vh !important',
      },
      "td *": {
        fontSize: '1.55vh !important',
      },
      "th *": {
        fontSize: '1.55vh !important',
      },
      ".table_balance": {
        fontSize: '1.55vh !important',
      },
    },
    '.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
      boxShadow: "unset"
    }
    // ".MuiButton-root.active": {
    //   color: "red"
    // }
  }
}));

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
