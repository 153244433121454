import { Helmet } from 'react-helmet';
import QuanlynapcuocDetail
  from 'src/components/Quanlynapcuoc-detail/QuanlynapcuocDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

//import QuanlynapcuocToolbar from '../components/Quanlynapcuoc-detail/QuanlynapcuocToolbar';

const Quanlynapcuoc = () => (
  <>
    <Helmet>
      <title>Quản lý nạp cước</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        {/* <QuanlynapcuocToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <QuanlynapcuocDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Quanlynapcuoc;
