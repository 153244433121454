import { ThongkethengayService } from 'src/services/thongkethengay.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const THONGKETHENGAY_NEW_CARE_SUCCESS = 'THONGKETHENGAY:NEW_CARE_SUCCESS'
export const THONGKETHENGAY_LIST_SUCCESS = 'THONGKETHENGAY:FIND_ALL_SUCCESS'
export const THONGKETHENGAY_LIST_FAILD = 'THONGKETHENGAY:FIND_ALL_FAILD'
export const THONGKETHENGAY_COUNT_ASSIGN_SUCCESS = 'THONGKETHENGAY:COUNT_ASSIGN_SUCCESS'
export const THONGKETHENGAY_COUNT_ASSIGN_FAILD = 'THONGKETHENGAY:COUNT_ASSIGN_FAILD'

export const THONGKETHENGAY_CREATE_SUCCESS = 'THONGKETHENGAY:CREATE_SUCCESS'
export const THONGKETHENGAY_CREATE_FAILD = 'THONGKETHENGAY:CREATE_FAILD'

export const THONGKETHENGAY_IMPORT_SUCCESS = 'THONGKETHENGAY:IMPORT_SUCCESS'
export const THONGKETHENGAY_SAVE_SUCCESS = 'THONGKETHENGAY:SAVE_SUCCESS'
export const THONGKETHENGAY_SAVE_FAILD = 'THONGKETHENGAY:SAVE_SUCCESS'

export const THONGKETHENGAY_DEL_SUCCESS = 'THONGKETHENGAY:DEL_SUCCESS'
export const THONGKETHENGAY_DEL_FAILD = 'THONGKETHENGAY:DEL_FAILD'

export const THONGKETHENGAY_ONE_SUCCESS = 'THONGKETHENGAY:FIND_ONE_SUCCESS'
export const THONGKETHENGAY_ONE_FAILD = 'THONGKETHENGAY:FIND_ONE_FAILD'

export const THONGKETHENGAY_HISTORY_LIST_SUCCESS = 'THONGKETHENGAY:HISTORY_FIND_ALL_SUCCESS'
export const THONGKETHENGAY_HISTORY_LIST_FAILD = 'THONGKETHENGAY:HISTORY_FIND_ALL_FAILD'

export const THONGKETHENGAY_UPDATE_FILTER = 'THONGKETHENGAY:UPDATE_FILTER'

export const THONGKETHENGAYCOMLIST_LIST_SUCCESS = 'THONGKETHENGAYCOMLIST:FIND_ALL_SUCCESS'
export const THONGKETHENGAYCOMLIST_LIST_FAILD = 'THONGKETHENGAYCOMLIST:FIND_ALL_FAILD'

export const ThongkethengayAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    approve,
    updateFilter,
    batTatLoaiDichVu,
    xoaMucDaChon,
    findAllByOrder,
    yeuCauChayLaiCallback,
    handleSuaNhieuMuc,
}

function updateFilter(payload) {
    return { type: THONGKETHENGAY_UPDATE_FILTER, data: payload }
}

function handleSuaNhieuMuc(payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { thongkethengay } = getState();            
            // Perform your asynchronous operation (e.g., API call)
            ThongkethengayService.handleSuaNhieuMuc(payload)
            .then(response => {
                dispatch(success(response));
                dispatch(ThongkethengayAction.list());
                resolve(response);
            })
            .catch(error => {
                dispatch(failure(error));
                reject(error);
            });
        });
    };
    function success(data) { return { type: THONGKETHENGAY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_CREATE_FAILD, error } }
}

function yeuCauChayLaiCallback(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.yeuCauChayLaiCallback(id).then((rs) => {
            dispatch(success(rs.data));
            dispatch(ThongkethengayAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_CREATE_FAILD, error } }
}

function batTatLoaiDichVu(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            ThongkethengayService.batTatLoaiDichVu(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(ThongkethengayAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: THONGKETHENGAY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_CREATE_FAILD, error } }
}

function xoaMucDaChon(payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            ThongkethengayService.xoaMucDaChon(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(ThongkethengayAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: THONGKETHENGAY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_CREATE_FAILD, error } }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            ThongkethengayService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(ThongkethengayAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: THONGKETHENGAY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAY_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { thongkethengay } = getState()
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...thongkethengay.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAY_LIST_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_LIST_FAILD, error } }
}

function findAllByOrder(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { thongkethengay } = getState();
        const page = thongkethengay && typeof thongkethengay.filter !== 'undefined' && thongkethengay.filter && typeof thongkethengay.filter.page !== 'undefined' ? thongkethengay.filter.page : 0;
        const limit = thongkethengay && typeof thongkethengay.filter !== 'undefined' && thongkethengay.filter && typeof thongkethengay.filter.limit !== 'undefined' ? thongkethengay.filter.limit : 10;
        ThongkethengayService.findAllByOrder(id, page, limit, thongkethengay.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = thongkethengay.thongkethengaycomlist_items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                console.log({
                    items: uniqueItems,
                    total: rs.data.count
                })
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAYCOMLIST_LIST_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAYCOMLIST_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { thongkethengay } = getState();
        const page = thongkethengay && typeof thongkethengay.filter !== 'undefined' && thongkethengay.filter && typeof thongkethengay.filter.page !== 'undefined' ? thongkethengay.filter.page : 0;
        const limit = thongkethengay && typeof thongkethengay.filter !== 'undefined' && thongkethengay.filter && typeof thongkethengay.filter.limit !== 'undefined' ? thongkethengay.filter.limit : 10;
        
        return new Promise((resolve, reject) => {
            ThongkethengayService.findAll(page, limit, thongkethengay.filter).then((rs) => {
                if (page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count,
                        tong_nap: rs.data.tong_nap, 
                        thuc_nhan: rs.data.thuc_nhan, 
                        so_the_dung: rs.data.so_the_dung, 
                        so_the_sai: rs.data.so_the_sai,
                    }));
                } else {
                    const oldItems = thongkethengay.items;
                    const rsDataValues = rs.data.values;

                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count,
                        tong_nap: rs.data.tong_nap, 
                        thuc_nhan: rs.data.thuc_nhan, 
                        so_the_dung: rs.data.so_the_dung, 
                        so_the_sai: rs.data.so_the_sai,
                    }));
                }

                resolve(rs);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        });
    };
    function success(data) { return { type: THONGKETHENGAY_LIST_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: THONGKETHENGAY_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            ThongkethengayService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(ThongkethengayAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: THONGKETHENGAY_SAVE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            ThongkethengayService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(ThongkethengayAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: THONGKETHENGAY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(ThongkethengayAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(ThongkethengayAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAY_DEL_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAY_ONE_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(ThongkethengayAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { thongkethengay } = getState()
        dispatch(AppAction.appLoading(true));
        ThongkethengayService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...thongkethengay.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THONGKETHENGAY_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: THONGKETHENGAY_HISTORY_LIST_FAILD, error } }
}