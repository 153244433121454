import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Search as SearchIcon } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { RequestrefundreportAction } from 'src/actions/requestrefundreport.action';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  Select as Select1,
  MenuItem,
} from '@material-ui/core';

import Select from 'react-select';

import CreateEditDialogSelectChannel from './CreateEditDialogSelectChannel';
import CreateEditDialog from './CreateEditDialog';
import CreateEditDialog1 from './CreateEditDialog1';
import { SettingAction } from 'src/actions/setting.action';
import { isGrantPermission } from 'src/utils/utils';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import dayjs from 'dayjs';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { AccountAction } from 'src/actions/account.acction';

const RequestrefundreportToolbar = (props) => {
  const dispatch = useDispatch()
  const [openSelectChannel, setOpenSelectChannel] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);
  const { filter } = useSelector(state => state.requestrefundreport)
  const { items, total } = useSelector(state => state.account)

  const fileInput = React.useRef();

  const { info, permissions, role } = useSelector(state => state.profile)
  
  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      message: "",
      username: "",
      trang_thai_giao_dich_doi_tac: "",
      is_transfer: "",
      trang_thai_truy_thu_hoan_theo_goi: "",
      trang_thai_truy_thu_hoan_them: "",
      don_hoan_hoac_nap: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(RequestrefundreportAction.updateFilter(payload));
    dispatch(AccountAction.listNoLimit());
  }, []);

  const [values, setValues] = useState({
    message: "",
    username: "",
    trang_thai_giao_dich_doi_tac: "",
    is_transfer: "",
    trang_thai_truy_thu_hoan_theo_goi: "",
    trang_thai_truy_thu_hoan_them: "",
    don_hoan_hoac_nap: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleClickOpenSelectChannel = () => {
    setOpenSelectChannel(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(1).map((c) => ({
        phone: c[1],
        type: c[2],
        package: c[3],
        registerAt: c[4],
        expiredAt: c[5],
        packageCycle: c[6],
        state: c[7],
        status: "NORMAL",
        packageCanRegister: c[8]
      }));
      dispatch(RequestrefundreportAction.importData(temp));
      dispatch(AppAction.appLoading(false))
      setPreview(true)
      e.target.value = null;
    };
    reader.readAsBinaryString(f)
  }

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleCloseAssign = () => {
    fileInput.current.value = null;
    setAssign(false)
  }

  const handleOpenAssign = () => {
    dispatch(RequestrefundreportAction.countAssign())
    setAssign(true)
  }

  const handleCloseSelectChannel = () => {
    setOpenSelectChannel(false);
  };

  const handleClose = () => {
    setOpen(false);
    console.log(role);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleTrangThaiGiaoDichDoiTacChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleIsTransferChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleTrangThaiTruyThuHoanTheoGoiChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDonHoanHoacNapChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleTrangThaiTruyThuHoanThemChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      message: values.message,
      username: values.username,
      trang_thai_giao_dich_doi_tac: values.trang_thai_giao_dich_doi_tac,
      is_transfer: values.is_transfer,
      trang_thai_truy_thu_hoan_theo_goi: values.trang_thai_truy_thu_hoan_theo_goi,
      trang_thai_truy_thu_hoan_them: values.trang_thai_truy_thu_hoan_them,
      don_hoan_hoac_nap: values.don_hoan_hoac_nap,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(RequestrefundreportAction.updateFilter(payload));
    dispatch(RequestrefundreportAction.list())
  };

  const xuatFile = async () => {
    if (role.priority == 5) return;
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        message: values.message,
        username: values.username,
        trang_thai_giao_dich_doi_tac: values.trang_thai_giao_dich_doi_tac,
        is_transfer: values.is_transfer,
        trang_thai_truy_thu_hoan_theo_goi: values.trang_thai_truy_thu_hoan_theo_goi,
        trang_thai_truy_thu_hoan_them: values.trang_thai_truy_thu_hoan_them,
        don_hoan_hoac_nap: values.don_hoan_hoac_nap,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(RequestrefundreportAction.updateFilter(payload));
      const response = await dispatch(RequestrefundreportAction.list());
      console.log(response)

      const data = [
        [
          '#', 
          'Username nhân viên', 
          'Tên nhân viên', 
          'Số điện thoại', 
          'Loại thuê bao', 
          'Gói cước', 
          'Trạng thái', 
          'Yêu cầu - Hình thức',
          'Số tiền HOÀN hoặc NẠP',
          'Trạng thái truy thu tiền hoàn theo gói',
          'Người duyệt',
          'Số tiền hoàn thêm',
          'Trạng thái truy thu tiền hoàn thêm',
          'Người duyệt',
          'Số tiền sau chiết khấu',
          'Tỉ lệ chiết khấu (%)',
          'Thời gian yêu cầu',
          'Trạng thái chuyển khoản',
          'Nhân viên duyệt nạp tiền'
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data && typeof response.data.values !== 'undefined' && response.data.values && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let yeuCau = "";
          let tiLeChietKhau = "";
          let trangThaiTruyThuHoanTheoGoi = "";
          let trangThaiTruyThuHoanThem = "";

          if (element.request_type == 1) yeuCau = "Hoàn trước";
          else if (element.request_type == 2) yeuCau = "Hoàn sau";
          else if (element.request_type == 3) yeuCau = "Không hoàn";
          else if (element.request_type == 4) yeuCau = "Hoàn thêm";
          else if (element.request_type == 5) yeuCau = "Nạp tiền";

          if (element.request_method == 1) yeuCau += " - Gia hạn";
          else if (element.request_method == 2) yeuCau += " - Nâng cấp T1";
          else if (element.request_method == 3) yeuCau += " - Nâng cấp T2";
          else if (element.request_method == 4) yeuCau += " - Nâng cấp T3";

          if (element.trang_thai_truy_thu_hoan_theo_goi == 'binh_thuong') trangThaiTruyThuHoanTheoGoi = "Bình thường";
          else if (element.trang_thai_truy_thu_hoan_theo_goi == 'da_truy_thu') trangThaiTruyThuHoanTheoGoi = "Đã truy thu";
          else if (element.trang_thai_truy_thu_hoan_theo_goi == 'truy_thu') trangThaiTruyThuHoanTheoGoi = "Truy thu";

          if (element.trang_thai_truy_thu_hoan_them == 'binh_thuong') trangThaiTruyThuHoanThem = "Bình thường";
          else if (element.trang_thai_truy_thu_hoan_them == 'da_truy_thu') trangThaiTruyThuHoanThem = "Đã truy thu";
          else if (element.trang_thai_truy_thu_hoan_them == 'truy_thu') trangThaiTruyThuHoanThem = "Truy thu";

          data.push([
            count,
            element.staff_username,
            element.staff_fullname,
            role.priority < 3 ? element.number_phone.slice(-9) : ('****' + element.number_phone.slice(-5)),
            element.number_type == 1 ? "MC" : "MG",
            element.package_code,
            element.trang_thai_giao_dich_doi_tac,
            yeuCau,
            element.refund_price,
            trangThaiTruyThuHoanTheoGoi,
            element.username_duyet_trang_thai_truy_thu_hoan_theo_goi,
            element.other_refund,
            trangThaiTruyThuHoanThem,
            element.username_duyet_trang_thai_truy_thu_hoan_them,
            element.request_type == 5 ? element.transfer_final_amount : '',
            element.request_type == 5 ? (element.ti_le_chiet_khau != 0 ? element.ti_le_chiet_khau : (element.refund_price != 0 ? parseInt((element.refund_price - element.transfer_final_amount) / element.refund_price * 100) : 0)) : '',
            moment(element.created_time).format('DD-MM-YYYY HH:mm:ss'),
            element.request_type == 5 ? (element.is_transfer == 1 ? 'Đã chuyển khoản' : "Chưa chuyển khoản") : '',
            element.request_type == 5 ? element.approver : '',
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'BAO-CAO-YCHT.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
  };

  return (
    <Box {...props} style={{ width: "100%"}}>
      <Box sx={{ mt: 3 }}>
        <Card style={{overflow: 'visible'}}>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <div style={{ width: '450px', zIndex: '10' }} className="full-width-select">
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: 56,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                  }}
                  options={items}
                  getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username} - ${item.fullname}`}
                  placeholder={values.username || "Chọn nhân viên xem doanh thu..."}
                  components={{
                    DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                    IndicatorSeparator: null,
                  }}
                  value={values.username || null}
                  onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                  isSearchable
                />
              </div>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="message"
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                value={values.message}
                placeholder="Tìm SĐT"
                variant="outlined"
                style={{ height: 56, marginTop: 0, width: "450px", marginLeft: '15px' }}
                />
              <DatePicker
                label="Thời gian bắt đầu"
                style={{ width: '200px' }}
                format='DD/MM/YYYY'
                value={values.startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
                />
              <DatePicker
                label="Thời gian kết thúc"
                style={{ width: '200px' }}
                value={values.endDate}
                format='DD/MM/YYYY'
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
              {role.priority != 5 && <Button
                color="primary"
                variant="contained"
                onClick={xuatFile}
                style={{ height: 56, marginTop: 6, width: "150px", marginLeft: 0 }}
              >
                Xuất file
              </Button>}
            </Box>
          </LocalizationProvider>
        </CardContent>
        <CardContent style={{paddingTop: 0}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <Select1
                  style={{ height: 56, marginTop: 6, width: "450px", marginLeft: 0 }}
                  name="don_hoan_hoac_nap"
                  value={values.don_hoan_hoac_nap || 0}
                  onChange={(newValue) => handleDonHoanHoacNapChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="0">Loại đơn...</MenuItem>
                  <MenuItem value="1">Đơn hoàn tiền</MenuItem>
                  <MenuItem value="2">Đơn nạp tiền</MenuItem>
                </Select1>
                <Select1
                  style={{ height: 56, marginTop: 6, width: "450px", marginLeft: 0, color: values.trang_thai_truy_thu_hoan_theo_goi == 'binh_thuong' ? 'green' : (values.trang_thai_truy_thu_hoan_theo_goi == 'truy_thu' ? 'red' : (values.trang_thai_truy_thu_hoan_theo_goi == 'da_truy_thu' ? 'blue' : 'black')) }}
                  name="trang_thai_truy_thu_hoan_theo_goi"
                  value={values.trang_thai_truy_thu_hoan_theo_goi || -99}
                  onChange={(newValue) => handleTrangThaiTruyThuHoanTheoGoiChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="-99">TTTT hoàn theo gói...</MenuItem>
                  <MenuItem value="binh_thuong" style={{color: 'green'}}>BÌNH THƯỜNG / ĐÃ THANH TOÁN</MenuItem>
                  <MenuItem value="truy_thu" style={{color: 'red'}}>TRUY THU</MenuItem>
                  <MenuItem value="da_truy_thu" style={{color: 'blue'}}>ĐÃ TRUY THU</MenuItem>
                </Select1>
                <Select1
                  style={{ height: 56, marginTop: 6, width: "550px", marginLeft: 0, color: values.trang_thai_truy_thu_hoan_them == 'binh_thuong' ? 'green' : (values.trang_thai_truy_thu_hoan_them == 'truy_thu' ? 'red' : (values.trang_thai_truy_thu_hoan_them == 'da_truy_thu' ? 'blue' : 'black')) }}
                  name="trang_thai_truy_thu_hoan_them"
                  value={values.trang_thai_truy_thu_hoan_them || -99}
                  onChange={(newValue) => handleTrangThaiTruyThuHoanThemChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="-99">TTTT hoàn thêm...</MenuItem>
                  <MenuItem value="binh_thuong" style={{color: 'green'}}>BÌNH THƯỜNG / ĐÃ THANH TOÁN</MenuItem>
                  <MenuItem value="truy_thu" style={{color: 'red'}}>TRUY THU</MenuItem>
                  <MenuItem value="da_truy_thu" style={{color: 'blue'}}>ĐÃ TRUY THU</MenuItem>
                </Select1>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 6, width: "150px", marginLeft: 0 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
      <CreateEditDialogSelectChannel open={openSelectChannel} handleClose={handleCloseSelectChannel} type={"SELECT_CHANNEL"} />
      <CreateEditDialog open={open} handleClose={handleClose} type={"CREATE"} />
      <CreateEditDialog1 open={open1} handleClose={handleClose1} type={"CREATE1"} />
    </Box>
  )
};

export default RequestrefundreportToolbar;
