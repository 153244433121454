import { ThamsoService } from 'src/services/thamso.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const THAMSO_NEW_CARE_SUCCESS = 'THAMSO:NEW_CARE_SUCCESS'
export const THAMSO_LIST_SUCCESS = 'THAMSO:FIND_ALL_SUCCESS'
export const THAMSO_LIST_FAILD = 'THAMSO:FIND_ALL_FAILD'
export const THAMSO_COUNT_ASSIGN_SUCCESS = 'THAMSO:COUNT_ASSIGN_SUCCESS'
export const THAMSO_COUNT_ASSIGN_FAILD = 'THAMSO:COUNT_ASSIGN_FAILD'

export const THAMSO_CREATE_SUCCESS = 'THAMSO:CREATE_SUCCESS'
export const THAMSO_CREATE_FAILD = 'THAMSO:CREATE_FAILD'

export const THAMSO_IMPORT_SUCCESS = 'THAMSO:IMPORT_SUCCESS'
export const THAMSO_SAVE_SUCCESS = 'THAMSO:SAVE_SUCCESS'
export const THAMSO_SAVE_FAILD = 'THAMSO:SAVE_SUCCESS'

export const THAMSO_DEL_SUCCESS = 'THAMSO:DEL_SUCCESS'
export const THAMSO_DEL_FAILD = 'THAMSO:DEL_FAILD'

export const THAMSO_ONE_SUCCESS = 'THAMSO:FIND_ONE_SUCCESS'
export const THAMSO_ONE_FAILD = 'THAMSO:FIND_ONE_FAILD'

export const THAMSO_HISTORY_LIST_SUCCESS = 'THAMSO:HISTORY_FIND_ALL_SUCCESS'
export const THAMSO_HISTORY_LIST_FAILD = 'THAMSO:HISTORY_FIND_ALL_FAILD'

export const ThamsoAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
}


function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        ThamsoService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { thamso } = getState()
        dispatch(AppAction.appLoading(true));
        ThamsoService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...thamso.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_LIST_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_LIST_FAILD, error } }
}

function list(page = 0, count = 20,) {
    return (dispatch, getState) => {
        const { thamso } = getState()
        dispatch(AppAction.appLoading(true));
        ThamsoService.findAll(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...thamso.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_LIST_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: THAMSO_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            ThamsoService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(ThamsoAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: THAMSO_SAVE_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_SAVE_FAILD, error } }
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        ThamsoService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(ThamsoAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_CREATE_FAILD, error } }
}

function update(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        ThamsoService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(ThamsoAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_CREATE_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        ThamsoService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(ThamsoAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_DEL_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        ThamsoService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_ONE_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_ONE_FAILD, error } }
}

function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        ThamsoService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(ThamsoAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { thamso } = getState()
        dispatch(AppAction.appLoading(true));
        ThamsoService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...thamso.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: THAMSO_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: THAMSO_HISTORY_LIST_FAILD, error } }
}